import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, scales } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { faker } from '@faker-js/faker';
import { useLazyQuery } from '@apollo/client';
import { useTranslateField } from '../../appContext/AppContext';
import { RegionalDistributionDocument } from '../../gql/graphql';
import { useAsyncEffect } from '../../utils/ReactHelpers';
import { DatasetsDataInterface } from './OfferPostAnalysis';

ChartJS.register(ArcElement, Tooltip, Legend);

export function RegionalDistribution() {
    const [regionalDistribution,] = useLazyQuery(RegionalDistributionDocument)
    const translate = useTranslateField()
    const [labels, setLabels] = useState<Array<string>>([])
    const [datasets, setDatasets] = useState<Array<DatasetsDataInterface>>([])
    useAsyncEffect(async () => {
        let response = await regionalDistribution()

        if (response.data) {
            console.log("response.data=", response.data)
            setLabels([...response.data.RegionalDistribution.labels])
            let colors = response.data.RegionalDistribution.data.map(v=>{
                return faker.color.rgb()
            })

            setDatasets([
                {
                    label: '# of Votes',
                    data: [...response.data.RegionalDistribution.data],
                    borderColor: colors,
                    backgroundColor: colors.map(v=>v+"9f"),
                }
            ])
        }
    }, [])
    return (
        <div className=' flex justify-center h-[30rem]'>
            <Pie
                options={
                    {
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top' as const,
                            },
                            title: {
                                display: true,
                                text: translate('Regional distribution'),
                            },
                        },
                    }
                }
                data={
                    {
                        labels: labels,
                        datasets: datasets,
                    }
                } />
        </div>
    )
}