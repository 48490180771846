import { Container } from "@mui/material";
import { Chart, ChartItem } from "chart.js";
import { useEffect, useRef, useState } from "react";
import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { faker } from "@faker-js/faker";
import { useLazyQuery } from "@apollo/client";
import { AccessDataListDocument, DataTypeEnum } from "../../gql/graphql";
import { useAsyncEffect } from "../../utils/ReactHelpers";
import { DatasetsDataInterface } from "./OfferPostAnalysis";
import { useTranslateField } from "../../appContext/AppContext";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

export function AccessCount() {
    const [accessDataListCall,] = useLazyQuery(AccessDataListDocument)
    const [labels, setLabels] = useState<Array<string>>([])
    const [datasets, setDatasets] = useState<Array<DatasetsDataInterface>>([])
    const translate = useTranslateField()
    useAsyncEffect(async ()=>{
        let response = await accessDataListCall({
            variables: {
                data: {
                    type: DataTypeEnum.Day
                }
            }
        })

        if (response.data) {
            console.log("response.data=", response.data)
            setLabels([...response.data.AccessDataList.labels])
            let color = faker.color.rgb()
            setDatasets([
                {
                    label: translate('Account count'),
                    data: [...response.data.AccessDataList.data],
                    borderColor: [color],
                    backgroundColor: [color + "9f"],
                }
            ])
        }
    },[])

    return (
        <div>
            <Line
                options={
                    {
                        responsive: true,
                        plugins: {
                            legend: {
                                position: 'top' as const,
                            },
                            title: {
                                display: true,
                                text: translate('Access analysis'),
                            },
                        },
                    }
                }
                data={
                    {
                        labels: labels,
                        datasets: datasets,
                    }
                } />
        </div>
    )
}