import {
    BaseInfoResponse,
    CategoryRecord,
    ContactRecord,
    PostTypeRecord,
    ProductTypeRecord,
} from "../gql/graphql";
import { CountryRecord } from "../gql/graphql";
import {
    GetLocalData,
    RemoveLocalData,
    SetLocalData,
} from "../utils/localStroage";

interface LanguageInterface {
    [key: string]: string;
}

interface LanguageDataInterface {
    [key: string]: LanguageInterface;
}

export class BaseDataObj {
    public languageList: string[] = [];
    public languageData: LanguageDataInterface | undefined;
    public countries: CountryRecord[] = [];
    public currencies: string[] = [];
    public categories: CategoryRecord[] = [];
    public postTypeList: PostTypeRecord[] = [];
    public productTypeList: ProductTypeRecord[] = [];
    public contactRecordList: ContactRecord[] = [];
    public accessCount: string = "";
}

export class BaseDataLib {
    private _baseDataObj: BaseDataObj;
    private _request: any;
    constructor(baseDataObj: BaseDataObj, request: any) {
        this._request = request;
        this._baseDataObj = baseDataObj;
    }

    public async InitForce() {
        let response = await this._request();
        if (response.data) {
            let data = response.data.BaseInfo as BaseInfoResponse;
            if (data.countries.length > 0) {
                this._baseDataObj.countries = [...data.countries].sort((a, b) =>
                    a.label.localeCompare(b.label)
                );
            }
            if (data.currencies.length > 0) {
                this._baseDataObj.currencies = [...data.currencies].sort(
                    (a, b) => a.localeCompare(b)
                );
                this._baseDataObj.currencies.forEach((v, index) => {
                    if (v == "JPY") {
                        console.log(index);
                    }
                });
            }

            let lang = JSON.parse(data.languages.mapData);

            if (lang) {
                this._baseDataObj.languageData = lang;
                if (lang["Home"]) {
                    this._baseDataObj.languageList = Object.keys(lang["Home"]);
                }
            }

            this._baseDataObj.postTypeList = data.postTypes;
            this._baseDataObj.productTypeList = data.productTypes;
            this._baseDataObj.categories = data.categories;
            this._baseDataObj.contactRecordList = data.contactSubjects;
            this._baseDataObj.accessCount = data.accessCount;
        }
    }

    public GetContactSubjects(): ContactRecord[] {
        return this._baseDataObj.contactRecordList;
    }

    public GetAccessCount(): string {
        return this._baseDataObj.accessCount;
    }

    public GetCountries(): CountryRecord[] {
        return this._baseDataObj.countries;
    }

    public GetCurrencies(): string[] {
        return this._baseDataObj.currencies;
    }

    public GetLanguageData(): LanguageDataInterface | undefined {
        return this._baseDataObj.languageData;
    }

    public GetLanguage(): string[] {
        return this._baseDataObj.languageList;
    }

    public GetPostTypes(): PostTypeRecord[] {
        return this._baseDataObj.postTypeList;
    }

    public GetProductTypes(): ProductTypeRecord[] {
        return this._baseDataObj.productTypeList;
    }

    public GetCategories(): CategoryRecord[] {
        return this._baseDataObj.categories;
    }

    public async Init() {
        //ローカルでデータがあるかどうかを確認する
        if (
            this._baseDataObj.countries.length === 0 ||
            this._baseDataObj.currencies.length === 0 ||
            this._baseDataObj.languageData === undefined
        ) {
            //もしローカルデータがないなら、サーバーにリクエストする
            console.log("rarara");
            await this.InitForce();
            console.log("ririri");
        }
    }
}
