import { createRoot } from "react-dom/client"

export function CreateRenderNode(id: string, isClean: boolean = true) {
    var rootNode = document.querySelector(`#${id}Root`)
    if (!rootNode) {
        var root = document.createElement("div")
        root.id = id + "Root"
        rootNode = root
        document.body.appendChild(rootNode)
    }

    var node = document.querySelector(`#${id}`)
    if (node) {
        if (isClean) {
            DeleteRenderNode(id)
        } else {
            return createRoot(node)
        }
    }

    const _dom = document.createElement("div")
    _dom.id = id
    rootNode.appendChild(_dom)
    return createRoot(_dom)
}

export function DeleteRenderNode(id: string) {
    var node = document.querySelector(`#${id}`)
    node?.remove()
}