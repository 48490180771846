import * as ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import App from "./App";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import GlobalCssPriority from "./components/global/GlobalCssPriority";

const rootElement = document.getElementById("root");
const root = ReactDOM.createRoot(rootElement!);

const themeTailwindcss = createTheme({
    components: {
        MuiPopover: {
            defaultProps: {
                container: rootElement,
            },
        },
        MuiPopper: {
            defaultProps: {
                container: rootElement,
            },
        },
    },
});

root.render(
    <ThemeProvider theme={{ ...themeTailwindcss, ...theme }}>
        <GlobalCssPriority>
            <BrowserRouter>
                {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                <CssBaseline />
                <App />
            </BrowserRouter>
        </GlobalCssPriority>
    </ThemeProvider>
);
