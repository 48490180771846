import { Box, Container } from "@mui/material";
import { PdfHeader } from "./PdfHeader";
import { PdfBody, PdfBodyInterface } from "./pdfBodyComponent/PdfBody";
import { PdfFooter } from "./PdfFooter";

export function PdfComponent({ data }: { data: PdfBodyInterface }) {
    return (
        <div
            id={`Pdf${data.id}`}
            style={{
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                backgroundColor: "white",
                width: "210mm",
                height: "296.5mm",
                padding: 0,
                margin: 0,
            }}>
            <PdfHeader title={data.title} />
            <PdfBody data={data} />
            <PdfFooter />
        </div>
    );
}
