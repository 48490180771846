export const PRODUCT_ENVIROMENT = "production";
export const Config = {
    // url: "http://localhost:8080/liberty",
    // fileUrl: "http://localhost:8080/file/",
    // url : "http://192.168.1.36:8080/liberty",
    // fileUrl : "http://192.168.1.36:8080/file/",
    // url : "https://api.liberty-staging.xyz/liberty",
    // fileUrl : "https://api.liberty-staging.xyz/file/",
    url: "https://api.liberty-hjc.com/liberty",
    fileUrl: "https://api.liberty-hjc.com/file/",
    // environment: "development",
    environment: "production",
};
