import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Divider, Tab, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslateField } from "../appContext/AppContext";
import { AccessCount } from "../components/analysisComponent/AccessCount";
import { RegionalDistribution } from "../components/analysisComponent/RegionalDistribution";
import { OfferPostAnalysis } from "../components/analysisComponent/OfferPostAnalysis";

export function PagesAnalysis() {

    const [value, setValue] = useState("1")

    const translate = useTranslateField();

    const handleChange = (v: string) => {
        setValue(v)
    }

    return (
        <>
            <Typography variant="h4">{translate("Analysis")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph mt={2}>
                {/* {translate("You can view bookmarked posts on this page.")} */}
            </Typography>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={(v, value) => {
                        handleChange(value)
                    }} aria-label="lab API tabs example">
                        <Tab label={translate("Access analysis")} value="1" />
                        <Tab label={translate("Regional")} value="2" />
                        <Tab label={translate("Offer analysis")} value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <AccessCount></AccessCount>
                </TabPanel>
                <TabPanel value="2">
                    <RegionalDistribution></RegionalDistribution>
                </TabPanel>
                <TabPanel value="3">
                    <OfferPostAnalysis></OfferPostAnalysis>
                </TabPanel>
            </TabContext>
        </>
    )
}