import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useTranslateField } from "../../appContext/AppContext";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import { AccountStatusEnum, GroupEnum } from "../../gql/graphql";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers'
import dayjs, { Dayjs } from 'dayjs';

export function AccountManagerSearchComponent({
    onSearch,
}: {
    onSearch?: (email: string, status: AccountStatusEnum | "", group: GroupEnum | "", startDate: string, endDate: string) => void
}) {
    const translate = useTranslateField()
    const [statusList, setStatusList] = useState<Array<AccountStatusEnum>>([AccountStatusEnum.Able, AccountStatusEnum.Disable, AccountStatusEnum.Unverifiable]);
    const [status, setStatus] = useState<AccountStatusEnum | "">("");
    const [group, setGroup] = useState<GroupEnum | "">("")
    const [groupList, setGroupList] = useState<Array<GroupEnum>>([GroupEnum.Admin, GroupEnum.Member, GroupEnum.Staff, GroupEnum.System])
    const [inputOnFocusTop, setInputOnFocusTop] = useState(-0.5)
    const [startDate, setStartDate] = useState<Dayjs | null>(null)
    const [endDate, setEndDate] = useState<Dayjs | null>(null)
    const [email, setEmail] = useState("")

    const handleStatusChange = (event: SelectChangeEvent) => {
        let newStatus = event.target.value as AccountStatusEnum
        setStatus(newStatus);
    };

    const handleGroupChange = (event: SelectChangeEvent) => {
        console.log("handleGroupChange=", event)
        if (event.target.value !== "") {
            let newGroup = event.target.value as GroupEnum
            console.log("newGroup=", newGroup)
            setGroup(newGroup);
        } else {
            setGroup("");
        }
    };

    const OnSearchClick = async () => {
        console.log("email=", email)
        console.log("status=", status)
        console.log("group=", group)
        console.log("startDate=", startDate?.toString())
        console.log("startDate=", startDate?.format("YYYY-MM-DD"))
        console.log("endDate=", endDate?.toString())
        let startDateStr = startDate ? startDate.format("YYYY-MM-DD") : ""
        let endDateStr = endDate ? endDate.format("YYYY-MM-DD") : ""
        onSearch && onSearch(email, status, group, startDateStr, endDateStr)
    }

    return (
        <Box className=" mb-4">
            <Container className=" p-0">
                <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    sx={{ marginRight: ".25rem" }} size="small" id="outlined-basic" label={translate("email")} variant="outlined" />
                <FormControl sx={{ marginRight: ".25rem", minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">{translate("Status")}</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={status}
                        label={translate("status")}
                        onChange={handleStatusChange}
                    >
                        <MenuItem value={""}>
                            <em>{translate("NONE")}</em>
                        </MenuItem>
                        {
                            statusList.map((status) => {
                                return <MenuItem key={status} value={status}>{translate(status)}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <FormControl sx={{ marginRight: ".25rem", minWidth: 120 }} size="small">
                    <InputLabel id="demo-select-small-label">{translate("group")}</InputLabel>
                    <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={group}
                        label={translate("group")}
                        onChange={handleGroupChange}
                    >
                        <MenuItem value={""}>
                            <em>{translate("NONE")}</em>
                        </MenuItem>
                        {
                            groupList.map(group => {
                                return <MenuItem key={group} value={group}>{translate(group)}</MenuItem>
                            })
                        }
                    </Select>
                </FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={translate("start date")}
                        sx={
                            {
                                width: "12rem",
                                "& label": {
                                    top: inputOnFocusTop + "rem",
                                },
                                "& input": {
                                    padding: ".5rem",
                                },
                                marginRight: ".25rem"
                            }
                        }
                        value={startDate}
                        onChange={(newStartDate) => setStartDate(newStartDate)}
                    />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        label={translate("end date")}
                        sx={
                            {
                                width: "12rem",
                                "& label": {
                                    top: inputOnFocusTop + "rem",
                                },
                                "& input": {
                                    padding: ".5rem",
                                },
                                marginRight: ".25rem"
                            }
                        }
                        value={endDate}
                        onChange={(endStartDate) => setEndDate(endStartDate)}
                    />
                </LocalizationProvider>
                <Button
                    onClick={OnSearchClick}
                    variant="contained" endIcon={<SearchIcon />}>
                    {translate("Search")}
                </Button>
            </Container>
        </Box>
    )
}