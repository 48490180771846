import Typography from "@mui/material/Typography";
import ProductHero from "../components/ProductHero";
import OfferCard from "../components/OfferCard";
import { Avatar, Box, Button, Card, Container, Divider, Grid, Link } from "@mui/material";
import { Search, Create, ConnectWithoutContact } from "@mui/icons-material";
import { useLazyQuery } from "@apollo/client";
import { LimitInput, OfferListDocument, OfferListEnum, OfferResponse } from "../gql/graphql";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { useState } from "react";
import { record } from "zod";
import { useTranslateField } from "../appContext/AppContext";
import { useNavigate } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import Sponsors from "../sponsors/sponsors";
// import logo from "../media/images/sponsors/logo_NIT.jpg";
import { Helmet } from "react-helmet-async";

export default function PagesHome() {
    const translate = useTranslateField();
    const [offerlist] = useLazyQuery(OfferListDocument);
    const [offerRecords, setOfferRecords] = useState<Array<OfferResponse>>([]);
    useAsyncEffect(async () => {
        let limit: LimitInput = {
            current: 0,
            pageSize: 4,
        };
        let response = await offerlist({
            variables: {
                limit: limit,
                searchType: OfferListEnum.All,
            },
        });
        console.log("response=", response);
        if (response.data) {
            setOfferRecords(response.data.OfferList.offers);
        }
    }, []);

    const navigate = useNavigate();
    const handleNavigate = (path: string) => () => navigate(path);

    const services = [
        {
            icon: <Search fontSize="large" />,
            label: translate("Search"),
            description: translate(
                "You can search for machine information from all over the world by entering a keyword. It also supports searches by hashtags and narrowed searches."
            ),
            buttonText: translate("Jump to search page"),
            onClick: handleNavigate(PageRecords.Search.path),
        },
        {
            icon: <Create fontSize="large" />,
            label: translate("Post"),
            description: translate(
                "You can post information about the products you want to buy and the products you want to sell. The information you post will appear in search results."
            ),
            buttonText: translate("Jump to post page"),
            onClick: handleNavigate(PageRecords.Post.path),
        },
        {
            icon: <ConnectWithoutContact fontSize="large" />,
            label: translate("Contact"),
            description: translate(
                "Buyers and sellers can communicate directly with each other using the messaging feature within the site. Received and sent messages can be viewed on the message page."
            ),
            buttonText: translate("Jump to message page"),
            onClick: handleNavigate(PageRecords.Account.path + PageRecords.Account.children.Message.path),
        },
    ];

    return (
        <>
            <Helmet>
                <title>{"Liberty | " + translate("Home")}</title>
            </Helmet>
            <ProductHero />
            <Box>
                <Container sx={{ py: 12 }}>
                    <Typography variant="h4">{translate("Service")}</Typography>
                    <Typography mt={1}>
                        {translate(
                            "These are the three basic services provided by Liberty. Please search the product information and post if the information you are looking for is not available. Messages can be exchanged between users regarding posts."
                        )}
                    </Typography>
                    <Grid container spacing={4} sx={{ mt: 2 }}>
                        {services.map((service) => {
                            return (
                                <>
                                    <Grid item xs={12} md={4} key={`service-card-${service.label}`}>
                                        <Card
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                flexDirection: "column",
                                                gap: 2,
                                                padding: 4,
                                                minHeight: "100%",
                                            }}
                                            onClick={service.onClick}>
                                            <Box>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                                                    <Avatar
                                                        sx={{
                                                            width: 56,
                                                            height: 56,
                                                            bgcolor: "secondary.main",
                                                        }}>
                                                        {service.icon}
                                                    </Avatar>
                                                    <Typography variant="h6">{service.label}</Typography>
                                                </Box>
                                                <Typography sx={{ mt: 2 }}>{service.description}</Typography>
                                            </Box>
                                            <Button variant="contained" sx={{ mt: 2 }} fullWidth>
                                                {service.buttonText}
                                            </Button>
                                        </Card>
                                    </Grid>
                                </>
                            );
                        })}
                    </Grid>
                </Container>
            </Box>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&::before": {
                        display: "block",
                        content: '""',
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        bgcolor: "#19857b7f",
                    },
                    "&::after": {
                        display: "block",
                        content: '""',
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        bgcolor: "#19857b7f",
                    },
                }}>
                <Divider
                    sx={{
                        flex: 1,
                        height: 2,
                        backgroundColor: "#19857b7f",
                    }}
                />
            </Container>
            <Box>
                <Container sx={{ py: 12 }}>
                    <Box>
                        <Typography variant="h4">{translate("Recent Posts")}</Typography>
                        <Grid container spacing={4} mt={2}>
                            {offerRecords.map((record) => {
                                return (
                                    <Grid
                                        sx={{
                                            ":hover": {
                                                cursor: "pointer",
                                            },
                                        }}
                                        key={`RecentPost-${record.id}`}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(PageRecords.SearchDetail.createSearchDetailLink(record.id));
                                        }}>
                                        <OfferCard showThumbnail data={record} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Typography align="right">
                            <Link
                                href={PageRecords.Search.path}
                                sx={{
                                    cursor: "pointer",
                                }}>
                                {translate("More")}
                                {">>"}
                            </Link>
                        </Typography>
                    </Box>
                    <Box mt={12}>
                        <Typography variant="h4">{translate("Products available for immediate purchase")}</Typography>
                        <Grid container spacing={4} mt={2}>
                            {offerRecords.map((record) => {
                                return (
                                    <Grid
                                        sx={{
                                            ":hover": {
                                                cursor: "pointer",
                                            },
                                        }}
                                        key={`Product-${record.id}`}
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(PageRecords.SearchDetail.createSearchDetailLink(record.id));
                                        }}>
                                        <OfferCard showThumbnail data={record} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Typography align="right">
                            <Link
                                href={PageRecords.Search.path}
                                sx={{
                                    cursor: "pointer",
                                }}>
                                {translate("More")}
                                {">>"}
                            </Link>
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <Container
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    "&::before": {
                        display: "block",
                        content: '""',
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        bgcolor: "#19857b7f",
                    },
                    "&::after": {
                        display: "block",
                        content: '""',
                        width: 8,
                        height: 8,
                        borderRadius: "50%",
                        bgcolor: "#19857b7f",
                    },
                }}>
                <Divider
                    sx={{
                        flex: 1,
                        height: 2,
                        backgroundColor: "#19857b7f",
                    }}
                />
            </Container>
            <Box>
                <Container sx={{ py: 12 }}>
                    <Typography variant="h4">{translate("Sponsors")}</Typography>
                    <Box bgcolor="#dfdfdf7f" p={3} mt={2} borderRadius={2}>
                        <Grid container spacing={3}>
                            {Sponsors.map((v) => {
                                return (
                                    <Grid item xs={4} sm={3} md={2}>
                                        <Link href={v.homepageUrl} target="_black">
                                            <Card
                                                sx={{
                                                    aspectRatio: 1,
                                                    backgroundImage: `url(${v.logoUrl})`,
                                                    backgroundSize: "80%",
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundPosition: "center",
                                                }}
                                            />
                                        </Link>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    );
}
