import { Divider, Typography } from "@mui/material";
import SearchBar from "../components/SearchBar";
import SearchResultTable from "../components/SearchResultTable";
import { useState, useTransition } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { ChangeOffersStatusDocument, DeleteOfferDocument, GroupEnum, LimitInput, OfferFilterInput, OfferListDocument, OfferListEnum, OfferResponse, OfferStatusInputEnum } from "../gql/graphql";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { LoginSharp } from "@mui/icons-material";
import { Message } from "../components/global/Message";
import { useAccount, useTranslateField } from "../appContext/AppContext";
import { GetLocalData, SetLocalData } from "../utils/localStroage";
import RadioButtonsGroupComponent from "../components/accountManager/RadioComponent";

export default function PagesAccountEditPost() {
    const [offerListCall] = useLazyQuery(OfferListDocument, { fetchPolicy: "network-only" });
    const [deleteOffer,] = useMutation(DeleteOfferDocument)
    const [offers, setOffers] = useState<Array<OfferResponse>>([]);
    const [current, setCurrent] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const translate = useTranslateField()
    const [changeOfferStatus,] = useMutation(ChangeOffersStatusDocument)
    const pageSizeList = [10, 30, 50];
    const account = useAccount()
    const [selectedType,setSelectedType] = useState(OfferListEnum.Owner)
    const creator = Object.values(OfferListEnum).map(v=>{
        return {
            label:v,
            value:v,
        }
    })
    let oldFilter = GetLocalData("EditFilter")
    let ret: OfferFilterInput | undefined = undefined
    if (oldFilter) {
        ret = JSON.parse(oldFilter)
    }
    const [filter, setFilter] = useState<OfferFilterInput | undefined>(ret)
    useAsyncEffect(async () => {
        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        let { data } = await offerListCall({
            variables: {
                limit: limitInput,
                searchType: selectedType,
                filter: filter,
            },
            fetchPolicy: "network-only",
        });
        if (data) {
            setOffers(data.OfferList.offers);
            setTotal(data.OfferList.total);
        }
    }, [current, pageSize, offers, filter]);

    const OnDeleteOfferClick = async (ids: string[]) => {
        if (ids.length > 0) {
            let response = await deleteOffer({
                variables: {
                    offerIds: ids
                }
            })

            console.log("OnDeleteOfferClick response = ", response)
            if (response.data) {
                if (response.data.DeleteOffer.valueOf()) {
                    let newOffers = offers.filter(offer => !ids.includes(offer.id))
                    console.log("new offers=", newOffers)
                    setOffers([...newOffers])
                    Message.Success("Delete offer is successed")
                }
            }
        }
    }

    const SetFilter = async (filterInfo: OfferFilterInput | undefined) => {
        setFilter(filterInfo)
        if (filterInfo) {
            SetLocalData("EditFilter", JSON.stringify(filterInfo));
        }
        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        console.log("EditPost filter=", filter)
        let { data } = await offerListCall({
            variables: {
                limit: limitInput,
                searchType: selectedType,
                filter: filterInfo,
            },
            fetchPolicy: "network-only",
        });
        if (data) {
            setOffers(data.OfferList.offers);
            setTotal(data.OfferList.total);
        }
    }

    const OnFilterApply = async (filter: OfferFilterInput) => {
        SetFilter(filter)
    }

    const OnChangeOfferStatus = async (offerIds: string[], status: OfferStatusInputEnum) => {
        let response = await changeOfferStatus({
            variables: {
                offerIds: offerIds,
                status: status
            }
        })

        if (response.data) {
            if (response.data.ChangeOffersStatus.valueOf()){
                Message.Success("Change Offer Status is success")
            }else{
                Message.Success("Change Offer Status is failed")
                return 
            }
        }

        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        let { data } = await offerListCall({
            variables: {
                limit: limitInput,
                searchType: OfferListEnum.Owner,
            },
            fetchPolicy: "network-only",
        });
        if (data) {
            setOffers(data.OfferList.offers);
            setTotal(data.OfferList.total);
        }
    }

    return (
        <>
            <Typography variant="h4">{translate("Edit post")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph mt={2}>
                {translate("This page gives you control over your posts. You can easily view, edit and delete existing posts.")}
            </Typography>
            <SearchBar sx={{ mt: 4, width: "100%" }} filter={filter} onFilterApply={OnFilterApply} />
            {
                account?.IsManager() === true ?<RadioButtonsGroupComponent selected={selectedType} data={creator} onChange={setSelectedType} ></RadioButtonsGroupComponent>:
                <div style={{marginTop:"1rem"}}></div>
            }
            
            <SearchResultTable
                data={offers}
                pageSize={pageSize}
                total={total}
                current={current}
                setPageSize={setPageSize}
                setCurrent={setCurrent}
                pageSizeList={pageSizeList}
                editMode
                sx={{ mt: 0, width: "100%" }}
                onDeleteOfferClick={OnDeleteOfferClick}
                onChangeOfferStatusClick={OnChangeOfferStatus}
            />
        </>
    );
}
