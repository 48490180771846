import { useMutation, useReactiveVar } from "@apollo/client";
import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Graphs } from "../appContext/Graphs";
import { Message } from "../components/global/Message";
import { useAccount, useTranslateField } from "../appContext/AppContext";
import {
    AccountProfileInput,
    ConfirmPasswordDocument,
    UpdateAccountProfileDocument,
    UpdatePasswordDocument,
} from "../gql/graphql";
import theme from "../theme";
import { AddPhotoAlternate } from "@mui/icons-material";
import FileWrapper from "../utils/files/FileWrapper";
import Files, { CreateFileLink } from "../utils/files/Files";

export default function PagesAccountProfile() {

    // <--start-- ※要整理※ プロフィール変更処理関連？
    const account = useAccount();
    if (!account) {
        Message.Error("account is not found");
        return <></>;
    }

    const translate = useTranslateField()
    useEffect(() => {
        if (account) {
            let userRecord = account.GetUser();
            if (userRecord) {
                console.log("useRecord=", userRecord);
                setFamilyName(userRecord.familyName ? userRecord.familyName : "");
                setFirstName(userRecord.firstName ? userRecord.firstName : "");
                setEmail(userRecord.email);
                if (userRecord.avatar) {
                    setThumbnail(CreateFileLink(userRecord.avatar))
                }
            }
        }
    }, []);
    // --end--> ※要整理※ プロフィール変更処理関連？
    // <--start-- プロフィール画像変更処理関連
    const [localFile, setLocalFile] = useState<FileWrapper | undefined>(
        undefined
    );
    const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);
    const onClickAddFile = async () => {
        const newFile = await Files.PromptSingleFileUploadDialogAsync_();
        if (!newFile) return;
        const newFileWrapper = FileWrapper.FromFile(newFile);
        setLocalFile(newFileWrapper);
        const newThumbnail = await newFileWrapper.maybeGetThumbnailUrlAsync();
        setThumbnail(newThumbnail);
    };
    useEffect(() => console.log(localFile), [localFile]);
    // --end--> プロフィール画像変更処理関連

    // <--start-- プロフィール変更処理関連
    const [savedFamilyName, setSavedFamilyName] = useState<string>("SAVED_VALUE");
    const [savedFirstName, setSavedFirstName] = useState<string>("SAVED_VALUE");
    const [savedEmail, setSavedEmail] = useState<string>("SAVED_VALUE");
    const [familyName, setFamilyName] = useState<string>(savedFamilyName);
    const [firstName, setFirstName] = useState<string>(savedFamilyName);
    const [email, setEmail] = useState<string>(savedEmail);
    const [editing, setEditing] = useState<boolean>(false);
    const [openPasswordCheckDialog, setOpenPasswordCheckDialog] = useState<
        boolean
    >(false);
    const [passwordForEditProfile, setPasswordForEditProfile] = useState<
        string
    >("");
    const [updateAccountProfileCall, setUpdateAccountProfileCall] = useMutation(UpdateAccountProfileDocument)
    // onChange
    const handleFamilyName = (e: ChangeEvent<HTMLInputElement>) => {
        setFamilyName(e.target.value);
    };

    const handleFirstName = (e: ChangeEvent<HTMLInputElement>) => {
        setFirstName(e.target.value);
    };
    const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handleChangePasswordForEditProfile = (
        e: ChangeEvent<HTMLInputElement>
    ) => {
        setPasswordForEditProfile(e.target.value);
    };
    // ダイアログ開閉・パスワード認証
    const dummyPassword = "test";
    const handleOpenPasswordCheckDialog = async () => {
        setOpenPasswordCheckDialog(true);
    };
    const handleClosePasswordCheckDialog = () => {
        setPasswordForEditProfile("");
        setOpenPasswordCheckDialog(false);
    };
    const handleCheckPasswordForEditProfile = async () => {

        let response = await confirmPasswordCall({
            variables: {
                password: passwordForEditProfile
            }
        })

        if (response.data && response.data.ConfirmPassword.valueOf()) {
            setEditing(true);
            setOpenPasswordCheckDialog(false);
            return;
        }
    };
    // パスワード認証後の操作
    const handleCancelEdit = () => {
        setFamilyName(savedFamilyName); // 編集前の値に戻す
        setEmail(savedEmail); // 編集前の値に戻す
        setEditing(false);
    };
    const handleSaveNewProfile = async () => {
        let saveNewProfileData: AccountProfileInput = {
            familyName: familyName,
            firstName: firstName,
        }
        console.log("localFile=", localFile)
        if (localFile) {
            saveNewProfileData.image = localFile.file
        }
        console.log("saveNewProfileData = ", saveNewProfileData)
        let response = await updateAccountProfileCall({
            variables: {
                data: saveNewProfileData,
            }
        })
        if (response.data) {
            let useRecord = response.data.UpdateAccountProfile
            setSavedFamilyName(useRecord.familyName ? useRecord.familyName : "");
            setSavedFirstName(useRecord.firstName ? useRecord.firstName : "")
            setSavedEmail(useRecord.email);
            if (useRecord.avatar) {
                setThumbnail(CreateFileLink(useRecord.avatar))
            }
            account.SetUser(useRecord)
            setEditing(false);
            Message.Success("update profile success!")
        }
    };
    // --end--> プロフィール変更処理関連

    // <--start-- パスワード変更処理関連
    const [openPasswordEdit, setOpenPasswordEdit] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPasswordCall,] = useMutation(ConfirmPasswordDocument)

    const [updatePassword] = useMutation(UpdatePasswordDocument);
    const handleSavePassword = async () => {
        let ret = await updatePassword({
            variables: {
                data: {
                    confirmPassword: confirmPassword,
                    currentPassword: currentPassword,
                    newPassword: newPassword,
                },
            },
        });
        if (ret.data && ret.data.UpdatePassword === true) {
            //TODO: メッセージを統一処理しよう
            Message.Success(translate("Password changed successfully"));
            setOpenPasswordEdit(false);
        }
    };
    const handleOpenPasswordEdit = () => {
        setOpenPasswordEdit(true);
    };
    const handleClosePasswordEdit = () => {
        setOpenPasswordEdit(false);
    };
    // --end--> パスワード変更処理関連

    // <--start-- ※要整理※ プロフィール変更処理関連？
    const [updateProfile] = useMutation(UpdateAccountProfileDocument);
    useEffect(() => {
        if (account) {
            let userRecord = account.GetUser();
            if (userRecord) {
                console.log("useRecord=", userRecord);
                setFamilyName(userRecord.familyName ? userRecord.familyName : "");
                setFirstName(userRecord.firstName ? userRecord.firstName : "");
                setEmail(userRecord.email);
            }
        }
    }, []);
    const handleSave = async () => {
        let user = account.GetUser();
        if (user && familyName) {
            let ret = await updateProfile({
                variables: {
                    data: {
                        familyName:familyName,
                        firstName:firstName,
                    },
                },
            });

            if (ret.data) {
                Message.Success("Profile changed successfully");
                user.familyName = familyName;
                user.firstName = firstName;
                account.SetUser(user);
                setEditing(false);
            }
        }
    };
    // --end--> ※要整理※ プロフィール変更処理関連？

    return (
        <>
            <Typography variant="h4">{translate("Account profile")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph sx={{ mt: 2 }}>
                {translate("This is your account profile page.")}
                <br /> {translate("You can change your account information and password on this page.")}
            </Typography>
            <Box sx={{ mt: 4 }}>
                <Card>
                    <CardContent>
                        <Grid
                            container
                            spacing={{ xs: 2, sm: 4 }}
                            justifyContent="center"
                        >
                            <Grid item xs="auto">
                                <ButtonBase
                                    disabled={!editing}
                                    onClick={onClickAddFile}
                                    sx={{
                                        borderRadius: "50%",
                                        position: "relative",
                                        "&:hover": {
                                            "&::before": {
                                                opacity: 1,
                                            },
                                        },
                                        "&::before": {
                                            display: "block",
                                            content: '""',
                                            position: "absolute",
                                            inset: "4px",
                                            borderRadius: "50%",
                                            backgroundColor:
                                                theme.palette.divider,
                                            opacity: 0,
                                            userSelect: "none",
                                            pointerEvents: "none",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: "200px",
                                            aspectRatio: 1,
                                            borderRadius: "50%",
                                            padding: 0.5,
                                            border: `solid 1px ${theme.palette.divider
                                                }`,
                                            boxShadow: theme.shadows[1],
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}
                                    >
                                        {thumbnail ? (
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    aspectRatio: 1,
                                                    overflow: "hidden",
                                                    backgroundImage: `url(${thumbnail})`,
                                                    backgroundPosition:
                                                        "center",
                                                    backgroundSize: "cover",
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    flexDirection: "column",
                                                    gap: 1,
                                                    color:
                                                        theme.palette.text
                                                            .secondary,
                                                }}
                                            >
                                                <AddPhotoAlternate />
                                                <Typography variant="caption">
                                                    {translate("Profile image")}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </ButtonBase>
                            </Grid>
                            <Grid item xs={12} sm>
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        flexWrap: "wrap",
                                        gap: 1,
                                    }}
                                >
                                    <Typography>{translate("Information")}</Typography>
                                    {editing ? (
                                        <Box>
                                            <Button
                                                type="submit"
                                                onClick={handleCancelEdit}
                                                sx={{
                                                    mr: 1,
                                                    color:
                                                        theme.palette.text
                                                            .secondary,
                                                }}
                                            >
                                                {translate("Cancel")}
                                            </Button>
                                            <Button
                                                type="submit"
                                                onClick={handleSaveNewProfile}
                                            >
                                                {translate("Save")}
                                            </Button>
                                        </Box>
                                    ) : (
                                        <Button
                                            variant="text"
                                            onClick={
                                                handleOpenPasswordCheckDialog
                                            }
                                        >
                                            {translate("Edit")}
                                        </Button>
                                    )}
                                </Box>
                                <Divider />
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ pt: 4, pl: 3 }}
                                >
                                    <Grid container 
                                        sx={{pl:2}}
                                        spacing={2}
                                    >
                                        <Grid item xs={4}>
                                            <TextField
                                                variant={
                                                    editing
                                                        ? "outlined"
                                                        : "standard"
                                                }
                                                fullWidth
                                                disabled={!editing}
                                                label={translate("First Name")}
                                                value={firstName}
                                                onChange={handleFirstName}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <TextField
                                                variant={
                                                    editing
                                                        ? "outlined"
                                                        : "standard"
                                                }
                                                fullWidth
                                                disabled={!editing}
                                                label={translate("Family Name")}
                                                value={familyName}
                                                onChange={handleFamilyName}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant={
                                                editing
                                                    ? "outlined"
                                                    : "standard"
                                            }
                                            fullWidth
                                            // disabled={!editing}
                                            disabled
                                            label={translate("Email")}
                                            value={email}
                                            onChange={handleChangeEmail}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Card sx={{ mt: 4 }}>
                    <CardContent
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Typography>{translate("Password")}</Typography>
                        <Box>
                            <Button onClick={handleOpenPasswordEdit}>
                                {translate("Edit")}
                            </Button>
                            <Dialog
                                open={openPasswordEdit}
                                onClose={handleClosePasswordEdit}
                            >
                                <DialogTitle>{translate("Edit password")}</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        {translate("Please use 8 or more single-byte alphanumeric characters.")}
                                    </DialogContentText>
                                    <TextField
                                        margin="dense"
                                        label={translate("Current password")}
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) =>
                                            setCurrentPassword(e.target.value)
                                        }
                                    />
                                    <TextField
                                        margin="dense"
                                        label={translate("New password")}
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) =>
                                            setNewPassword(e.target.value)
                                        }
                                    />
                                    <TextField
                                        margin="dense"
                                        label={translate("Confirm new password")}
                                        type="password"
                                        fullWidth
                                        variant="standard"
                                        onChange={(e) => {
                                            setConfirmPassword(e.target.value);
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClosePasswordEdit}>
                                        {translate("Cancel")}
                                    </Button>
                                    <Button onClick={handleSavePassword}>
                                        {translate("Save")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </Box>
                    </CardContent>
                </Card>
                <Dialog
                    open={openPasswordCheckDialog}
                    onClose={handleClosePasswordCheckDialog}
                >
                    <DialogTitle>{translate("Enter password")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {translate("Please use 8 or more single-byte alphanumeric characters.")}
                        </DialogContentText>
                        <TextField
                            margin="dense"
                            label={translate("Password")}
                            type="password"
                            fullWidth
                            variant="standard"
                            value={passwordForEditProfile}
                            onChange={handleChangePasswordForEditProfile}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClosePasswordCheckDialog}>
                            {translate("Cancel")}
                        </Button>
                        <Button onClick={handleCheckPasswordForEditProfile}>
                            {translate("Check")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
}
