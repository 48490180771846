import { SyntheticEvent, useEffect, useState } from "react";
import {
    Autocomplete,
    Box,
    Container,
    Grid,
    List,
    ListItem,
    ListItemButton,
    TextField,
    Typography,
} from "@mui/material";
import PageRecords, { PathsWithoutFooter } from "../routes/PageRecords";
import Copyright from "./Copyright";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useLocation, useNavigate } from "react-router-dom";
import { useLanguage } from "../appContext/AppContext";
import { useTranslateField } from "../appContext/AppContext";

const languages: LanguageType[] = [
    {
        label: "English",
        value: "en",
    },
    {
        label: "Japanese",
        value: "ja",
    },
    {
        label: "Chinese",
        value: "ch",
    },
    {
        label: "Korean",
        value: "ko",
    },
];
interface LanguageType {
    label: string;
    value: string;
}

export default function Footer(props: { forceDisplay?: boolean }) {
    const { forceDisplay } = props;
    const translate = useTranslateField();

    const [language, setLaunguage] = useState<LanguageType>(languages[0]);
    const [globeLanguage, setGlobeLanguage] = useLanguage()
    const handleLanguage = (e: SyntheticEvent, newLanguage: LanguageType) => {
        let entry = languages.find(v => v.value === newLanguage.value)
        if (entry) {
            setLaunguage(newLanguage);
            setGlobeLanguage(entry.label)
        }
    };

    useEffect(() => {
        let entry = languages.find(v => v.label === globeLanguage)
        if (entry) {
            setLaunguage(entry)
        } else {
            setLaunguage(languages[0])
        }
    }, [globeLanguage])

    // <--start-- ページごとにフッターの表示/非表示を切り替える
    const [hidden, setHidden] = useState(true);
    const { pathname } = useLocation();
    const [pathNamesWithoutFooter] = useState(PathsWithoutFooter());
    useEffect(() => {
        if (forceDisplay) {
            setHidden(false);
            return;
        }
        const hiddenFlag = pathNamesWithoutFooter.includes(pathname);
        setHidden(hiddenFlag);
    }, [pathname]);
    // --end--> ページごとにフッターの表示/非表示を切り替える

    const navigate = useNavigate();

    const onClickNavigate = (path: string) => () => {
        navigate(path);
    };

    return hidden ? (
        <></>
    ) : (
        <Box>
            <Box
                component="footer"
                sx={{
                    py: 8,
                    bgcolor: "secondary.main",
                    color: "#fff",
                }}
            >
                <Container>
                    <Grid container spacing={{ xs: 4, sm: 8 }}>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box>
                                <Typography variant="h6" color="#F9F871">
                                    {translate("SERVICES")}
                                </Typography>
                            </Box>
                            <List disablePadding>
                                <ListItem>
                                    <ListItemButton
                                        onClick={onClickNavigate(
                                            PageRecords.Home.path
                                        )}
                                        sx={{
                                            color: "#fff",
                                            borderBottom: "solid 1px #fff",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {translate("Home")}
                                        <ArrowRightIcon />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        onClick={onClickNavigate(
                                            PageRecords.Search.path
                                        )}
                                        sx={{
                                            color: "#fff",
                                            borderBottom: "solid 1px #fff",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {translate("Search")}
                                        <ArrowRightIcon />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        onClick={onClickNavigate(
                                            PageRecords.Category.path
                                        )}
                                        sx={{
                                            color: "#fff",
                                            borderBottom: "solid 1px #fff",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {translate("Category")}
                                        <ArrowRightIcon />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        onClick={onClickNavigate(
                                            PageRecords.Post.path
                                        )}
                                        sx={{
                                            color: "#fff",
                                            borderBottom: "solid 1px #fff",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {translate("Post")}
                                        <ArrowRightIcon />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton
                                        onClick={onClickNavigate(
                                            PageRecords.Contact.path
                                        )}
                                        sx={{
                                            color: "#fff",
                                            borderBottom: "solid 1px #fff",
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {translate("Contact")}
                                        <ArrowRightIcon />
                                    </ListItemButton>
                                </ListItem>
                                {/* <ListItemButton onClick={onClickNavigate(PageRecords)}
                                    
                                    sx={{
                                        color: "#fff",
                                        borderBottom: "solid 1px #fff",
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    Manual
                                    <ArrowRightIcon />
                                </ListItemButton> */}
                            </List>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box>
                                <Typography variant="h6" color="#F9F871">
                                    {translate("ACCOUNT")}
                                </Typography>
                                <List disablePadding>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={onClickNavigate(
                                                PageRecords.SignUp.path
                                            )}
                                            sx={{
                                                color: "#fff",
                                                borderBottom: "solid 1px #fff",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {translate("Sign Up")}
                                            <ArrowRightIcon />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={onClickNavigate(
                                                PageRecords.SignIn.path
                                            )}
                                            sx={{
                                                color: "#fff",
                                                borderBottom: "solid 1px #fff",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {translate("Sign In")}
                                            <ArrowRightIcon />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={onClickNavigate(
                                                PageRecords.Account.path
                                            )}
                                            sx={{
                                                color: "#fff",
                                                borderBottom: "solid 1px #fff",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {translate("Dashboard")}
                                            <ArrowRightIcon />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box>
                                <Typography variant="h6" color="#F9F871">
                                    {translate("LEGAL")}
                                </Typography>
                                <List disablePadding>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={onClickNavigate(
                                                PageRecords.Terms.path
                                            )}
                                            sx={{
                                                color: "#fff",
                                                borderBottom: "solid 1px #fff",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {translate("Terms")}
                                            <ArrowRightIcon />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemButton
                                            onClick={onClickNavigate(
                                                PageRecords.Privacy.path
                                            )}
                                            sx={{
                                                color: "#fff",
                                                borderBottom: "solid 1px #fff",
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            {translate("Privacy")}
                                            <ArrowRightIcon />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Box>
                                <Typography variant="h6" color="#F9F871">
                                    {translate("LANGUAGE")}
                                </Typography>
                                <List disablePadding>
                                    <ListItem>
                                        <Autocomplete
                                            fullWidth
                                            options={languages.map(language => {
                                                return {
                                                    label: translate(language.label),
                                                    value: language.value
                                                } as LanguageType
                                            })}
                                            value={{ label: translate(language.label), value: language.value }}
                                            disableClearable
                                            getOptionLabel={(
                                                option: LanguageType
                                            ) => option.label}
                                            onChange={handleLanguage}
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.value === value.value}
                                            sx={{
                                                "& .MuiAutocomplete-endAdornment": {
                                                    right: "16px"
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="standard"
                                                    sx={{
                                                        "& .MuiInput-root": {
                                                            "& .MuiInput-input": {
                                                                p: 0
                                                            },
                                                            p: "8px 16px",
                                                            color: "#fff",
                                                            ":hover": {
                                                                ":not(.Mui-disabled, .Mui-error)": {
                                                                    ":before": {
                                                                        borderColor: "#fff"
                                                                    }
                                                                }
                                                            },
                                                            ":after": {
                                                                borderColor: "#fff"
                                                            },
                                                            ":before": {
                                                                borderColor: "#fff"
                                                            },
                                                        },
                                                        "& .MuiSvgIcon-root": {
                                                            color: "#fff"
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box sx={{ bgColor: "#fff", py: 2 }}>
                <Container>
                    <Copyright />
                </Container>
            </Box>
        </Box>
    );
}
