import Copyright from "../components/Copyright";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Graphs } from "../appContext/Graphs";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { AppContext, useTranslateField } from "../appContext/AppContext";
import { useNavigate } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import { ResetPasswordMailDocument, User } from "../gql/graphql";
import { Message } from "../components/global/Message";
import { Avatar, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { DateTimePicker } from "@mui/lab";
import { GetLocalData, RemoveLocalData, SetLocalData } from "../utils/localStroage";

const header = "3vtkgnywzi82"

export default function PagesSignIn() {
    const [signIn, { data, error }] = useMutation(Graphs.SignIn)
    const context = useContext(AppContext)
    const navigate = useNavigate()
    const translate = useTranslateField();
    const [resetPasswordMailCall,] = useMutation(ResetPasswordMailDocument)
    const [isRemember, setIsRemember] = useState(false)
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("");

    useEffect(() => {
        let data = GetLocalData(header + "rememberData")
        console.log("data=", data)
        if (data) {
            if (data.email) {
                setEmail(data.email)
            }
            if (data.password) {
                setPassword(data.password)
            }
            setIsRemember(true)
        }
    }, [])

    useEffect(() => {
        try {
            if (data) {
                let session = data.SignIn.session as string
                let user = data.SignIn.user as User

                if (session.length > 0) {
                    context?.account.LogIn(session, user)
                    navigate(PageRecords.Account.path)
                } else {
                    navigate(PageRecords.VerificationPage.path, {
                        state: {
                            email: user.email
                        }
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }, [data])
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (isRemember) {
            SetLocalData(header + "rememberData", {
                email: email,
                password: password,
            })
        } else {
            RemoveLocalData(header + "rememberData")
        }
        console.log({
            email: email,
            password: password,
        });
        //TODO:チェックすることが必要です
        await signIn({
            variables: {
                data: {
                    email: email,
                    password: password,
                }
            }
        })

    };

    // パスワード表示/非表示用
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    // <--start-- パスワードリセットダイアログ関連
    const [openResetPasswordDialog, setOpenResetPasswordDialog] = useState(
        false
    );
    const [sendEmailFlag, setSendEmailFlag] = useState<boolean>(false);
    const onClickResetPasswordButton = () => {
        setOpenResetPasswordDialog(true);
    };
    const onClickCloseResetPasswordDialog = () => {
        setOpenResetPasswordDialog(false);
        setEmail("");
        setSendEmailFlag(false);
    };
    const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };

    const onClickSendEmail = async () => {
        if (email.length > 0) {
            let response = await resetPasswordMailCall({
                variables: {
                    email: email
                }
            })

            if (response.data) {
                if (response.data.ResetPasswordMail.valueOf()) {
                    setSendEmailFlag(true);
                }
            }
        }
    };

    const onClickSendEmailAgain = () => {
        setSendEmailFlag(false);
    };
    const onClickNavigateContact = () => {
        navigate(PageRecords.Contact.path);
    }
    // --end--> パスワードリセットダイアログ関連

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        // header's height is 70px (ResponsiveAppBar.tsx => Toolbar)
                        minHeight: "calc(100vh - 70px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {translate("Sign in")}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="email"
                            value={email}
                            label={translate("Email")}
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            label={translate("Password")}
                            autoComplete="current-password"
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControlLabel
                            checked={isRemember}
                            onClick={() => setIsRemember(!isRemember)}
                            control={<Checkbox value="Remember" color="primary" />}
                            label={translate("Remember me")}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {translate("Sign in")}
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link onClick={onClickResetPasswordButton} variant="body2" sx={{ cursor: "pointer" }}>
                                    {translate("Forgot password?")}
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link
                                    onClick={() =>
                                        navigate(PageRecords.SignUp.path)
                                    }
                                    variant="body2"
                                    sx={{ cursor: "pointer" }}
                                >
                                    {translate("Don't have an account? Sign up")}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                    <Copyright sx={{ mt: 4 }} />
                </Box>
            </Container>
            <Dialog open={openResetPasswordDialog} onClose={onClickCloseResetPasswordDialog}>
                {!sendEmailFlag ? <>
                    <DialogTitle>{translate("Reset password")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            If you have forgotten your password, please enter the email you used during registration below, and we will send a reset link to your email.
                        </DialogContentText>
                        <TextField
                            variant="standard"
                            label="Email"
                            value={email}
                            autoFocus
                            onChange={onChangeEmail}
                            fullWidth
                            sx={{ mt: 2 }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClickCloseResetPasswordDialog}>
                            {translate("Cancel")}
                        </Button>
                        <Button
                            onClick={onClickSendEmail}
                            disabled={email.length === 0}
                            variant="outlined"
                        >
                            {translate("Send mail")}
                        </Button>
                    </DialogActions></> : <>
                    <DialogTitle>{translate("Please check your mailbox")}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography>
                                {translate("A password reset URL has been sent to the email address you provided.")}<br />{translate("If you do not receive an email, please check your email address and try again.")}<br />{translate("Also, the email may have been delivered to your junk mail folder or spam folder, so please check there as well.")}<br /><br />{translate("If you need assistance,")} <Link onClick={onClickNavigateContact} sx={{ cursor: "pointer" }}>{translate("please contact support.")}</Link>
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={onClickSendEmailAgain}
                        >
                            {translate("Try again")}
                        </Button>
                        <Button onClick={onClickCloseResetPasswordDialog} variant="outlined">
                            {translate("Close")}
                        </Button>
                    </DialogActions>
                </>
                }
            </Dialog>
        </>
    );
}
