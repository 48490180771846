import { Category, DoubleArrow } from "@mui/icons-material";
import { Box, Divider, Link, Paper, SxProps, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslateField } from "../appContext/AppContext";

export default function SearchDetailBasicInfo(props: {
    type: string;
    hashtags: string[];
    title: string
    description?: string
    category: string;
    no?: string
    sx?: SxProps;
}) {
    const { id } = useParams()
    const { type, hashtags, sx, category, title, description, no } = props;
    const translate = useTranslateField();
    return (
        <>
            <Paper
                sx={{
                    ...sx,
                    p: 2,
                    height: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 1,
                    }}
                >
                    <Typography variant="h6" color="secondary.main">
                        {translate(type)}
                    </Typography>
                    <DoubleArrow sx={{ color: "secondary.main" }} />
                    <Typography variant="h6" sx={{ wordBreak: "break-all", margin: "auto" }}>
                        {title}
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ display: "flex" }}>
                    <Typography
                        sx={{
                            color: "text.secondary",
                            minWidth: 120,
                        }}
                    >
                        {translate("Category")}
                    </Typography>:
                    <Link sx={{ margin: "auto" }}>{category}</Link>
                </Box>
                <Divider />
                <Box sx={{ display: "flex" }}>
                    <Typography
                        sx={{
                            color: "text.secondary",
                            minWidth: 120,
                        }}
                    >
                        {translate("No")}
                    </Typography>:
                    <Box
                        sx={{
                            display: "flex",
                            columnGap: 1,
                            flexWrap: "wrap",
                            justifyContent: "center",
                            width: "100%",
                        }}
                    >
                        {no ? no : "-"}
                    </Box>
                </Box>
                <Divider />
                <Typography className=" break-words whitespace-pre-wrap" sx={{ mt: 2 }}>
                    {description ? description : ""}
                </Typography>
            </Paper>
        </>
    );
}
