import { SxProps, alpha, useTheme } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import { Mail, Bookmark, BookmarkAdd, BookmarkRemove, ReadMore, Delete, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import PageRecords from '../routes/PageRecords';
import { Avatar, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, Link, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Tooltip, Typography, useMediaQuery, } from '@mui/material';
import { useAccount, useTranslateField } from '../appContext/AppContext';
import { ChangeEvent, HtmlHTMLAttributes, MouseEvent, useEffect, useRef, useState } from 'react';
import { BookMarkControllerEnum, ChangeOffersStatusDocument, LimitInput, OfferResponse, OfferStatusInputEnum, PostTypeRecord, SendPromotionalEmailDocument, SendPromotionalEmailInput, SendPromotionalEmailType } from '../gql/graphql';
import { CreateFileLink } from '../utils/files/Files';
import ImageNotSupportedSharpIcon from '@mui/icons-material/ImageNotSupportedSharp';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ApiPdf, ApiPdfInterface } from './PdfComponent/ApiPdf';
import { SendMailPage } from './MailManager/SendMailPage';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { useLazyQuery, useMutation } from '@apollo/client';
import { validate } from '@graphql-codegen/schema-ast';
import { Message } from './global/Message';

interface Data {
    uid: number;
    type: PostTypeRecord, //TODO: マージの際に仮のタイプとして入れました → ジさんに調整していただきたいです
    status: OfferStatusInputEnum,
    title: string;
    picture: "◯" | "×"
    maker: string;
    model: string;
    price: string;
    date: string;
    operation: "";
}

function createData(
    uid: number,
    type: PostTypeRecord, //TODO: マージの際に仮のタイプとして入れました → ジさんに調整していただきたいです
    status: OfferStatusInputEnum,
    title: string,
    picture: "◯" | "×",
    maker: string,
    model: string,
    price: string,
    date: string,
    operation: "",

): Data {
    return {
        uid,
        type,
        status,
        title,
        picture,
        maker,
        model,
        price,
        date,
        operation,
    };
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
}

interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler =
        (property: keyof Data) => (event: MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };

    const translate = useTranslateField();

    const headCells: readonly HeadCell[] = [
        {
            id: 'picture',
            numeric: true,
            disablePadding: false,
            label: translate('Picture'),
        },
        {
            id: 'title',
            numeric: false,
            disablePadding: true,
            label: translate('Title'),
        },
        {
            id: 'type',
            numeric: true,
            disablePadding: false,
            label: translate('Type'),
        },
        {
            id: 'status',
            numeric: true,
            disablePadding: false,
            label: translate('Status'),
        },
        {
            id: 'maker',
            numeric: true,
            disablePadding: false,
            label: translate('Maker'),
        },
        {
            id: 'model',
            numeric: true,
            disablePadding: false,
            label: translate('Model'),
        },
        {
            id: 'price',
            numeric: true,
            disablePadding: false,
            label: translate('Price'),
        },
        {
            id: 'date',
            numeric: true,
            disablePadding: false,
            label: translate('Date'),
        },
        {
            id: 'operation',
            numeric: true,
            disablePadding: false,
            label: translate('Operation'),
        },
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        // align={headCell.numeric ? 'right' : 'left'}
                        className={headCell.id == "picture" ? "min-w-[6rem]" : "min-w-[12rem]"}
                        align={"center"}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sx={headCell.id === "operation" ? { position: "sticky", right: 0, background: "white" } : {}}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {translate(headCell.label)}
                        {/* <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {translate(headCell.label)}
                            {orderBy === headCell.id ? (
                                <Box sx={visuallyHidden} component="span" >
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel> */}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface EnhancedTableToolbarProps {
    numSelected: number;
    total: number;
    editMode?: boolean;
    onAddBookMarkClick?: () => void
    onRemoveBookMarkClick?: () => void
    onDeleteOfferClick?: () => void
    onEditOfferClick?: (status: OfferStatusInputEnum) => void
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected, total, editMode, onAddBookMarkClick, onRemoveBookMarkClick, onDeleteOfferClick, onEditOfferClick } = props;
    const translate = useTranslateField();
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [status, setStatus] = useState(OfferStatusInputEnum.Open)

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value as OfferStatusInputEnum);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setStatus(OfferStatusInputEnum.Open)
    };

    const onOfferStatusChange = () => {
        onEditOfferClick && onEditOfferClick(status)
        handleClose()
    }

    return (
        <Toolbar
            sx={{
                pl: { xs: numSelected ? 1 : 2 },
                pr: { xs: 1, sm: 1 },
                ...(numSelected > 0 && {
                    bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
            }}
        >
            {numSelected > 0 ? (
                <></>
            ) : (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    variant="h6"
                    component="div"
                >
                    {`${translate("Result")} > ${total} ${translate("hits")}`}
                </Typography>
            )}
            {numSelected > 0 ? (
                <>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                        {editMode ? <>
                            <Tooltip title={translate("Delete selected posts")} placement="top" disableInteractive onClick={(e) => {
                                e.stopPropagation();
                                onDeleteOfferClick && onDeleteOfferClick()
                            }}>
                                <IconButton>
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={translate("Edit offer status")} placement="top" disableInteractive onClick={(e) => {
                                e.stopPropagation();
                            }}>
                                <IconButton onClick={handleClickOpen}>
                                    <ModeEditIcon />
                                </IconButton>
                            </Tooltip>
                            <Dialog
                                fullScreen={fullScreen}
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="responsive-dialog-title"
                            >
                                <DialogTitle id="responsive-dialog-title">
                                    {"Use Google's location service?"}
                                </DialogTitle>
                                <DialogContent>
                                    <DialogContentText sx={{ marginBottom: "1rem" }}>
                                        Batch update offer statuses
                                    </DialogContentText>
                                    <FormControl fullWidth>
                                        <InputLabel size="small" id="demo-simple-select-label">{translate("Status")}</InputLabel>
                                        <Select
                                            size='small'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={status}
                                            label="status"
                                            onChange={handleChange}
                                        >
                                            <MenuItem value={OfferStatusInputEnum.Closed}>{translate(OfferStatusInputEnum.Closed)}</MenuItem>
                                            <MenuItem value={OfferStatusInputEnum.Open}>{translate(OfferStatusInputEnum.Open)}</MenuItem>
                                            <MenuItem value={OfferStatusInputEnum.OutOfStock}>{translate(OfferStatusInputEnum.OutOfStock.replaceAll("_", " "))}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="error" autoFocus onClick={handleClose}>
                                        {translate("Disagree")}
                                    </Button>
                                    <Button color='success' onClick={onOfferStatusChange} autoFocus>
                                        {translate("Agree")}
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </> : <>
                            {/* <Tooltip title={translate("Contact")} placement="top" disableInteractive>
                                <IconButton>
                                    <Mail />
                                </IconButton>
                            </Tooltip> */}
                            <Tooltip title={translate("Add to bookmark")} placement="top" disableInteractive onClick={(e) => {
                                e.stopPropagation()
                                onAddBookMarkClick && onAddBookMarkClick()
                            }}>
                                <IconButton>
                                    <BookmarkAdd />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={translate("Remove from bookmark")} placement="top" disableInteractive onClick={() => onRemoveBookMarkClick && onRemoveBookMarkClick()}>
                                <IconButton>
                                    <BookmarkRemove />
                                </IconButton>
                            </Tooltip>
                        </>
                        }
                        <Typography
                            sx={{}}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {`${numSelected} ${translate("selected")}`}
                        </Typography>
                    </Box>
                </>
            ) : (
                <></>
            )}
        </Toolbar>
    );
}

export default function SearchResultTable({
    data,
    current,
    pageSizeList,
    pageSize,
    total,
    setCurrent,
    setPageSize,
    editMode,
    sx,
    onBookMarkChange,
    onDeleteOfferClick,
    onChangeOfferStatusClick,
}: {
    data: OfferResponse[],
    current: number,
    pageSizeList: number[],
    pageSize: number,
    total: number,
    setPageSize?: (size: number) => void,
    setCurrent?: (size: number) => void,
    editMode?: boolean,
    sx?: SxProps,
    onBookMarkChange?: (offerIds: string[], controller: BookMarkControllerEnum) => void
    onDeleteOfferClick?: (offerIds: string[]) => void,
    onChangeOfferStatusClick?: (offerIds: string[], status: OfferStatusInputEnum) => void,
}) {
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<keyof Data>('date');
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [dense, setDense] = useState(true);
    const apiPdfRef = useRef<ApiPdfInterface>(null)
    const topRef = useRef<HTMLDivElement>(null);
    const account = useAccount()
    const [sendPromotionalEmailCall,] = useMutation(SendPromotionalEmailDocument)

    useEffect(() => {

        let dataIds = data.map(v => {
            return v.id
        })
        let newSelected = selected.filter(select => {
            return dataIds.includes(select)
        })

        console.log("newSelected=", newSelected)
        setSelected(newSelected)
    }, [data])

    const handleRequestSort = (
        event: MouseEvent<unknown>,
        property: keyof Data,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.id + "");
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event: MouseEvent<unknown>, name: string) => {
        if (selected.includes(name)) {
            setSelected([...selected.filter(v => name != v)])
        } else {
            setSelected([...selected, name])
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        console.log("handleChangePage = ", newPage)
        console.log("topRef=", topRef.current)
        if (topRef.current) {
            topRef.current.scrollTop = 0
        }
        setCurrent && setCurrent(newPage)
    };

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        console.log("topRef=", topRef.current)
        if (topRef.current) {
            topRef.current.scrollTop = 0
        }
        setPageSize && setPageSize(parseInt(event.target.value, 10))
        // setCurrent && setCurrent(1)
    };

    const handleChangeDense = (event: ChangeEvent<HTMLInputElement>) => {
        setDense(event.target.checked);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const handleBookmark = (e: any, offerIds: string[], controller: BookMarkControllerEnum) => {
        e.stopPropagation();
        onBookMarkChange && onBookMarkChange(offerIds, controller)
    }

    const OnAddBookMarkClick = () => {
        onBookMarkChange && onBookMarkChange([...selected], BookMarkControllerEnum.Add)
    }

    const OnRemoveBookMarkClick = () => {
        onBookMarkChange && onBookMarkChange([...selected], BookMarkControllerEnum.Remove)
    }

    const OnDeleteOfferClick = () => {
        onDeleteOfferClick && onDeleteOfferClick([...selected])
    }

    const OnChangeOfferStatus = (status: OfferStatusInputEnum) => {
        onChangeOfferStatusClick && onChangeOfferStatusClick([...selected], status)
    }

    const navigate = useNavigate();
    const translate = useTranslateField();
    const CreatePdf = async (data: OfferResponse) => {
        console.log("createPdf start")
        let price = Number(data.price)
        let priceStr = ""
        if (price > 0) {
            priceStr = price.toLocaleString()
            if (data.current) {
                priceStr += " " + data.current
            }
        } else {

            priceStr = "Negotiable"
        }
        apiPdfRef.current?.createPdf({
            id: data.id,
            files: data.files.map(v => CreateFileLink(v.key)),
            title: data.title,
            type: data.type.label,
            maker: data.maker ? data.maker : "-",
            model: data.model ? data.model : "-",
            price: priceStr,
            description: data.description,
            link: PageRecords.SearchDetail.createSearchDetailLink(data.id),
        })
        console.log("createPdf end")
    }

    const OnSendEmail = async (sendType: SendPromotionalEmailType[], email: string[]) => {
        let response = await sendPromotionalEmailCall(
            {
                variables: {
                    data: {
                        mailList: email,
                        offerIds: [],
                        sendType: sendType,
                    }
                }
            }
        )

        if (response.data && response.data.SendPromotionalEmail.valueOf()) {
            Message.Success("send email success")
            return
        }

        if (response.errors) {
            Message.Error(response.errors[0].message)
            return
        }
    }

    return (
        <Box sx={{ ...sx }}>
            <Paper sx={{ width: '100%', mb: 2, overflow: "hidden" }}>
                <EnhancedTableToolbar
                    numSelected={selected.length}
                    total={total}
                    editMode={editMode}
                    onAddBookMarkClick={OnAddBookMarkClick}
                    onRemoveBookMarkClick={OnRemoveBookMarkClick}
                    onDeleteOfferClick={OnDeleteOfferClick}
                    onEditOfferClick={OnChangeOfferStatus}
                />
                <TableContainer ref={topRef} sx={{ height: 440 }}>
                    <Table
                        sx={{ minWidth: 1000 }}
                        size={dense ? 'small' : 'medium'}
                        stickyHeader
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={data.length}
                        />
                        <TableBody
                        >
                            {data.map((row, index) => {
                                const isItemSelected = isSelected(row.id);
                                const labelId = `enhanced-table-checkbox-${index}`;
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isItemSelected}
                                        sx={{
                                            cursor: 'pointer',
                                            "& .MuiTableCell-body": {
                                                // maxWidth: "200px",
                                                // overflowX: "hidden",
                                                // textOverflow: "ellipsis",
                                                // whiteSpace: "nowrap"
                                            }
                                        }}
                                    >
                                        <TableCell padding="checkbox"
                                            onClick={(event) => handleClick(event, row.id)}
                                        >
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ display: "flex", justifyContent: "center" }} className='min-w-[6rem]' align="center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                        >
                                            {
                                                row.files.length > 0 ?
                                                    <Avatar imgProps={{ loading: "lazy" }} variant="square" src={CreateFileLink(row.files[0].key)}></Avatar>
                                                    : <Avatar variant="square">
                                                        <ImageNotSupportedSharpIcon></ImageNotSupportedSharpIcon>
                                                    </Avatar>
                                            }
                                        </TableCell>
                                        <TableCell
                                            component="th"
                                            id={labelId}
                                            scope="row"
                                            padding="none"
                                            className="min-w-[15rem]"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                console.log("title is clicked ")
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                        >
                                            <Tooltip title={row.title} placement="right" disableInteractive>
                                                <Link>
                                                    {row.title}
                                                </Link>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell className='min-w-[12rem]' align="center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                        >{translate(row.type.label)}</TableCell>
                                        <TableCell className='min-w-[12rem]' align="center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                            sx={{
                                                fontWeight: "bold",
                                                color: row.status === OfferStatusInputEnum.Open ? "green" :
                                                    row.status === OfferStatusInputEnum.Closed ? "red" : ""
                                            }}
                                        >{translate(row.status.toString().replaceAll("_", " "))}</TableCell>
                                        <TableCell className='min-w-[12rem]' align="center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                        >{row.maker}</TableCell>
                                        <TableCell className='min-w-[12rem]' align="center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                        >{row.model}</TableCell>
                                        <TableCell className='min-w-[12rem]' align="center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                        >{row.price ? row.price + (row.current ? " " + row.current : "") : translate("Negotiable")}</TableCell>
                                        <TableCell className='min-w-[12rem]' align="center"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                editMode ? navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    : navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                            }}
                                        >{row.createTime}</TableCell>
                                        <TableCell className='min-w-[12rem]' align='center' sx={{ position: "sticky", right: 0, background: "white" }}>
                                            <Tooltip title={translate("Detail page")} placement="top" disableInteractive>
                                                <IconButton size="small" sx={{ p: 0 }} onClick={(e) => {
                                                    e.stopPropagation();
                                                    window.scrollTo(0, 0);
                                                    navigate(PageRecords.SearchDetail.createSearchDetailLink(row.id));
                                                }}>
                                                    <ReadMore />
                                                </IconButton>
                                            </Tooltip>
                                            {editMode ? <>
                                                <Tooltip title={translate("Edit")} placement="top" disableInteractive>
                                                    <IconButton size="small" sx={{ p: 0 }} onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.scrollTo(0, 0);
                                                        navigate(PageRecords.EditOffer.createEditOfferLink(row.id))
                                                    }}>
                                                        <Edit />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={translate("Delete")} placement="top" disableInteractive>
                                                    <IconButton size="small" sx={{ p: 0 }} onClick={(e) => {
                                                        e.stopPropagation();
                                                        onDeleteOfferClick && onDeleteOfferClick([row.id])
                                                    }}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </> : <>
                                                {
                                                    !row.isBookmarked ?
                                                        <Tooltip title={translate("Add to bookmark")} placement="top" disableInteractive>
                                                            <IconButton size="small" sx={{ p: 0 }} onClick={(e) => handleBookmark(e, [row.id + ""], BookMarkControllerEnum.Add)}>
                                                                <Bookmark sx={{ color: "rgba(0,0,0,0.54)" }} />
                                                            </IconButton>
                                                        </Tooltip> :
                                                        <Tooltip title={translate("Remove from bookmark")} placement="top" disableInteractive>
                                                            <IconButton size="small" sx={{ p: 0 }} onClick={(e) => handleBookmark(e, [row.id + ""], BookMarkControllerEnum.Remove)}>
                                                                <Bookmark sx={{ color: "red" }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                }
                                                <Tooltip title={translate("Pdf")} placement="top" disableInteractive>
                                                    <IconButton size="small" sx={{ p: 0 }} onClick={async (e) => {
                                                        e.stopPropagation();
                                                        await CreatePdf(row)
                                                    }}>
                                                        <PictureAsPdfIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                {
                                                    account?.IsManager() &&
                                                    <Tooltip title={translate("SendMail")} placement="top" disableInteractive>
                                                        <IconButton size="small" sx={{ p: 0 }} onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}>
                                                            <SendMailPage
                                                                onSend={async (sendType: SendPromotionalEmailType[], email: string[]) => {
                                                                    let response = await sendPromotionalEmailCall(
                                                                        {
                                                                            variables: {
                                                                                data: {
                                                                                    mailList: email,
                                                                                    offerIds: [row.id],
                                                                                    sendType: sendType,
                                                                                }
                                                                            }
                                                                        }
                                                                    )

                                                                    if (response.data && response.data.SendPromotionalEmail.valueOf()) {
                                                                        Message.Success("send email success")
                                                                        return
                                                                    }

                                                                    if (response.errors) {
                                                                        Message.Error(response.errors[0].message)
                                                                        return
                                                                    }
                                                                }}
                                                                button={
                                                                    <Mail />
                                                                }
                                                                title={row.title}
                                                            ></SendMailPage>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </>}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                        labelRowsPerPage={translate("Rows per page")}
                    rowsPerPageOptions={pageSizeList}
                    component="div"
                    count={total}
                    rowsPerPage={pageSize}
                    page={current}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    showFirstButton
                    showLastButton
                />
            </Paper>
            {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
            <div className=' absolute top-[-9999px]'>
                <ApiPdf ref={apiPdfRef}></ApiPdf>
            </div>
        </Box >
    );
}