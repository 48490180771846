import { Box } from "@mui/material";
import { ReactNode } from "react";

type Props = {
    children: ReactNode;
};

export default function PageWrapper(props: Props) {
    return (
        <>
            {/* header's height is 70px (ResponsiveAppBar.tsx => Toolbar) */}
            <Box minHeight="calc(100vh - 70px)" minWidth="320px">
                {props.children}
            </Box>
        </>
    );
}
