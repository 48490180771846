import { Box, Container, Divider, List, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { MailManagerFilterInput, MailManagerListDocument, MailManagerRecord, MailManagerTypeEnum, OfferResponse } from "../gql/graphql";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { useTranslateField } from './../appContext/AppContext';
import { MailManagerSearchComponent, MailManagerSearchFilterInterface } from "../components/MailManager/MailManagerSearchComponent";
import { validate } from "@graphql-codegen/schema-ast";
import { SendMailPage } from "../components/MailManager/SendMailPage";

interface Column {
    id: 'name' | 'code' | 'population' | 'size' | 'density';
    label: string;
    minWidth?: number;
    align?: 'right';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'code', label: 'ISO\u00a0Code', minWidth: 100 },
    {
        id: 'population',
        label: 'Population',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'size',
        label: 'Size\u00a0(km\u00b2)',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toLocaleString('en-US'),
    },
    {
        id: 'density',
        label: 'Density',
        minWidth: 170,
        align: 'right',
        format: (value: number) => value.toFixed(2),
    },
];

interface Data {
    name: string;
    code: string;
    population: number;
    size: number;
    density: number;
}

function createData(
    name: string,
    code: string,
    population: number,
    size: number,
): Data {
    const density = population / size;
    return { name, code, population, size, density };
}

const rows = [
    createData('India', 'IN', 1324171354, 3287263),
    createData('China', 'CN', 1403500365, 9596961),
    createData('Italy', 'IT', 60483973, 301340),
    createData('United States', 'US', 327167434, 9833520),
    createData('Canada', 'CA', 37602103, 9984670),
    createData('Australia', 'AU', 25475400, 7692024),
    createData('Germany', 'DE', 83019200, 357578),
    createData('Ireland', 'IE', 4857000, 70273),
    createData('Mexico', 'MX', 126577691, 1972550),
    createData('Japan', 'JP', 126317000, 377973),
    createData('France', 'FR', 67022000, 640679),
    createData('United Kingdom', 'GB', 67545757, 242495),
    createData('Russia', 'RU', 146793744, 17098246),
    createData('Nigeria', 'NG', 200962417, 923768),
    createData('Brazil', 'BR', 210147125, 8515767),
];


export function PagesMailManager() {
    const [mailManagerListCall] = useLazyQuery(MailManagerListDocument)
    const [mailRecords, setMailRecords] = useState<Array<MailManagerRecord>>([])
    const translate = useTranslateField()

    const [current, setCurrent] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const pageSizeList = [2, 10, 30, 50];
    const [filterObj, setFilterObj] = useState<MailManagerFilterInput>(
        {
            date: "",
            emails: "",
            offerTitle: "",
            type: [],
        }
    )

    useAsyncEffect(async () => {
        let response = await mailManagerListCall({
            variables: {
                limit: {
                    current: current,
                    pageSize: pageSize,
                },
                filter: filterObj,
            },
            fetchPolicy: "network-only",
        })

        if (response.data && response.data.MailManagerList) {
            if (response.data.MailManagerList.list) {
                let newArr = response.data.MailManagerList.list as Array<MailManagerRecord>
                setMailRecords([...newArr])
            }
            setTotal(response.data.MailManagerList.total)
        }

        console.log("response =", response);
    }, [current, pageSize])

    const handleChangePage = (event: unknown, newPage: number) => {
        setCurrent(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPageSize(+event.target.value);
        setCurrent(0);
    };

    const GetMailManagerList = () => {
    }

    const OnSend = async (filter: MailManagerSearchFilterInterface) => {
        console.log("filter=", filter)
        let dateStr = ""
        if (filter.date) {
            let date = new Date(filter.date)
            dateStr = date.getFullYear() + "-" + ("0" + (date.getMonth() + 1)).slice(-2) + "-" + ("0" + date.getDate()).slice(-2)
            console.log("dateStr=", dateStr)
        }

        let typeList: Array<MailManagerTypeEnum> = []

        if (filter.type === "") {
            typeList = Object.values(MailManagerTypeEnum)
        } else {
            Object.values(MailManagerTypeEnum).forEach(v => {
                if (v === filter.type) {
                    typeList.push(v)
                }
            })
        }

        var sendFilter: MailManagerFilterInput = {
            date: dateStr,
            emails: filter.email,
            offerTitle: filter.offerTitle,
            type: typeList,
        }

        setFilterObj(sendFilter)

        let response = await mailManagerListCall({
            variables: {
                filter: sendFilter,
                limit: {
                    current: current,
                    pageSize: pageSize
                }
            },
            fetchPolicy: "network-only",
        })

        if (response.data && response.data.MailManagerList) {
            let newArr = response.data.MailManagerList.list as Array<MailManagerRecord>
            setMailRecords([...newArr])
            setTotal(response.data.MailManagerList.total)
        }
    }

    return (
        <Container>
            <Typography variant="h4">{translate("Mail manager")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph mt={2}>
                {/* {translate("You can view bookmarked posts on this page.")} */}
            </Typography>
            <Box>
                <MailManagerSearchComponent onSend={OnSend} ></MailManagerSearchComponent>
            </Box>
            <Box>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell
                                        key="date"
                                        align={"center"}
                                        style={{ minWidth: "10rem" }}
                                    >
                                        {translate("date")}
                                    </TableCell>
                                    <TableCell
                                        key="emails"
                                        align={"center"}
                                        style={{ minWidth: "10rem" }}
                                    >
                                        {translate("emails")}
                                    </TableCell>
                                    <TableCell
                                        key="offer"
                                        align={"center"}
                                        style={{ minWidth: "10rem" }}
                                    >
                                        {translate("offer")}
                                    </TableCell>
                                    <TableCell
                                        key="type"
                                        align={"center"}
                                        style={{ minWidth: "10rem" }}
                                    >
                                        {translate("type")}
                                    </TableCell>
                                    <TableCell
                                        key="user"
                                        align={"center"}
                                        style={{ minWidth: "10rem" }}
                                    >
                                        {translate("user")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mailRecords.map((row, key) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={"col" + key}>
                                            <TableCell key={row.offer.id + "data"} align={"left"}>
                                                {row.date}
                                            </TableCell>
                                            <TableCell key={row.offer.id + "emails"} align={"center"}>
                                                {row.emails.slice(0, 1).map((v) => {
                                                    return v
                                                })}
                                                ({row.emails.length})
                                            </TableCell>
                                            <TableCell key={row.offer.id + "title"} align={"left"}>
                                                {row.offer.title}
                                            </TableCell>
                                            <TableCell key={row.offer.id + "type"} align={"center"}>
                                                {translate(row.type)}
                                            </TableCell>
                                            <TableCell key={row.offer.id + "user"} align={"center"}>
                                                {row.user?.email}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        labelRowsPerPage={translate("Rows per page")}
                        rowsPerPageOptions={pageSizeList}
                        component="div"
                        count={total}
                        rowsPerPage={pageSize}
                        page={current}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Box>
        </Container>
    )
}