import { Box, Button, Typography } from "@mui/material";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import { useEffect } from "react";

export default function PagesNotFound() {
    useEffect(() => {
        const metaTag = document.createElement("meta");
        metaTag.name = "robots";
        metaTag.content = "noindex";
        document.head.appendChild(metaTag);

        // Remove the "noindex" meta tag when leaving the page
        return () => {
            document.head.removeChild(metaTag);
        };
    }, []);

    return (
        <>
            <Box
                sx={{
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: 2,
                }}>
                <SentimentVeryDissatisfiedIcon sx={{ fontSize: 56 }} />
                <Typography variant="h4">404 Not Found</Typography>
                <Button component="a" href="/" variant="contained" color="secondary" size="large">
                    Home
                </Button>
            </Box>
        </>
    );
}
