import { Theme, styled } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { ArrowDownward } from "@mui/icons-material";

const ProductHeroLayoutRoot = styled("section")(({ theme }) => ({
    color: theme.palette.common.white,
    position: "relative",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
        // header's height is 70px (ResponsiveAppBar.tsx => Toolbar)
        height: "calc(100vh - 70px)",
        minHeight: 500,
        maxHeight: 1300,
    },
}));

const Background = styled(Box)({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    zIndex: -2,
});

interface ProductHeroLayoutProps {
    sxBackground: SxProps<Theme>;
}

export default function ProductHeroLayout(
    props: React.HTMLAttributes<HTMLDivElement> & ProductHeroLayoutProps
) {
    const { sxBackground, children } = props;
    return (
        <ProductHeroLayoutRoot>
            <Container
                sx={{
                    my: 14,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                {children}
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        backgroundColor: "common.black",
                        opacity: 0.5,
                        zIndex: -1,
                    }}
                />
                <Background sx={sxBackground} />
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "absolute",
                        bottom: 32,
                    }}
                >
                    <ArrowDownward
                        height="16"
                        width="12"
                        htmlColor="#F9F871"
                    />
                </Box>
            </Container>
        </ProductHeroLayoutRoot>
    );
}
