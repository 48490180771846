import assert from "assert";
import React, { ReactNode, useState } from "react";
import { useLocation,useParams } from "react-router-dom";

/** Calls the method at the "componentDidMount" time, and also works with async functions.
 * 
 * This lets you write this:
 * UseComponentDidMountAsync( async()=>{
 *     ...
 * } )
 * 
 * instead of this:
 * React.useEffect( ()=>{
 *     async()=>{
 *      //componentDidMount
 *      ...
 *     }
 * }, [] );
 * 
*/
export function useComponentDidMount( callbackCanBeAsync:any ){
    React.useEffect( ()=>{
        // componentDidMount timing is here
        callbackCanBeAsync();
    }, [] );
}

/** Works with async functions */
export function useAsyncEffect( callbackCanBeAsync:any, dependencies?: React.DependencyList ){
    React.useEffect( ()=>{
        callbackCanBeAsync();
    }, dependencies );
}

export function useComponentDidUpdate( callbackCanBeAsync:any ){
    return useAsyncEffect(callbackCanBeAsync);
}

/** Returns a new function that, when invoked, will cause something similar to forceUpdate in a class-based component. */
export function useForceUpdate(){
    const [,forceUpdate] = React.useReducer(_=>!_,false);
}

export function useComponentDidUnmount( callbackCanBeAsync:any ){
    React.useEffect( ()=>{
        return ()=>{
            // componentDidUnmount timing is here
            callbackCanBeAsync();
        }
    }, [] );
}

/** 
 * @returns a new URLSearchParams instance with the current url's query parameters
 */
export function useQueryParameters(){
    const {search} = useLocation();
    return new URLSearchParams(search);
}

/**
 * @returns A single query parameter, by name.
 */
export function useQueryParameter(parameterName:string){
    const parameters =useQueryParameters();
    const value = parameters.get(parameterName);
    if(value===null){
        return undefined;
    }
    return value;
}
export function useQueryParameterInt(parameterName:string){
    const string = useQueryParameter(parameterName);
    if(!string){
        return undefined;
    }
    const value = parseInt(string);
    if(isNaN(value)){
        return undefined;
    }
    return value;
}


export function useUrlParameter(parameterName:string){
    const parameters:any = useParams();
    if( parameters[parameterName]===undefined ){
        throw new Error("Missing expected URL parameter: "+parameterName);
    }
    return parameters[parameterName] as string;
}

export function useMaybeUrlParameter(parameterName:string){
    const parameters:Object = useParams() 
    let ret = undefined
    Object.entries(parameters).find(([k,v])=>{
        if(k=== parameterName){
            ret = v
        }
    })
    if( ret ===undefined ){
        return undefined
    }
    return ret 
}


export function useStateWithLocalStorage({localStorageKey,initialValue}:{localStorageKey:string,initialValue:string}){
    if(localStorage.getItem(localStorageKey)){
        initialValue = ""+localStorage.getItem(localStorageKey);
    }
    const [value,setValue] = useState(initialValue);
    useComponentDidMount(()=>{
      if(localStorage.getItem(localStorageKey)){
        setValue(""+localStorage.getItem(localStorageKey));
      }
    })
    useComponentDidUpdate(()=>{
      localStorage.setItem(localStorageKey, value);
    })
    return [value,setValue] as [typeof value,typeof setValue];
}  

/**
 * @returns
 *  a component or undefined, depending on the value of the condition
 *  a setter, that you pass the condition to
 */
export function useConditionalComponent(component:JSX.Element,defaultValue?:boolean){
    const [value,setValue] = useState<boolean>(defaultValue||false);
    return [
        value?component:undefined,
        setValue
    ] as const;
}