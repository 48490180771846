import { Route, Routes } from "react-router-dom";
import PageRecords, { PageRecord } from "./PageRecords";

export default function Router() {
    return (
        <Routes>
            {(Object.values(PageRecords) as PageRecord[]).map(
                ({ content: Component, path, children }: PageRecord, index) => {
                    if (children) {
                        const parentPath = path;
                        return (
                            <Route
                                key={`routers-${index}`}
                                path={path}
                                element={<Component />}
                            >
                                {Object.values(children).map(
                                    (
                                        {
                                            content: ChildrenComponent,
                                            path,
                                        }: PageRecord,
                                        childIndex
                                    ) => {
                                        return (
                                            <Route
                                                key={`routers-${index}-${childIndex}`}
                                                path={parentPath + path}
                                                element={<ChildrenComponent />}
                                            />
                                        );
                                    }
                                )}
                            </Route>
                        );
                    }
                    return (
                        <Route
                            key={`routers-${index}`}
                            path={path}
                            element={<Component />}
                        />
                    );
                }
            )}
        </Routes>
    );
}
