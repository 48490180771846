import PagesAccountLayout from "../pages/PagesAccountLayout";
import PagesAccountHome from "../pages/PagesAccountHome";
import PagesHome from "../pages/PagesHome";
import PagesNotFound from "../pages/PagesNotFound";
import { PagesSessionVerification } from "../pages/PagesSessionVerfication";
import PagesSignIn from "../pages/PagesSignIn";
import PagesSignUp from "../pages/PagesSignUp";
import PagesAccountProfile from "../pages/PagesAccountProfile";
import PagesAccountEditPost from "../pages/PagesAccountEditPost";
import PagesAccountBookmark from "../pages/PagesAccountBookmark";
import PagesAccountMailSetting from "../pages/PagesAccountMailSetting";
import PagesContact from "../pages/PagesContact";
import PagesSearch from "../pages/PagesSearch";
import { PageFileTest } from "../pages/PageFileTest";
import PagesCategory from "../pages/PagesCategory";
import PagesPost from "../pages/PagesPost";
import PagesSearchDetail from "../pages/PagesSearchDetail";
import { Config } from "../config/config";
import PagesTerms from "../pages/PagesTerms";
import PagesPrivacy from "../pages/PagesPrivacy";
import PagesResetPassword from "../pages/PagesResetPassword";
import PagesAccountMessage from "../pages/PagesAccountMessage";
import { PdfComponent } from "../components/PdfComponent/PdfComponent";
import { PagesAnalysis } from "../pages/PagesAnalysis";
import { PagesMailManager } from "../pages/PagesMailManager";
import { PageAccountManager } from "../pages/PageAccountManager";

export interface PageRecord {
    path: string;
    content: (prop?: any) => JSX.Element | null;
    requiresLogin?: boolean;
    withoutFooter?: boolean;
    children?: { [key: string]: PageRecord };
}

const PageRecords = {
    Home: {
        path: "/",
        content: PagesHome,
        requiresLogin: false,
    },

    Search: {
        path: "/search",
        content: PagesSearch,
        requiresLogin: false,
    },

    SearchDetail: {
        path: "/search/:id",
        content: PagesSearchDetail,
        requiresLogin: false,
        createSearchDetailLink: (id: string) =>
            PageRecords.Search.path + `/${id}`,
    },

    Category: {
        path: "/category",
        content: PagesCategory,
        requiresLogin: false,
    },

    Post: {
        path: "/post",
        content: PagesPost,
        requiresLogin: false,
    },

    EditOffer: {
        path: "/post/:id",
        content: PagesPost,
        requiresLogin: false,
        createEditOfferLink: (id: string) => PageRecords.Post.path + `/${id}`,
    },

    Contact: {
        path: "/contact",
        content: PagesContact,
        requiresLogin: false,
    },

    SignUp: {
        path: "/signup",
        content: PagesSignUp,
        withoutFooter: true,
        requiresLogin: false,
    },

    SignIn: {
        path: "/signin",
        content: PagesSignIn,
        withoutFooter: true,
        requiresLogin: false,
    },

    DEVELOPMENT__ResetPassword: {
        path: "/resetpassword",
        content: PagesResetPassword,
        withoutFooter: true,
        requiresLogin: false,
    },

    Account: {
        path: "/account",
        content: PagesAccountLayout,
        withoutFooter: true,
        requiresLogin: true,
        children: {
            // Home: {
            //     path: "",
            //     content: PagesAccountHome,
            //     withoutFooter: true,
            // },
            Profile: {
                // path: "/profile",
                path: "",
                content: PagesAccountProfile,
                withoutFooter: true,
            },
            Message: {
                path: "/message",
                content: PagesAccountMessage,
                withoutFooter: true,
            },
            EditPost: {
                path: "/edit",
                content: PagesAccountEditPost,
                withoutFooter: true,
            },
            Bookmark: {
                path: "/bookmark",
                content: PagesAccountBookmark,
                withoutFooter: true,
            },
            MailSetting: {
                path: "/mail",
                content: PagesAccountMailSetting,
                withoutFooter: true,
            },
            Analysis:{
                path: "/analysis",
                content: PagesAnalysis,
                withoutFooter: true,
            },
            MailManager:{
                path: "/mailManager",
                content: PagesMailManager,
                withoutFooter: true,
            },
            AccountManager:{
                path: "/accountManager",
                content: PageAccountManager,
                withoutFooter: true,
            },
        },
    },

    NotFound: {
        path: "*",
        content: PagesNotFound,
        requiresLogin: false,
    },

    VerificationPage: {
        path: "/verification",
        content: PagesSessionVerification,
        requiresLogin: false,
    },

    // FileTest: {
    //     path: "/filetest",
    //     content: PageFileTest,
    //     requiresLogin: false,
    // },

    // PdfComponent: {
    //     path: "/pdf",
    //     content: PdfComponent,
    //     requiresLogin: false,
    // },

    Terms: {
        path: "/terms",
        content: PagesTerms,
        requiresLogin: false,
    },

    Privacy: {
        path: "/privacy",
        content: PagesPrivacy,
        requiresLogin: false,
    },
};
export default PageRecords;

export const PathsWithoutFooter = () => {
    const paths: string[] = [];
    const findPaths = (obj: any, parentPath: string) => {
        for (const key in obj) {
            let currentPath =
                obj[key].path === "/"
                    ? parentPath
                    : `${parentPath}/${obj[key].path}`.replace(/\/+/g, "/");
            currentPath = currentPath.replace(/\/undefined\//g, "/");
            if (currentPath.endsWith("/undefined")) {
                currentPath = currentPath.slice(0, -"/undefined".length);
            }
            if (obj[key].withoutFooter) {
                paths.push(currentPath);
            }
            if (typeof obj[key] === "object" && obj[key] !== null) {
                findPaths(obj[key], currentPath);
            }
        }
    };
    findPaths(PageRecords, "");
    return paths;
};
