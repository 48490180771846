import { Navigate, Route, useLocation, useNavigate } from "react-router-dom";
import PageRecords, { PageRecord } from "./PageRecords";
import { ReactNode, useContext, useEffect, useLayoutEffect } from "react";
import { AppContext } from "../appContext/AppContext";

export function RequiresLoginCheck() {
    const local = useLocation();
    const context = useContext(AppContext);
    const navigate = useNavigate();
    useEffect(() => {
        if (context && context.account) {
            let account = context.account;
            if (
                local.pathname === PageRecords.SignIn.path ||
                local.pathname === PageRecords.SignUp.path
            ) {
                if (account.IsLogin()) {
                    navigate(PageRecords.Account.path);
                }
            }

            const pageList = Object.values(PageRecords) as PageRecord[];
            let requiresLogin = getRequiresLoginFromPageRrecordByURI(
                local.pathname,
                "",
                pageList
            );
            if (requiresLogin) {
                if (!account.IsLogin()  ) {
                    navigate(PageRecords.Home.path);
                }
            }
        }
    }, [local]);
    return <></>;
}

function getRequiresLoginFromPageRrecordByURI(
    uri: string,
    path: string,
    pageRecords: Array<PageRecord>
): boolean {
    for (let i of pageRecords) {
        if (i.children) {
            let childrenPages = Object.values(i.children);
            let ret = getRequiresLoginFromPageRrecordByURI(
                uri,
                i.path,
                childrenPages
            );
            if (ret) {
                return ret;
            }
        }
        if (uri === path + i.path) {
            return i.requiresLogin !== undefined ? i.requiresLogin : true;
        }
    }
    return false;
}
