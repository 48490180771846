import { Box, Container, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import { useLocation, useNavigate, useNavigation, useParams } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import { useContext, useEffect } from "react";
import { AppContext } from "../appContext/AppContext";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Graphs } from "../appContext/Graphs";
import { User } from "../gql/graphql";

export function PagesSessionVerification() {
    const location = useLocation();
    const state = location.state
    const searchParams = new URLSearchParams(location.search);
    const session = searchParams.get("session")
    const navigate = useNavigate()
    const context = useContext(AppContext)
    const [verfication, { data }] = useLazyQuery(Graphs.Verification)

    useEffect(() => {
        if (data) {
            let user = data.Verification as User
            if (data.Verification) {
                context?.account.LogIn(session ? session : "", user)
                navigate(PageRecords.Account.path)
            }
        }
    }, [data])

    useEffect(() => {
        if (session && session.length > 0) {
            context?.account.SetSession(session)
            //TODO:验证
            verfication({
                variables: {
                    session: session
                }
            })
        }
    }, [])

    return (
        <Container component="main" >
            <Box className=" text-center min-h-screen flex flex-col items-center justify-center">
                <EmailIcon className=" text-gray-600 text-6xl"></EmailIcon>
                <Typography className=" mt-4">The login link has been sent to your registered email {state ? state.email : ""}, please confirm in the email and click the login link.</Typography>
            </Box>
        </Container>
    )
}