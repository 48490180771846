import React, { useEffect, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import { PdfComponent } from "./PdfComponent";
import { PdfBodyInterface } from "./pdfBodyComponent/PdfBody";
import html2pdf from "../../js/htmtopdf";
import { CreateFileLink } from "../../utils/files/Files";

export interface ApiPdfInterface {
    createPdf: (data: PdfBodyInterface) => void;
}

export const ApiPdf = React.forwardRef((props, ref) => {
    const defaultFileName = "elastic-hjc.pdf";
    const [pdfBody, setPdfBody] = useState<PdfBodyInterface | null>(null);
    useEffect(() => {
        if (pdfBody) {
            console.log("渲染完成");
            var element = document.getElementById(`Pdf${pdfBody.id}`);
            if (element) {
                var opt = {
                    margin: 0,
                    filename: pdfBody.title.length ? pdfBody.title + ".pdf" : defaultFileName,
                    image: { type: "jpeg", quality: 1 },
                    pagebreak: { mode: "avoid-all" },
                    enableLinks: true,
                    html2canvas: {
                        useCORS: true,
                    },
                };
                html2pdf()
                    .set(opt)
                    .from(element)
                    .save();
                setPdfBody(null);
            }
        }
    }, [pdfBody]);

    React.useImperativeHandle(ref, () => ({
        createPdf: (data: PdfBodyInterface) => {
            console.log("data=", data);
            setPdfBody(data);
        },
    }));

    if (!pdfBody) {
        return <></>;
    }
    return <PdfComponent data={pdfBody} />;
});
