import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Badge,
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    Pagination,
    Paper,
    SwipeableDrawer,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useAccount, useTranslateField } from "../appContext/AppContext";
import {
    Cancel,
    ExpandMore,
    MoreHoriz,
    Refresh,
    Send,
} from "@mui/icons-material";
import SearchBar from "../components/SearchBar";
import theme from "../theme";
import { ButtonHTMLAttributes, MouseEvent, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import OfferCard from "../components/OfferCard";
import { ConfirmPasswordDocument, LimitInput, MessageGroupFilterStatusEnum, MessageGroupListDocument, MessageGroupRecord, MessageGroupResponse, MessageListDocument, MessageRecord, PostMessageDocument, PostMessageInput, SendMailEnum, User } from "../gql/graphql";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { CreateFileLink } from "../utils/files/Files";

export default function PagesAccountMessage() {
    const navigate = useNavigate();
    const translate = useTranslateField();
    const [messageGroupListCall,] = useLazyQuery(MessageGroupListDocument, {
        fetchPolicy: "network-only"
    })
    const [messageGroupList, setMessageGroupList] = useState<Array<MessageGroupRecord>>([])
    const [total, setTotal] = useState(0)
    const account = useAccount()
    const [messageListCall,] = useLazyQuery(MessageListDocument, {
        fetchPolicy: "network-only"
    })
    const [activeMessageGroup, setActiveMessageGroup] = useState<MessageGroupRecord | undefined>(undefined)
    const [messageList, setMessageList] = useState<Array<MessageRecord>>([])
    const [sendMessageText, setSendMessageText] = useState("")
    const [messageGroupStatus, setMessageGroupStatus] = useState<MessageGroupFilterStatusEnum>(MessageGroupFilterStatusEnum.All)
    const [sendMessageTextCall,] = useMutation(PostMessageDocument)
    const sendRef = useRef(null)

    useAsyncEffect(async () => {
        if (activeMessageGroup) {
            let response = await messageListCall({
                variables: {
                    groupId: activeMessageGroup.id
                }
            })

            if (response.data) {
                setMessageList(response.data.MessageList)
            }
        }

    }, [activeMessageGroup, messageList])

    if (!account) {
        return <></>
    }

    const [current, setCurrent] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    useAsyncEffect(async () => {
        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };
        let response = await messageGroupListCall({
            variables: {
                limit: limitInput,
                filter: {
                    messageGroupStatus: messageGroupStatus
                }
            }
        })

        if (response.data) {
            console.log("messageGroupList=", response.data.MessageGroupList)
            setMessageGroupList(response.data.MessageGroupList.messageGroups)
            setTotal(response.data.MessageGroupList.total)
        }

    }, [messageGroupStatus, activeMessageGroup])

    const menuOptions = [
        { label: translate("All message"), status: MessageGroupFilterStatusEnum.All },
        { label: translate("Unread"), status: MessageGroupFilterStatusEnum.Unread },
    ];
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleNavMenuNavigate = (status: MessageGroupFilterStatusEnum) => () => {
        setMessageGroupStatus(status)
        setAnchorElNav(null);
    };

    const [open, setOpen] = useState<boolean>(false);
    const handleOpenMessage = () => {
        console.log("open message")
        setOpen(true);
    };

    const handleCloseMessage = () => {
        if (sendMessageText.length > 0) {
            let isConfirm = confirm("The entered content will be cleared after returning, whether to confirm the return.")
            if (isConfirm) {
                setSendMessageText("")
            } else {
                return
            }
        }
        setActiveMessageGroup(undefined)
        setOpen(false);
    };

    const OnSendMessage = async () => {
        if (sendMessageText.length > 0) {
            if (activeMessageGroup) {
                let postMessageData: PostMessageInput = {
                    offerId: activeMessageGroup.offer.id,
                    groupId: activeMessageGroup.id,
                    text: sendMessageText,
                }

                let response = await sendMessageTextCall({
                    variables: {
                        data: postMessageData
                    }
                })

                if (response.data && response.data.PostMessage.valueOf()) {
                    setSendMessageText("")
                    let response = await messageListCall({
                        variables: {
                            groupId: activeMessageGroup.id
                        }
                    })

                    if (response.data) {
                        setMessageList(response.data.MessageList)
                    }
                }
            }
        }
    }

    const GetUserName = (data: MessageGroupRecord): string => {
        let record = getUserRecord(data)
        if (record) {
            return record.avatar ? record.avatar : ""
        }
        return ""
    }

    const GetUserAdvatars = (data: MessageGroupRecord): ReactNode => {
        let record = getUserRecord(data)
        console.log("get useRecord advatars=", record)
        if (record) {
            if (record.avatar && record.avatar.length > 0) {
                return <Avatar src={CreateFileLink(record.avatar)} />
            }
        }
        return <Avatar />
    }

    const getUserRecord = (data: MessageGroupRecord): (User | undefined) => {
        console.log("getUserRecord start")
        console.log("userList=", data.userList)
        console.log("last message data=", data.lastMessages)
        let useRecord = account.GetUser()
        if (useRecord) {
            let entry = data.userList.find(user => user.id !== useRecord?.id)
            console.log("getUserRecord end")
            return entry
        }
        return undefined
    }

    const GetProductName = (data: MessageGroupRecord): string => {
        let useRecord = account.GetUser()
        if (useRecord) {
            let entry = data.userList.find(v => v.id != useRecord?.id)
            if (entry) {
                return entry.email + "-" + (data.groupName ? data.groupName : data.offer.title)
            }
        }
        return ""
    }

    const getMessageUserRecord = (userId: string): (User | undefined) => {
        if (activeMessageGroup) {
            let entry = activeMessageGroup.userList.find(v => v.id === userId)
            return entry
        }
        return undefined
    }

    const GetMessageUserName = (userId: string): string => {
        let userRecord = getMessageUserRecord(userId)
        if (userRecord) {
            let name = ""
            if (userRecord.familyName && userRecord.firstName) {
                name = userRecord.familyName + " " + userRecord.firstName
            }
            return name.length > 1 ? name : userRecord.email
        }
        return ""
    }

    const GetMessageUserAvatar = (userId: string): ReactNode => {
        let userRecord = getMessageUserRecord(userId)
        console.log("useRecord=", userRecord)
        console.log("userId=", userId)
        if (userRecord) {
            if (userRecord.avatar) {
                return <Avatar src={CreateFileLink(userRecord.avatar)} />
            }
        }
        return <Avatar />
    }

    return (
        <>
            <Typography variant="h4">{translate("Message")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph mt={2}>
                {translate(
                    "You can connect with other users and communicate through our messaging feature. You can engage in direct conversations with other users, such as inquiring about products or any other topics of interest."
                )}
            </Typography>
            <Paper sx={{ px: { xs: 0, sm: 2 }, py: 2, mt: 4 }}>
                <Box sx={{ px: { xs: 2, sm: 0 } }}>
                    <SearchBar
                        sx={{
                            boxShadow: "none",
                            border: `solid 2px ${theme.palette.divider}`,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        mt: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                        borderBottom: `solid 1px ${theme.palette.divider}`,
                        pb: 2,
                        px: { xs: 2, sm: 0 },
                    }}
                >
                    <Button variant="outlined" size="small">
                        <Refresh fontSize="small" />
                        <Typography sx={{ ml: 1 }}>
                            {translate("Refresh")}
                        </Typography>
                    </Button>
                    <Box
                        sx={{
                            display: { xs: "block", md: "none" },
                        }}
                    >
                        <Tooltip title={translate("Menu")}>
                            <IconButton
                                size="small"
                                onClick={handleOpenNavMenu}
                                sx={{
                                    border: `solid 1px ${theme.palette.divider
                                        }`,
                                }}
                            >
                                <MoreHoriz fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{ top: "8px" }}
                        >
                            {menuOptions.map((option) => (
                                <MenuItem
                                    id={"mesnuItem" + option.label}
                                    key={`menu-${option.label}-mobile`}
                                    onClick={handleNavMenuNavigate(option.status)}
                                    sx={{
                                        backgroundColor: option.status === messageGroupStatus ? "#C8C8C8" : ""
                                    }}
                                >
                                    <Typography
                                        component="a"
                                        textAlign="center"
                                    >
                                        {option.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Box>
                <Grid container>
                    <Grid
                        item
                        md={3}
                        sx={{
                            display: "none",
                            [theme.breakpoints.up("md")]: {
                                display: "block",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                borderRight: `solid 1px ${theme.palette.divider
                                    }`,
                            }}
                        >
                            <List>
                                {menuOptions.map((option) => {
                                    return (
                                        <>
                                            <ListItemButton
                                                key={`menu-${option.label}`}
                                                sx={{
                                                    backgroundColor: option.status === messageGroupStatus ? "#C8C8C8" : ""
                                                }}
                                                onClick={() => setMessageGroupStatus(option.status)}
                                            >
                                                {option.label}
                                            </ListItemButton>
                                        </>
                                    );
                                })}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <List disablePadding>
                            {messageGroupList.map((messageGroup, i) => {
                                return (
                                    <>
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                sx={{
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    handleOpenMessage()
                                                    setActiveMessageGroup(messageGroup)
                                                }}
                                            >
                                                <ListItemAvatar>
                                                    <Badge
                                                        badgeContent={messageGroup.noReadCount}
                                                        color="secondary"
                                                    >
                                                        {
                                                            GetUserAdvatars(messageGroup)
                                                        }
                                                    </Badge>
                                                </ListItemAvatar>
                                                <ListItemText
                                                    primary={
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "space-between",
                                                                alignItems:
                                                                    "center",
                                                                gap: 1,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    overflowX:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                }}
                                                            >
                                                                {GetProductName(messageGroup)}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                color="text.secondary"
                                                            >
                                                                {messageGroup.lastMessages.createTime}
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    secondary={
                                                        <>
                                                            <Typography
                                                                variant="body2"
                                                                color="text.primary"
                                                                sx={{
                                                                    overflowX:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                }}
                                                            >
                                                                {GetUserName(messageGroup)}
                                                            </Typography>
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    overflowX:
                                                                        "hidden",
                                                                    textOverflow:
                                                                        "ellipsis",
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                    flexShrink: 1,
                                                                }}
                                                            >
                                                                {messageGroup.lastMessages.body}
                                                            </Typography>
                                                        </>
                                                    }
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                        {i !== messageGroupList.length - 1 && (
                                            <Divider />
                                        )}
                                    </>
                                );
                            })}
                        </List>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        pt: 2,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        borderTop: `solid 1px ${theme.palette.divider}`,
                    }}
                >
                    <Pagination
                        count={Math.ceil(total / pageSize)}
                        // siblingCount={0}
                        sx={{ px: { xs: 2, sm: 0 } }}
                    />
                </Box>
            </Paper>
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onClose={handleCloseMessage}
                onOpen={handleOpenMessage}
            >
                <Box sx={{ position: "relative", minWidth: "320px" }}>
                    <Box
                        sx={{
                            borderBottom: `solid 2px ${theme.palette.secondary.main
                                }`,
                            position: "sticky",
                            top: 0,
                            bgcolor: "#fff",
                            zIndex: 1,
                            boxShadow: theme.shadows[1],
                        }}
                    >
                        <Box
                            sx={{
                                height: "68px",
                                display: "flex",
                                alignItems: "center",
                                px: 2,
                                borderBottom: {
                                    xs: `solid 2px ${theme.palette.divider}`,
                                    md: "none",
                                },
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={handleCloseMessage}
                            >
                                <Cancel
                                    sx={{
                                        mr: 1,
                                    }}
                                />
                                {translate("CLOSE")}
                                <Typography
                                    component="span"
                                    variant="button"
                                    sx={{
                                        display: {
                                            xs: "none",
                                            md: "inline",
                                        },
                                    }}
                                >
                                    {translate("（Press [Esc] key）")}
                                </Typography>
                            </Button>
                        </Box>
                        <Accordion
                            disableGutters
                            sx={{
                                display: { xs: "block", md: "none" },
                                boxShadow: "none",
                                "&.MuiAccordion-root": {
                                    "&::before": {
                                        display: "none",
                                    },
                                },
                            }}
                        >
                            <AccordionSummary expandIcon={<ExpandMore />}>
                                {activeMessageGroup?.offer.title}
                            </AccordionSummary>
                            <AccordionDetails>
                                <OfferCard
                                    openDetailPageInAnotherTab
                                    sx={{
                                        p: 0,
                                        boxShadow: "none",
                                        "& .MuiCardContent-root": {
                                            p: 0,
                                        },
                                        "& .MuiCardActions-root": {
                                            p: 0,
                                            pt: 2,
                                        },
                                    }}
                                    data={activeMessageGroup?.offer}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Box>
                    <Box
                        sx={{
                            minHeight: {
                                xs: "calc(100vh - 118px)",
                                md: "calc(100vh - 70px)",
                            },
                            minWidth: "320px",
                            bgcolor: "#f7f7f7",
                        }}
                    >
                        <Box sx={{ py: 2 }}>
                            <Container
                                disableGutters
                                sx={{ display: "flex", gap: 2, px: 2 }}
                            >
                                <Paper
                                    elevation={2}
                                    sx={{
                                        flex: 1,
                                        p: 2,
                                        height: "fit-content",
                                    }}
                                >
                                    <Divider />
                                    {messageList.map((v, i) => {
                                        return (
                                            <>
                                                <Box
                                                    sx={{
                                                        p: 2,
                                                        bgcolor:
                                                            i % 2 === 0
                                                                ? "#f7f7f7"
                                                                : "transparent",
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            justifyContent:
                                                                "space-between",
                                                            alignItems:
                                                                "center",
                                                            gap: 1,
                                                            flexWrap: "wrap",
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                gap: 1,
                                                            }}
                                                        >
                                                            {GetMessageUserAvatar(v.ownerId)}
                                                            <Typography
                                                                sx={{
                                                                    wordBreak:
                                                                        "break-all",
                                                                }}
                                                            >
                                                                {GetMessageUserName(v.ownerId)}
                                                            </Typography>
                                                        </Box>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color:
                                                                    theme
                                                                        .palette
                                                                        .text
                                                                        .secondary,
                                                            }}
                                                        >
                                                            {v.createTime}
                                                        </Typography>
                                                    </Box>
                                                    <Typography
                                                        sx={{
                                                            mt: 2,
                                                        }}
                                                    >
                                                        {v.body}
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                            </>
                                        );
                                    })}
                                    <Box sx={{ mt: 2 }}>
                                        <TextField
                                            multiline
                                            minRows={4}
                                            fullWidth
                                            value={sendMessageText}
                                            onChange={(e) => setSendMessageText(e.target.value)}
                                            placeholder={translate(
                                                "Please enter the message to send to the recipient"
                                            )}
                                        />
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{ mt: 2 }}
                                            disabled={!sendMessageText}
                                            onClick={OnSendMessage}
                                        >
                                            <Send sx={{ mr: 1 }} />
                                            {translate("Send")}
                                        </Button>
                                        <div id="send" ref={sendRef}></div>
                                    </Box>
                                </Paper>
                                <Box
                                    sx={{
                                        width: "320px",
                                        display: {
                                            xs: "none",
                                            md: "block",
                                        },
                                        position: "relative",
                                    }}
                                >
                                    <OfferCard
                                        showThumbnail
                                        openDetailPageInAnotherTab
                                        sx={{
                                            position: "sticky",
                                            top: "86px",
                                        }}
                                    />
                                </Box>
                            </Container>
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </>
    );
}
