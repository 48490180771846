import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { ReactNode } from 'react';
import { useTranslateField } from '../../appContext/AppContext';
import { OfferListEnum } from '../../gql/graphql';

export interface RadioButtonGroupItem {
    label: string,
    value: unknown,
}

export default function RadioButtonsGroupComponent({
    row,
    data,
    selected,
    onChange,
}: {
    row?: boolean,
    data: RadioButtonGroupItem[],
    selected?: OfferListEnum,
    onChange?: (offerListType: OfferListEnum) => void
}) {

    const translate = useTranslateField()
    if (!row) {
        row = true
    }
    return (
        row ?
            <FormControl sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: "center",
            }}>
                <FormLabel sx={{
                    marginRight: 1,
                    marginY: .5,
                    fontWeight: "bold"
                }} id="demo-radio-buttons-group-label">{translate("Creator")}</FormLabel>
                {
                    data && data.length > 0 &&
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                    >
                        {
                            data.map(v => {
                                return <FormControlLabel
                                    checked={selected?.toString() === v.value}
                                    key={v.label}
                                    value={v.value}
                                    control={<Radio />}
                                    label={translate(v.label)}
                                    onChange={e => {
                                        onChange && onChange(v.value as OfferListEnum)
                                    }}
                                />
                            })
                        }
                    </RadioGroup>
                }
            </FormControl> :
            <FormControl>
                <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                >
                    {
                        data.map(v => {
                            return <FormControlLabel checked={selected?.toString() === v.value} key={v.label} value={v.value} control={<Radio />} label={translate(v.label)} />
                        })
                    }
                </RadioGroup>
            </FormControl>
    )
}