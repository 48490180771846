import {
    Delete,
    ExpandMore,
    Visibility,
    VisibilityOff,
} from "@mui/icons-material";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    css,
} from "@mui/material";
import {
    ChangeEvent,
    MouseEvent,
    SyntheticEvent,
    useEffect,
    useState,
} from "react";
import PostHashtagInput from "../components/PostHashtagInput";
import theme from "../theme";
import FileWrapper from "../utils/files/FileWrapper";
import Files, { CreateFileLink } from "../utils/files/Files";
import { useAccount, useCategories, useCountries, useCurrencies, usePostTypes, useProductTypes, useTranslateField } from "../appContext/AppContext";
import { CategoryRecord, CountryRecord, OfferInput, OfferInputEnum, OfferSearchDocument, OfferStatusInputEnum, PostOfferDocument, PostTypeRecord, ProductTypeRecord } from "../gql/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from 'react-router-dom';
import PageRecords from "../routes/PageRecords";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { record } from "zod";
import { Graphs } from "../appContext/Graphs";
import {Helmet} from "react-helmet-async";

interface FileInputInterface {
    id: string
    key: string
    file: File
    fileName: string
}

export default function PagesPost() {
    const countries = useCountries();
    const currencies = useCurrencies();
    const postTypes = usePostTypes();
    const productTypes = useProductTypes()
    const categories = useCategories()
    const [postOffer, { error }] = useMutation(PostOfferDocument)
    const [getOfferInfo,] = useLazyQuery(OfferSearchDocument)

    const navigate = useNavigate();
    const translate = useTranslateField();

    const [postType, setPostType] = useState<PostTypeRecord>(postTypes[0]);
    const [offerStatus, setOfferStatus] = useState<OfferStatusInputEnum>(OfferStatusInputEnum.Open)
    const statusTypes = [OfferStatusInputEnum.Open, OfferStatusInputEnum.Closed, OfferStatusInputEnum.OutOfStock]
    const [productType, setProductType] = useState<ProductTypeRecord>(productTypes[0]);
    const [category, setCategory] = useState<CategoryRecord | null>(null);
    const [productName, setProductName] = useState<string>("");
    const [description, setDescription] = useState<string>("");
    const [maker, setMaker] = useState<string>("");
    const [model, setModel] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const [size, setSize] = useState<string>("");
    const [country, setCountry] = useState<CountryRecord | null>(null);
    const [price, setPrice] = useState<string>("");
    const [currency, setCurrency] = useState<string | undefined>(undefined);
    const [hashtags, setHashtags] = useState<string[]>([]);
    const [checked, setChecked] = useState<number[]>([]);
    const [localFiles, setLocalFiles] = useState<FileInputInterface[]>([]);
    const [fileView, setfileView] = useState<string | undefined>(undefined);
    const [disable, setDisable] = useState<boolean>(true);
    const [no, setNo] = useState("")
    const { id } = useParams()
    const account = useAccount()
    const navigator = useNavigate()



    useAsyncEffect(async () => {
        console.log("check is login")
        if (!account || !account.IsLogin()) {
            navigator(PageRecords.SignIn.path)
        }
        if (id) {
            let response = await getOfferInfo({ variables: { offerSearchId: id } })
            if (response.data) {
                let record = response.data.OfferSearch
                setPostType(record.type)
                setProductType(record.productType)
                setCategory(record.category)
                setProductName(record.title)
                setDescription(record.description)
                setMaker(record.maker ? record.maker : "")
                setModel(record.model ? record.model : "")
                setYear(record.year ? record.year : "")
                setSize(record.size ? record.size : "")
                setCountry(record.country ? record.country : null)
                setPrice(record.price ? record.price + "" : "")
                setCurrency(record.current ? record.current : "")
                setHashtags(record.keywords ? record.keywords : [])
                setOfferStatus(record.status)
                setNo(record.no)

                let defaultFiles: Array<FileInputInterface> = record.files.map(file => {
                    return {
                        id: file.id,
                        key: file.key,
                        file: new File([""], file.fileName),
                        fileName: file.fileName,
                    }
                })

                setLocalFiles(defaultFiles)
            }
        } else {
            let newNo = "E"
            let typeStr = "M"

            //パーツ判断
            if (postType && postType.value === 20) {
                typeStr = "P"
            }

            let addLeadingZero = (value: number) => {
                return value < 10 ? `0${value}` : `${value}`;
            }

            let date = new Date()
            newNo += typeStr
            newNo += date.getFullYear().toString() + addLeadingZero(date.getMonth()) + addLeadingZero(date.getDate())
            // setNo(newNo)
        }
    }, [])

    const OnPostClick = async () => {
        if (!disable) {
            if (category) {
                console.log("country=", country)
                let data: OfferInput = {
                    type: postType?.value,
                    productType: productType?.value,
                    category: category.value,
                    title: productName,
                    description: description,
                    country: country?.value,
                    files: localFiles.map(v => { return { id: v.id, file: v.file } }),
                    keywords: hashtags,
                    maker: maker,
                    model: model,
                    current: currency,
                    price: parseFloat(price),
                    size: size,
                    year: year,
                    status: offerStatus,
                    no: no,
                }
                let offerInputType = OfferInputEnum.Create
                if (id) {
                    offerInputType = OfferInputEnum.Update
                }
                let ret = await postOffer({
                    variables: {
                        data: { ...data, id: id },
                        offerInputType: offerInputType
                    }
                })

                if (ret.data) {
                    if (id) {
                        navigate(PageRecords.Account.path + PageRecords.Account.children.EditPost.path)
                    } else {
                        navigate(PageRecords.SearchDetail.createSearchDetailLink(ret.data.PostOffer.id))
                    }
                }
            }
        }
    }

    const handlePostType = (newPostType: PostTypeRecord) => (e: SyntheticEvent) => {
        setPostType(newPostType);
    };

    const handleProductType = (newProductType: ProductTypeRecord) => (
        e: SyntheticEvent
    ) => {
        console.log("newProductType=", newProductType)
        console.log("productType=", productType)
        setProductType(newProductType);
    };

    const handleCategory = (
        e: SyntheticEvent,
        newCategory: CategoryRecord | null
    ) => {
        setCategory(newCategory);
    };

    const handleProductName = (e: ChangeEvent<HTMLInputElement>) => {
        setProductName(e.target.value);
    };

    const handleNo = (e: ChangeEvent<HTMLInputElement>) => {
        setNo(e.target.value);
    };

    const handleDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setDescription(e.target.value);
    };

    const handleMaker = (e: ChangeEvent<HTMLInputElement>) => {
        setMaker(e.target.value);
    };

    const handleModel = (e: ChangeEvent<HTMLInputElement>) => {
        setModel(e.target.value);
    };

    const handleYear = (e: ChangeEvent<HTMLInputElement>) => {
        setYear(e.target.value);
    };

    const handleSize = (e: ChangeEvent<HTMLInputElement>) => {
        setSize(e.target.value);
    };

    const handleCountry = (
        e: SyntheticEvent,
        newCountry: CountryRecord | null
    ) => {
        setCountry(newCountry);
    };

    const handlePrice = (e: ChangeEvent<HTMLInputElement>) => {
        setPrice(e.target.value);
    };

    const handleCurrency = (e: SyntheticEvent, newCurrency: string | undefined) => {
        setCurrency(newCurrency);
    };

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const onClickAddFiles = async () => {
        const newFiles = await Files.PromptMultipleFilesUploadDialogAsync_();
        const currentFiles: FileInputInterface[] = [...localFiles];
        for (let f of newFiles) {
            currentFiles.push({id:"", key: "", file: f, fileName: f.name });
        }
        const comparisonKey = "name";
        const uniqueFiles = currentFiles.reduce((acc: FileInputInterface[], file) => {
            if (
                !acc.some(
                    (f) => {
                        if (f.file && file.file) {
                            return f.file.name === file.file.name
                        }

                        return f.key === file.key
                    }
                )
            ) {
                acc.push(file);
            }
            return acc;
        }, []);
        setLocalFiles(uniqueFiles);
    };

    const onClickDeleteSelectedFiles = () => {
        const newLocalFiles = localFiles.filter(
            (_, i) => checked.indexOf(i) === -1
        );
        setLocalFiles(newLocalFiles);
        setChecked([]);
    };

    const onClickDeleteFile = (e: MouseEvent, removeIndex: number) => {
        e.stopPropagation();
        const newLocalFiles = localFiles.filter((_, i) => i !== removeIndex);
        let newChecked: number[] = [];
        checked.forEach((v) => {
            if (v === removeIndex) return;
            newChecked.push(v > removeIndex ? v - 1 : v);
        });
        setLocalFiles(newLocalFiles);
        setChecked(newChecked);
    };

    const onClickDeleteAllFile = () => {
        setLocalFiles([]);
        setChecked([]);
    };

    const onClickAllSelect = () => {
        if (allCheckedFlag) setChecked([]);
        else setChecked(localFiles.map((_, i) => i));
    };

    const onClickFolderUpload = async () => {
        const dirInput = document.createElement("input");
        dirInput.value = ""
        dirInput.type = "file";
        dirInput.webkitdirectory = true;
        dirInput.onchange = (e: any) => {
            if (e.target) {
                let fileInputList: FileInputInterface[] = [];
                for (let f of e.target.files) {
                    fileInputList.push({ id: "", key: "", file: f, fileName: f.name });
                }
                setLocalFiles(fileInputList);
            }
        };
        dirInput.click();
    };

    const handleFileView = (localFile: FileInputInterface) => async (
        e: MouseEvent
    ) => {
        e.stopPropagation();
        if (localFile.key) {
            setfileView(CreateFileLink(localFile.key));
            return
        }
        if (localFile.file) {
            let fileWrapper = FileWrapper.FromFile(localFile.file)
            const imageUrl = await fileWrapper.maybeGetThumbnailUrlAsync();
            setfileView(imageUrl);
        }
    };

    const handleCloseDialog = () => {
        setfileView(undefined);
    };

    const checkFileViewable = (localFile: FileInputInterface) => {
        let fileName = ""
        if (localFile.file) {
            fileName = localFile.file.name
        }
        const matchImageExtension = fileName.match(
            /\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/
        );
        const viewable = Boolean(matchImageExtension);
        return viewable;
    };

    const handleDisable = () => {
        const postTypeEntered = !!postType;
        const productTypeEntered = !!productType;
        const categoryEntered = !!category;
        const productNameEnterd = productName.length > 0;
        const descriptionEntered = description.length > 0;
        const requirementForPost =
            postTypeEntered &&
            productType &&
            categoryEntered &&
            productNameEnterd &&
            descriptionEntered;
        setDisable(!requirementForPost);
    };

    useEffect(() => {
        handleDisable();
    }, [postType, productType, category, productName, description]);

    const allCheckedFlag = checked.length === localFiles.length;

    const completeNumForTypeInfo = postType === undefined ? 0 : 1;
    const completeNumForBasicInfo =
        Number(!!productType) +
        Number(!!category) +
        Number(productName.length > 0) +
        Number(description.length > 0);
    // Number(no.length > 0);
    const completeNumForAdditionalInfo =
        Number(maker.length > 0) +
        Number(model.length > 0) +
        Number(year.length > 0) +
        Number(size.length > 0) +
        Number(country !== null) +
        Number(price.length > 0);

    const completeFlagForTypeInfo = postType;
    const completeFlagForBasicInfo = completeNumForBasicInfo === 5;
    const completeFlagForAdditionalInfo = completeNumForAdditionalInfo === 6;

    return (
        <>
        <Helmet>
            <title>{"Liberty | " + translate("Post")}</title>
            <meta name="description" content="You can post information about the products you want to buy and the products you want to sell. The information you post will appear in search results." lang='en'/>
            <meta name="description" content="買いたい商品や売りたい商品の情報を投稿できます。投稿した情報は検索結果に表示されるようになります。" lang='ja'/>
        </Helmet>
            <Container sx={{ pt: 4, pb: 8 }}>
                <Box>
                    <Typography variant="h4">{id ? translate("Edit") : translate("Post")}</Typography>
                    <Typography mt={1}>
                        {translate("Maximize the visibility of your post by providing detailed information on our posting page. By including more information, you increase the chances of your listing appearing in relevant search results. Capture the attention of users with comprehensive descriptions, high-quality images, and accurate specifications. Enhance your post's discoverability and attract interested customers by providing a complete and enticing listing.")}
                    </Typography>
                </Box>

                <Box mt={4}>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                                variant="subtitle2"
                                sx={{ width: "50%", flexShrink: 0 }}
                            >
                                {`${translate("Type")} *`}
                            </Typography>
                            <Typography
                                sx={{
                                    color: completeFlagForTypeInfo
                                        ? "success.main"
                                        : "text.secondary",
                                }}
                            >
                                {`${completeNumForTypeInfo} / 1 ${translate("completed")}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ py: 2 }}>
                            <FormControl>
                                <FormLabel required>{translate("Post type")}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    {postTypes.map(v => {
                                        return <FormControlLabel
                                            key={v.label}
                                            control={<Radio />}
                                            label={translate(v.label)}
                                            value={v.label}
                                            checked={postType?.value === v.value}
                                            onChange={handlePostType(v)}
                                        />
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                                variant="subtitle2"
                                sx={{ width: "50%", flexShrink: 0 }}
                            >
                                {`${translate("Status")} *`}
                            </Typography>
                            <Typography
                                sx={{
                                    color: completeFlagForTypeInfo
                                        ? "success.main"
                                        : "text.secondary",
                                }}
                            >
                                {`${completeNumForTypeInfo} / 1 ${translate("completed")}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ py: 2 }}>
                            <FormControl>
                                <FormLabel required>{translate("Status")}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    {statusTypes.map(v => {
                                        let text = v.replaceAll("_", " ")
                                        return <FormControlLabel
                                            key={v}
                                            control={<Radio />}
                                            label={translate(text)}
                                            value={v}
                                            checked={offerStatus === v}
                                            onChange={() => setOfferStatus(v)}
                                        />
                                    })}
                                </RadioGroup>
                            </FormControl>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                                variant="subtitle2"
                                sx={{ width: "50%", flexShrink: 0 }}
                            >
                                {`${translate("Basic information")} *`}
                            </Typography>
                            <Typography
                                sx={{
                                    color: completeFlagForBasicInfo
                                        ? "success.main"
                                        : "text.secondary",
                                }}
                            >
                                {`${completeNumForBasicInfo} / 4 ${translate("completed")}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ py: 2 }}>
                            <FormControl>
                                <FormLabel required>{translate("Product type")}</FormLabel>
                                <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                >
                                    {
                                        productTypes.map(v => {
                                            return <FormControlLabel
                                                key={v.label}
                                                control={<Radio />}
                                                label={translate(v.label)}
                                                value={v.value}
                                                checked={productType?.value === v.value}
                                                onChange={handleProductType(v)}
                                            />
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>
                            <Box mt={2}>
                                <Grid container spacing={4} sx={{ width: 1 }}>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={categories}
                                            value={category}
                                            getOptionLabel={(
                                                option: CategoryRecord
                                            ) => option.label}
                                            onChange={handleCategory}
                                            autoHighlight
                                            isOptionEqualToValue={(
                                                option,
                                                value
                                            ) => option.value === value.value}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label={translate("Category")}
                                                    fullWidth
                                                    variant="standard"
                                                    required
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={translate("Product name")}
                                            fullWidth
                                            variant="standard"
                                            value={productName}
                                            onChange={handleProductName}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={translate("Description")}
                                            helperText={translate("This field allows line breaks")}
                                            multiline
                                            maxRows={8}
                                            spellCheck={false}
                                            fullWidth
                                            variant="standard"
                                            value={description}
                                            onChange={handleDescription}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={translate("No")}
                                            fullWidth
                                            variant="standard"
                                            value={no}
                                            onChange={handleNo}
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                                variant="subtitle2"
                                sx={{ width: "50%", flexShrink: 0 }}
                            >
                                {translate("Additional information")}
                            </Typography>
                            <Typography
                                sx={{
                                    color: completeFlagForAdditionalInfo
                                        ? "success.main"
                                        : "text.secondary",
                                }}
                            >
                                {`${completeNumForAdditionalInfo} / 6 ${translate("completed")}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ py: 2 }}>
                            <Grid container spacing={4} sx={{ width: 1 }}>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={translate("Maker")}
                                        fullWidth
                                        variant="standard"
                                        value={maker}
                                        onChange={handleMaker}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={translate("Model")}
                                        fullWidth
                                        variant="standard"
                                        value={model}
                                        onChange={handleModel}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={translate("Year")}
                                        fullWidth
                                        variant="standard"
                                        value={year}
                                        onChange={handleYear}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        label={translate("Size")}
                                        fullWidth
                                        variant="standard"
                                        value={size}
                                        onChange={handleSize}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Autocomplete
                                        options={countries}
                                        getOptionLabel={(
                                            option: CountryRecord
                                        ) => option.label}
                                        onChange={handleCountry}
                                        autoHighlight
                                        isOptionEqualToValue={(option, value) =>
                                            option.label === value.label
                                        }
                                        renderOption={(props, option) => (
                                            <Box
                                                key={"country" + option.label}
                                                component="li"
                                                sx={{
                                                    "& > img": {
                                                        mr: 2,
                                                        flexShrink: 0,
                                                    },
                                                }}
                                                {...props}
                                            >
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={option.link}
                                                    srcSet={option.link2x}
                                                    alt=""
                                                />
                                                {option.label}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label={translate("Region")}
                                                variant="standard"
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={9}>
                                            <TextField
                                                label={translate("Price")}
                                                fullWidth
                                                variant="standard"
                                                value={price}
                                                onChange={handlePrice}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Autocomplete
                                                options={currencies}
                                                defaultValue={currency}
                                                disableClearable
                                                autoHighlight
                                                onChange={handleCurrency}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label={translate("Currency")}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                                variant="subtitle2"
                                sx={{ width: "50%", flexShrink: 0 }}
                            >
                                {translate("Hashtag")}
                            </Typography>
                            <Typography sx={{ color: "text.secondary" }}>
                                {`${hashtags.length} ${translate("hashtags")}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ py: 2 }}>
                            <PostHashtagInput
                                hashtags={hashtags}
                                setHashtags={setHashtags}
                            />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={false}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <Typography
                                variant="subtitle2"
                                sx={{ width: "50%", flexShrink: 0 }}
                            >
                                {translate("Attachment files")}
                            </Typography>
                            <Typography sx={{ color: "text.secondary" }}>
                                {`${localFiles.length} ${translate("files")}`}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ py: 2 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Button
                                        variant="outlined"
                                        onClick={onClickAddFiles}
                                        fullWidth
                                    >
                                        {translate("Select file")}
                                    </Button>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4} lg={3}>
                                    <Button
                                        variant="outlined"
                                        fullWidth
                                        onClick={onClickFolderUpload}
                                    >
                                        {translate("Bulk upload from folder")}
                                    </Button>
                                </Grid>
                            </Grid>
                            {localFiles.length > 0 && (
                                <>
                                    <Box
                                        sx={{
                                            ml: "auto",
                                            display: "flex",
                                            gap: 2,
                                            mt: 4,
                                            pt: 1,
                                            justifyContent: "flex-end",
                                            borderTop: `solid 1px ${theme.palette.divider
                                                }`,
                                        }}
                                    >
                                        {checked.length > 0 && (
                                            <>
                                                <Button
                                                    onClick={
                                                        onClickDeleteSelectedFiles
                                                    }
                                                >
                                                    {translate("Delete selected items")}
                                                </Button>
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                />
                                            </>
                                        )}
                                        <Button onClick={onClickDeleteAllFile}>
                                            {translate("Delete all items")}
                                        </Button>
                                    </Box>
                                    <List
                                        sx={{
                                            width: "100%",
                                            bgcolor: "background.paper",
                                        }}
                                    >
                                        <ListItem disablePadding>
                                            <ListItemButton
                                                role={undefined}
                                                dense
                                                onClick={onClickAllSelect}
                                            >
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        tabIndex={-1}
                                                        disableRipple
                                                        checked={allCheckedFlag}
                                                        indeterminate={
                                                            checked.length !==
                                                            localFiles.length &&
                                                            checked.length !== 0
                                                        }
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary="File name"
                                                    primaryTypographyProps={{
                                                        variant: "subtitle2",
                                                    }}
                                                />
                                            </ListItemButton>
                                        </ListItem>
                                        {localFiles.map((localFile, i) => {
                                            const labelId = `checkbox-list-label-${i}`;

                                            return (
                                                <ListItem
                                                    key={localFile.fileName}
                                                    disablePadding
                                                >
                                                    <ListItemButton
                                                        role={undefined}
                                                        onClick={handleToggle(
                                                            i
                                                        )}
                                                        dense
                                                    >
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                edge="start"
                                                                checked={
                                                                    checked.indexOf(
                                                                        i
                                                                    ) !== -1
                                                                }
                                                                tabIndex={-1}
                                                                disableRipple
                                                                inputProps={{
                                                                    "aria-labelledby": labelId,
                                                                }}
                                                            />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            id={labelId}
                                                            primary={
                                                                localFile.fileName
                                                            }
                                                        />
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                gap: 2,
                                                            }}
                                                        >
                                                            <IconButton
                                                                edge="end"
                                                                onClick={handleFileView(
                                                                    localFile
                                                                )}
                                                                disabled={
                                                                    !checkFileViewable(
                                                                        localFile
                                                                    )
                                                                }
                                                            >
                                                                {checkFileViewable(
                                                                    localFile
                                                                ) ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                            <IconButton
                                                                edge="end"
                                                                onClick={(e) =>
                                                                    onClickDeleteFile(
                                                                        e,
                                                                        i
                                                                    )
                                                                }
                                                            >
                                                                <Delete />
                                                            </IconButton>
                                                        </Box>
                                                    </ListItemButton>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                </>
                            )}
                        </AccordionDetails>
                    </Accordion>
                    {fileView && (
                        <Dialog open={true} onClose={handleCloseDialog}>
                            <DialogContent>
                                <img
                                    css={css`
                                        width: 100%;
                                    `}
                                    src={`${fileView}`}
                                />
                            </DialogContent>
                        </Dialog>
                    )}
                </Box>
                <Box mt={4}>
                    <Button
                        variant="contained"
                        size="large"
                        fullWidth
                        disabled={disable}
                        onClick={OnPostClick}
                    >
                        {id ? translate("Save") : translate("Post")}
                    </Button>
                </Box>
            </Container>
        </>
    );
}
