import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Copyright from "../components/Copyright";
import { useMutation } from "@apollo/client";
import { Graphs } from "../appContext/Graphs";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { AppContext, useTranslateField } from "../appContext/AppContext";
import { Loading } from "./../components/global/Loading";
import { Message } from "../components/global/Message";
import { SignUpOrInResponse, User } from "../gql/graphql";
import { useNavigate } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function PagesSignUp() {
    const [signUp, { data, loading, error }] = useMutation(Graphs.SignUp);
    const navigate = useNavigate();
    const translate = useTranslateField();
    const context = useContext(AppContext);

    useEffect(() => {
        if (data) {
            var user = data.SignUp.user as User;
            navigate(PageRecords.VerificationPage.path, {
                state: {
                    email: user.email,
                },
            });
        }
    }, [data]);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get("email"),
            password: data.get("password"),
        });
        try {
            if (!data.get("allowExtraEmails")) {
                Message.Error("Must check allowExtraEmails.");
                return;
            }
            //TODO:チェックすることが必要です
            signUp({
                variables: {
                    data: {
                        email: data.get("email"),
                        password: data.get("password"),
                        firstName:data.get("firstName"),
                        familyName:data.get("familyName"),
                    },
                },
            });
        } catch (error) {
            console.log("Err=", error);
        }
    };

    // パスワード表示/非表示用
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    // header's height is 70px (ResponsiveAppBar.tsx => Toolbar)
                    minHeight: "calc(100vh - 70px)",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {translate("Sign up")}
                </Typography>
                <Box
                    component="form"
                    noValidate
                    onSubmit={handleSubmit}
                    sx={{ mt: 3 }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                required
                                fullWidth
                                id="firstName"
                                label={translate("First Name")}
                                name="firstName"
                                autoComplete="first-name"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="family-name"
                                name="familyName"
                                required
                                fullWidth
                                id="familyName"
                                label={translate("Family name")}
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label={translate("Email")}
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label={translate("Password")}
                                id="password"
                                autoComplete="new-password"
                                type={showPassword ? "text" : "password"}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={
                                                    handleClickShowPassword
                                                }
                                                edge="end"
                                            >
                                                {showPassword ? (
                                                    <VisibilityOff />
                                                ) : (
                                                    <Visibility />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                name="allowExtraEmails"
                                control={
                                    <Checkbox
                                        value="allowExtraEmails"
                                        color="primary"
                                    />
                                }
                                label={translate("I want to receive marketing promotions and updates via email.")}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {translate("Sign Up")}
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link
                                onClick={() =>
                                    navigate(PageRecords.SignIn.path)
                                }
                                variant="body2"
                                sx={{ cursor: "pointer" }}
                            >
                                {translate("Already have an account? Sign in")}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
                <Copyright sx={{ mt: 4 }} />
            </Box>
        </Container>
    );
}
