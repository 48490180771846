import { useState, KeyboardEvent, ChangeEvent } from "react";
import {
    Box,
    Button,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

export default function PostHashtagInput({
    hashtags,
    setHashtags,
}: {
    hashtags: Array<string>;
    setHashtags: (key: Array<string>) => void;
}) {
    const [inputValue, setInputValue] = useState<string>("");
    const [typing, setTyping] = useState(false);

    const onKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter" && !typing) {
            let inputValueFixed = inputValue.trim();
            if (inputValueFixed.length === 0) {
                setInputValue("");
                return;
            }
            if (hashtags.indexOf(inputValueFixed) !== -1) {
                setInputValue("");
                return;
            }
            if (hashtags.length + 1 > 10) {
                setInputValue("");
                return;
            }
            if (inputValueFixed.length >= 51) {
                setInputValue("");
                return;
            }
            setHashtags([...hashtags, inputValueFixed]);
            setInputValue("");
        }
    };
    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;
        newValue = newValue.replace(/ |　|#|＃/, "");
        setInputValue(newValue);
    };
    return (
        <>
            <Box display="flex" alignItems="center" gap={2}>
                <TextField
                    variant="standard"
                    label="Hashtag"
                    helperText="Press enter to add keywords"
                    fullWidth
                    spellCheck="false"
                    value={inputValue}
                    onChange={onChange}
                    onCompositionStart={() => setTyping(true)}
                    onCompositionEnd={() => setTyping(false)}
                    onKeyDown={(e) => onKeyDown(e)}
                />
                <Button variant="outlined">Add</Button>
            </Box>
            <Grid container spacing={2} mt={1}>
                {hashtags.map((v, i) => {
                    return (
                        <>
                            <Grid
                                item
                                key={`hashtagsList${i}`}
                                contentEditable={false}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <Typography color="secondary.main">{`#${v}`}</Typography>
                                <IconButton
                                    onClick={(e) => {
                                        let newList = hashtags.filter((lv) => {
                                            return lv !== v;
                                        });
                                        setHashtags(newList);
                                    }}
                                >
                                    <Cancel sx={{ color: "secondary.light" }} />
                                </IconButton>
                            </Grid>
                        </>
                    );
                })}
                {hashtags.length > 0 && (
                    <Grid item>
                        <Button
                            variant="outlined"
                            onClick={() => setHashtags([])}
                        >
                            Reset
                        </Button>
                    </Grid>
                )}
            </Grid>
        </>
    );
}
