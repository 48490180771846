import { Box } from "@mui/material";
import { PdfImageList } from "./PdfImageList";
import { BodyContext, BodyContextInterface } from "./BodyContext";

export interface PdfFileInterface  {
    files: string[]
}

export type PdfBodyInterface = PdfFileInterface & BodyContextInterface

export function PdfBody({
    data,
}: {
    data: PdfFileInterface & BodyContextInterface,
}) {
    return (
        <div
            style={{
                height:"252mm",
                padding:"5mm"
            }}
        >
            <PdfImageList data={data.files}></PdfImageList>
            <BodyContext data={data}></BodyContext>
        </div>
    )
}