import { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import SearchBar from "../components/SearchBar";
import SearchResultTable from "../components/SearchResultTable";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
    BookMarkControllerEnum,
    BookMarkDocument,
    LimitInput,
    OfferFilterInput,
    OfferListDocument,
    OfferListEnum,
    OfferResponse,
    OfferSearchDocument,
} from "../gql/graphql";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { useTranslateField } from "../appContext/AppContext";
import { Message } from "../components/global/Message";
import { GetLocalData, RemoveLocalData, SetLocalData } from "../utils/localStroage";
import { useLocation, useParams } from "react-router-dom";
import { Loading } from "../components/global/Loading";
import { Helmet } from "react-helmet-async";

export default function PagesSearch() {
    const [offerListCall] = useLazyQuery(OfferListDocument);
    const [offers, setOffers] = useState<Array<OfferResponse>>([]);
    const [current, setCurrent] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const pageSizeList = [10, 30, 50];
    const [bookMark] = useMutation(BookMarkDocument);
    // let oldFilter = GetLocalData("SearchFilter")
    // let ret: OfferFilterInput | undefined = undefined
    // if (oldFilter) {
    //     ret = JSON.parse(oldFilter)
    // }
    // console.log("old filter: ", oldFilter)
    const [filter, setFilter] = useState<OfferFilterInput | undefined>(undefined);
    const translate = useTranslateField();
    const { state } = useLocation();
    console.log("location state=", state);

    useEffect(() => {
        let oldFilter = GetLocalData("SearchFilter");
        let ret: OfferFilterInput | undefined = undefined;
        if (oldFilter) {
            ret = JSON.parse(oldFilter);
        }
        console.log("old filter: ", oldFilter);
        console.log("filter: ", filter);
        console.log("ret: ", ret);
        SetFilter(ret);
        return () => {
            RemoveLocalData("SearchFilter");
        };
    }, []);

    useAsyncEffect(async () => {
        console.log("filter=", filter);
        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        console.log("filter search=", filter);
        let { data } = await offerListCall({
            variables: { limit: limitInput, searchType: OfferListEnum.All, filter: filter },
            fetchPolicy: "network-only",
        });
        if (data) {
            setOffers(data.OfferList.offers);
            setTotal(data.OfferList.total);
        }
        window.scrollTo(0, 0);
    }, [current, pageSize, filter]);

    const SetFilter = async (filterInfo: OfferFilterInput | undefined) => {
        setFilter(filterInfo);
        if (filterInfo) {
            SetLocalData("SearchFilter", JSON.stringify(filterInfo));
        }

        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        console.log("filterInfo=", filterInfo);
        let { data } = await offerListCall({
            variables: { limit: limitInput, searchType: OfferListEnum.All, filter: filterInfo },
            fetchPolicy: "network-only",
        });
        if (data) {
            setOffers(data.OfferList.offers);
            setTotal(data.OfferList.total);
        }
    };

    const OnBookMarkChange = async (ids: string[], controller: BookMarkControllerEnum) => {
        let response = await bookMark({
            variables: {
                offerIds: ids,
                controller: controller,
            },
        });
        if (response.data) {
            if (response.data.BookMark.valueOf()) {
                let tmpOffers = offers.map((v) => {
                    let newIsBookMarked = v.isBookmarked;
                    if (ids.includes(v.id)) {
                        if (controller === BookMarkControllerEnum.Add) {
                            newIsBookMarked = true;
                        } else if (controller === BookMarkControllerEnum.Remove) {
                            newIsBookMarked = false;
                        }
                    }
                    return {
                        ...v,
                        isBookmarked: newIsBookMarked,
                    };
                });
                setOffers(tmpOffers);
                if (controller === BookMarkControllerEnum.Add) {
                    Message.Success("Add offer to bookmark successed");
                } else {
                    Message.Success("Remove offer from bookmark successed");
                }
            }
        } else {
            if (controller === BookMarkControllerEnum.Add) {
                Message.Success("Add offer to bookmark failed");
            } else {
                Message.Success("Remove offer from bookmark failed");
            }
        }
    };

    const OnFilterApply = async (filter: OfferFilterInput) => {
        SetFilter(filter);
    };

    return (
        <>
            <Helmet>
                <title>{"Liberty | " + translate("Search")}</title>
            </Helmet>
            <Container>
                <Box py={4}>
                    <Typography variant="h4">{translate("Search")}</Typography>
                    <Typography mt={1}>
                        {translate(
                            "Please enter a keyword in the search box. You can also take advantage of advanced search features such as hashtag search and filter search. By entering a hashtag (e.g., #ElasticLLC) or applying filters (click the filter icon), you can narrow down the search results and find exactly what you're looking for accurately."
                        )}
                    </Typography>
                    <Box py={4} display="flex">
                        <SearchBar sx={{ flexGrow: 1 }} filter={filter} onFilterApply={OnFilterApply} />
                    </Box>
                    <SearchResultTable
                        data={offers}
                        pageSize={pageSize}
                        total={total}
                        current={current}
                        setPageSize={(size) => {
                            setPageSize(size);
                            setCurrent(1);
                        }}
                        setCurrent={setCurrent}
                        pageSizeList={pageSizeList}
                        onBookMarkChange={OnBookMarkChange}
                    />
                </Box>
            </Container>
        </>
    );
}
