import {
    Autocomplete,
    Box,
    Button,
    Container,
    Grid,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import { useConcactSubjects, useTranslateField } from "../appContext/AppContext";
import {
    ContactInput,
    ContactRecord,
    PostContactDocument,
} from "../gql/graphql";
import { useMutation } from "@apollo/client";
import { Message } from "../components/global/Message";

export default function PagesContact() {
    const subjectList = useConcactSubjects();

    const [email, setEmail] = useState<string>("");
    const [subject, setSubject] = useState<ContactRecord | null>(null);
    const [body, setBody] = useState<string>("");
    const [disable, setDisable] = useState<boolean>(true);
    const [postContant] = useMutation(PostContactDocument);

    const handleEmail = (e: ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
    };
    const handleSubject = (
        event: SyntheticEvent,
        newSubject: ContactRecord | null
    ) => {
        setSubject(newSubject);
    };
    const handleBody = (e: ChangeEvent<HTMLInputElement>) => {
        setBody(e.target.value);
    };

    useEffect(() => {
        const disableFlag = !email || !subject || !body;
        setDisable(disableFlag);
    }, [email, subject, body]);

    const translate = useTranslateField();

    const onSendContact = async () => {
        if (!disable && subject) {
            let data: ContactInput = {
                description: body,
                email: email,
                subject: subject.value,
            };
            let response = await postContant({
                variables: {
                    data,
                },
            });

            if (response.data !== null) {
                setEmail("")
                setBody("")
                Message.Success(translate("Message sent successfully."));
            }
        }
    };

    return (
        <>
            <Container sx={{ pt: 4, pb: 8 }}>
                <Box>
                    <Typography variant="h4">{translate("Contact")}</Typography>
                    <Typography mt={1}>
                        {translate("Contact our staff through this page. Simply enter your email address, select a subject, and write your message in the provided text field to submit your inquiry. Our team is ready to assist you and will respond promptly to your message. Please provide as much detail as possible to help us better understand and address your needs.")}
                    </Typography>
                </Box>
                <Box mt={4}>
                    <Paper sx={{ p: 4 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <TextField
                                    label={translate("Email")}
                                    fullWidth
                                    variant="standard"
                                    value={email}
                                    onChange={handleEmail}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={subjectList}
                                    getOptionLabel={(option: ContactRecord) =>
                                        translate(option.label)
                                    }
                                    onChange={handleSubject}
                                    autoHighlight
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={translate("Subject")}
                                            variant="standard"
                                            helperText={translate("Choose from options")}
                                            required
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label={translate("Body")}
                                    helperText={translate("This field allows line breaks")}
                                    multiline
                                    maxRows={8}
                                    spellCheck={false}
                                    fullWidth
                                    variant="standard"
                                    value={body}
                                    onChange={handleBody}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Box mt={4}>
                        <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled={disable}
                            onClick={onSendContact}
                        >
                            {translate("Send")}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </>
    );
}
