import { DocumentNode, gql } from "@apollo/client";
import { graphql } from "../gql";

export type GraphsType = typeof Graphs;

export const FileUploadList = ["Upload", "PostOffer", "UpdateAccountProfile"];

//Graphqlリクエストため作ったリスト
export const Graphs = {
    //新規
    SignUp: gql`
        mutation SignUp($data: SignUpInput!) {
            SignUp(data: $data) {
                session
                user {
                    id
                    email
                    mobile
                    familyName
                    firstName
                    status
                    sendMail
                    remarks
                    avatar
                    group
                    createTime
                }
            }
        }
    `,
    SetAccountGroup: gql`
        mutation SetAccountGroup($accountId: String!, $group: [GroupEnum!]!) {
            SetAccountGroup(accountId: $accountId, group: $group)
        }
    `,
    //登録
    SignIn: gql`
        mutation SignIn($data: SignInInput!) {
            SignIn(data: $data) {
                session
                user {
                    id
                    email
                    mobile
                    avatar
                    firstName
                    familyName
                    status
                    remarks
                    sendMail
                    group
                    createTime
                }
            }
        }
    `,
    //session認証
    Verification: gql`
        query Verification($session: String!) {
            Verification(session: $session) {
                id
                email
                mobile
                firstName
                familyName
                status
                remarks
                group
                createTime
            }
        }
    `,
    //パスワード更新
    UpdatePassword: gql`
        mutation UpdatePassword($data: UpdatePasswordInput!) {
            UpdatePassword(data: $data)
        }
    `,
    //ファイルアップロード
    Upload: gql`
        mutation Upload($files: [Upload!]!) {
            Upload(files: $files) {
                fileName
                key
                id
            }
        }
    `,
    //基礎情報
    BaseInfo: gql`
        query BaseInfo {
            BaseInfo {
                countries {
                    label
                    value
                    link
                    link2x
                }
                languages {
                    mapData
                }
                currencies
                categories {
                    label
                    value
                }
                postTypes {
                    label
                    value
                }
                productTypes {
                    label
                    value
                }
                contactSubjects {
                    label
                    value
                }
                accessCount
            }
        }
    `,
    //新規オファー
    PostOffer: gql`
        mutation PostOffer(
            $data: OfferInput!
            $offerInputType: OfferInputEnum!
        ) {
            PostOffer(data: $data, offerInputType: $offerInputType) {
                id
                type {
                    label
                    value
                }
                productType {
                    label
                    value
                }
                category {
                    label
                    value
                }
                title
                description
                maker
                model
                year
                size
                country {
                    label
                    value
                }
                price
                current
                keywords
                status
                owner {
                    id
                    email
                    mobile
                    firstName
                    familyName
                    status
                    sendMail
                    remarks
                    avatar
                    group
                    createTime
                }
                files {
                    id
                    key
                    fileName
                }
                deleteUserId
                createTime
            }
        }
    `,
    //オファーリスト
    OfferList: gql`
        query OfferList(
            $limit: LimitInput!
            $searchType: OfferListEnum!
            $filter: OfferFilterInput
        ) {
            OfferList(limit: $limit, searchType: $searchType, filter: $filter) {
                offers {
                    id
                    type {
                        label
                        value
                    }
                    productType {
                        label
                        value
                    }
                    category {
                        label
                        value
                    }
                    no
                    title
                    description
                    maker
                    model
                    year
                    size
                    country {
                        label
                        value
                        link
                        link2x
                    }
                    price
                    current
                    keywords
                    status
                    owner {
                        id
                        email
                        mobile
                        firstName
                        familyName
                        status
                        sendMail
                        remarks
                        avatar
                        group
                        createTime
                    }
                    files {
                        id
                        key
                        fileName
                    }
                    deleteUserId
                    createTime
                    user {
                        id
                        email
                        mobile
                        firstName
                        familyName
                        status
                        avatar
                        remarks
                        sendMail
                        group
                        createTime
                    }
                    isBookmarked
                }
                total
            }
        }
    `,
    //問い合わせ送信
    PostContact: gql`
        mutation PostContact($data: ContactInput!) {
            PostContact(data: $data) {
                ID
                email
                subject {
                    label
                    value
                }
                description
            }
        }
    `,
    //offer検索
    OfferSearch: gql`
        query OfferSearch($offerSearchId: String!, $login: Boolean = false) {
            OfferSearch(id: $offerSearchId) {
                id
                type {
                    label
                    value
                }
                productType {
                    label
                    value
                }
                category {
                    label
                    value
                }
                title
                description
                maker
                model
                year
                size
                country {
                    label
                    value
                    link
                    link2x
                }
                price
                no
                current
                keywords
                status
                owner {
                    id
                    email
                    mobile
                    firstName
                    familyName
                    status
                    sendMail
                    remarks
                    avatar
                    group
                    createTime
                }
                files {
                    id
                    key
                    fileName
                }
                deleteUserId
                createTime
                user @include(if: $login) {
                    id
                    email
                    mobile
                    firstName
                    familyName
                    status
                    remarks
                    sendMail
                    group
                    createTime
                }
                isBookmarked
            }
        }
    `,
    // アカウントの情報を更新する
    UpdateAccountProfile: gql`
        mutation UpdateAccountProfile($data: AccountProfileInput) {
            UpdateAccountProfile(data: $data) {
                id
                email
                mobile
                firstName
                familyName
                status
                remarks
                sendMail
                avatar
                group
                createTime
            }
        }
    `,
    // ブックマーク全データーを取る
    BookMarkList: gql`
        query BookMarkList($limit: LimitInput!, $filter: OfferFilterInput) {
            BookMarkList(limit: $limit, filter: $filter) {
                offers {
                    id
                    type {
                        label
                        value
                    }
                    productType {
                        label
                        value
                    }
                    category {
                        label
                        value
                    }
                    title
                    description
                    maker
                    model
                    year
                    size
                    country {
                        label
                        value
                        link
                        link2x
                    }
                    no
                    price
                    current
                    keywords
                    status
                    owner {
                        id
                        email
                        mobile
                        firstName
                        familyName
                        status
                        sendMail
                        remarks
                        avatar
                        group
                        createTime
                    }
                    files {
                        id
                        key
                        fileName
                    }
                    deleteUserId
                    createTime
                    user {
                        id
                        email
                        mobile
                        firstName
                        familyName
                        status
                        remarks
                        avatar
                        sendMail
                        group
                        createTime
                    }
                    isBookmarked
                }
                total
            }
        }
    `,
    //ブックマークを設定
    BookMark: gql`
        mutation BookMark(
            $controller: BookMarkControllerEnum!
            $offerIds: [String!]
        ) {
            BookMark(controller: $controller, offerIds: $offerIds)
        }
    `,
    // offerを削除する
    DeleteOffer: gql`
        mutation DeleteOffer($offerIds: [String!]) {
            DeleteOffer(offerIds: $offerIds)
        }
    `,
    // 定期的なメールを送信する
    SettingSendMail: gql`
        mutation SettingSendMail($sendMailType: SendMailEnum!) {
            SettingSendMail(sendMailType: $sendMailType) {
                id
                email
                mobile
                firstName
                familyName
                status
                sendMail
                remarks
                group
                createTime
            }
        }
    `,
    // パスワードリセット申請メール送信
    ResetPasswordMail: gql`
        mutation ResetPasswordMail($email: String!) {
            ResetPasswordMail(email: $email)
        }
    `,
    //　パスワードリセット
    ResetPassword: gql`
        mutation ResetPassword(
            $key: String!
            $password: String!
            $confirmPassword: String!
        ) {
            ResetPassword(
                key: $key
                password: $password
                confirmPassword: $confirmPassword
            )
        }
    `,
    //　メッセージを送信
    PostMessage: gql`
        mutation PostMessage($data: PostMessageInput!) {
            PostMessage(data: $data)
        }
    `,
    // メッセージグループ情報を取る
    MessageGroupList: gql`
        query MessageGroupList(
            $limit: LimitInput!
            $filter: MessageGroupFilterInput
        ) {
            MessageGroupList(limit: $limit, filter: $filter) {
                total
                messageGroups {
                    id
                    groupName
                    ownerId
                    lastMessages {
                        ownerId
                        groupId
                        body
                        type
                        isReadAccountIds
                        createTime
                    }
                    noReadCount
                    userList {
                        id
                        email
                        mobile
                        firstName
                        familyName
                        status
                        sendMail
                        remarks
                        avatar
                        group
                        createTime
                    }
                    offer {
                        id
                        type {
                            label
                            value
                        }
                        productType {
                            label
                            value
                        }
                        category {
                            label
                            value
                        }
                        title
                        description
                        maker
                        model
                        year
                        size
                        country {
                            label
                            value
                            link
                            link2x
                        }
                        price
                        current
                        keywords
                        status
                        owner {
                            id
                            email
                            mobile
                            firstName
                            familyName
                            status
                            sendMail
                            remarks
                            avatar
                            group
                            createTime
                        }
                        no
                        files {
                            id
                            key
                            fileName
                        }
                        deleteUserId
                        createTime
                        user {
                            id
                            email
                            mobile
                            firstName
                            familyName
                            status
                            sendMail
                            remarks
                            avatar
                            group
                            createTime
                        }
                        isBookmarked
                    }
                }
            }
        }
    `,
    //グループの全部リストを取る
    MessageList: gql`
        query MessageList($groupId: String!) {
            MessageList(groupId: $groupId) {
                ownerId
                groupId
                body
                type
                isReadAccountIds
                createTime
            }
        }
    `,
    ConfirmPassword: gql`
        mutation ConfirmPassword($password: String!) {
            ConfirmPassword(password: $password)
        }
    `,
    CategoryList: gql`
        query CategoryList($parentId: Int) {
            CategoryList(parentId: $parentId) {
                label
                value
            }
        }
    `,
    PostOfferAnalysis: gql`
        query PostOfferAnalysis($data: PostOfferAnalysisInput!) {
            PostOfferAnalysis(data: $data) {
                labels
                data
            }
        }
    `,
    AccessDataList: gql`
        query AccessDataList($data: AccessDataInput!) {
            AccessDataList(data: $data) {
                labels
                data
            }
        }
    `,
    RegionalDistribution: gql`
        query RegionalDistribution {
            RegionalDistribution {
                labels
                data
            }
        }
    `,
    MailManagerList: gql`
        query MailManagerList(
            $limit: LimitInput!
            $filter: MailManagerFilterInput
        ) {
            MailManagerList(limit: $limit, filter: $filter) {
                list {
                    date
                    type
                    emails
                    offer {
                        id
                        title
                    }
                    user {
                        id
                        email
                    }
                }
                total
            }
        }
    `,
    ChangeOffersStatus: gql`
        mutation ChangeOffersStatus(
            $offerIds: [String!]!
            $status: OfferStatusInputEnum!
        ) {
            ChangeOffersStatus(offerIds: $offerIds, status: $status)
        }
    `,
    SendPromotionalEmail: gql`
        mutation SendPromotionalEmail($data: SendPromotionalEmailInput) {
            SendPromotionalEmail(data: $data)
        }
    `,
    AccountList: gql`
        query AccountList($limit: LimitInput!, $filter: AccountListFilter) {
            AccountList(limit: $limit, filter: $filter) {
                list {
                    id
                    email
                    mobile
                    familyName
                    firstName
                    status
                    sendMail
                    remarks
                    avatar
                    group
                    createTime
                }
                total
            }
        }
    `,
    LogOut: gql`
        mutation LogOut($session: String!) {
            LogOut(session: $session)
        }
    `,
};
