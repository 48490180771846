import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, TablePagination, Typography } from "@mui/material";
import { useTranslateField } from "../appContext/AppContext";
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AccountListDocument, AccountListFilter, AccountStatusEnum, GroupEnum, LimitInput, SetAccountGroupDocument, User } from "../gql/graphql";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { AccountManagerSearchComponent } from "../components/accountManager/AccountManagerSearchComponent"
import TransferList, { TransferListItem } from "../components/TranslferListComponent";
import { Message } from "../components/global/Message";

function createData(
    email: string,
    status: string,
    group: string[],
    carbs: number,
    protein: number,
) {
    return { email, status, group, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', "test1", ["group1"], 24, 4.0),
    createData('Ice cream sandwich', "test2", ["group2"], 37, 4.3),
    createData('Eclair', "test3", ["group3"], 24, 6.0),
    createData('Cupcake', "test4", ["group4"], 67, 4.3),
    createData('Gingerbread', "test5", ["group5"], 49, 3.9),
];


export function PageAccountManager() {
    const translate = useTranslateField()
    const [accountManagerListCall,] = useLazyQuery(AccountListDocument)
    const [accountList, setAccountList] = useState<Array<User>>([])
    const [filter, setFilter] = useState<AccountListFilter>()
    const [current, setCurrent] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const pageSizeList = [10, 30, 50];
    const [open, setOpen] = useState(false);
    const [unSelected, setUnSelected] = useState<Array<TransferListItem>>([])
    const [setAccountGroupCall,] = useMutation(SetAccountGroupDocument)
    const [accountId, setAccoundID] = useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setUnSelected(Object.values(GroupEnum).map(v => {
            return {
                label: v,
                value: v,
            }
        }))
    }, [])

    const getStatusFunc = useCallback((status: number | null | undefined) => {
        console.log("status=", status)
        switch (status) {
            case 0:
                return "UNVERIFIABLE"
            case 10:
                return "ABLE"
            case 90:
                return "DISABLE"
            default:
                return "-"
        }
    }, [])

    useAsyncEffect(async () => {
        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        let accountRecords = await accountManagerListCall({
            variables: {
                limit: limitInput,
            },
            fetchPolicy: "network-only"
        })

        console.log("accountRecords=", accountRecords)
        if (accountRecords.data) {
            setAccountList(accountRecords.data.AccountList.list)
            setTotal(accountRecords.data.AccountList.total)
        }
    }, [pageSize, current])

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        console.log("newPage=", newPage)
        setCurrent(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        console.log("event.target.value=", event.target.value)
        console.log("parseInt=", parseInt(event.target.value, 10))
        setPageSize(parseInt(event.target.value, 10));
        setCurrent(0);
    };

    const OnSearch = async (email: string, status: AccountStatusEnum | "", group: GroupEnum | "", startDate: string, endDate: string) => {
        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        let filter: AccountListFilter = {
            email: email !== "" ? email.trim() : undefined,
            status: status !== "" ? status : undefined,
            group: group !== "" ? [group] : undefined,
            startDate: startDate !== "" ? startDate : undefined,
            endDate: endDate !== "" ? endDate : undefined,
        }

        setFilter(filter)
        let response = await accountManagerListCall({
            variables: {
                limit: limitInput,
                filter: filter
            },
            fetchPolicy: "network-only",
        })

        if (response.data) {
            console.log("response.data= ", response.data)
            setAccountList(response.data.AccountList.list)
            setTotal(response.data.AccountList.total)
            setCurrent(0)
        }
    }

    const OnSaveGroup = async (groups: TransferListItem[]) => {
        console.log("groups", groups)
        let response = await setAccountGroupCall({
            variables: {
                group: groups.map(v => v.value as GroupEnum),
                accountId: accountId,
            }
        })

        if (response.data && response.data.SetAccountGroup.valueOf()) {
            Message.Success("Successfully")
            let limitInput: LimitInput = {
                current: current,
                pageSize: pageSize,
            };

            let accountRecords = await accountManagerListCall({
                variables: {
                    limit: limitInput,
                    filter: filter,
                },
                fetchPolicy: "network-only"
            })

            console.log("accountRecords=", accountRecords)
            console.log("filter=", filter)
            if (accountRecords.data) {
                setAccountList(accountRecords.data.AccountList.list)
                setTotal(accountRecords.data.AccountList.total)
            }
        } else {
            Message.Error("Failed")
        }
    }

    return (
        <Box>
            <Typography variant="h4">{translate("Account manager")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph mt={2}>
                {/* {translate("You can view bookmarked posts on this page.")} */}
            </Typography>
            <AccountManagerSearchComponent onSearch={OnSearch}></AccountManagerSearchComponent>
            <TableContainer component={Paper} >
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ width: "10rem" }} align="center">{translate("email")}</TableCell>
                            <TableCell sx={{ width: "10rem" }} align="center">{translate("Status")}</TableCell>
                            <TableCell sx={{ width: "10rem" }} align="center">{translate("group")}</TableCell>
                            <TableCell sx={{ width: "10rem" }} align="center">{translate("createTime")}</TableCell>
                            <TableCell sx={{ width: "15rem" }} align="center">{translate("Operation")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accountList.map((row) => (
                            <TableRow
                                key={row.email}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="center" component="th" scope="row">
                                    {row.email}
                                </TableCell>
                                <TableCell align="center">{translate(getStatusFunc(row.status))}</TableCell>
                                <TableCell align="center">{row.group.map((v, k) => {
                                    if (k !== row.group.length - 1) {
                                        return v + "、"
                                    } else {
                                        return v
                                    }
                                })}</TableCell>
                                <TableCell align="center">{row.createTime}</TableCell>
                                <TableCell align="center">
                                    <Button size="small" sx={{ minWidth: "2rem" }} variant="outlined" onClick={() => {
                                        setAccoundID(row.id)
                                        handleClickOpen()
                                    }}>
                                        {translate("edit")}
                                    </Button>
                                    <Dialog
                                        open={open}
                                        onClose={handleClose}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {translate("Account group setting")}
                                        </DialogTitle>
                                        <DialogContent>
                                            <TransferList unSelected={unSelected.filter(v => {
                                                return !row.group.includes(v.value as GroupEnum)
                                            })}
                                                selected={Object.values(row.group).map(v => {
                                                    return {
                                                        label: v,
                                                        value: v,
                                                    }
                                                }

                                                )}
                                                onClose={handleClose}
                                                onSave={OnSaveGroup}
                                            ></TransferList>
                                        </DialogContent>
                                    </Dialog>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                labelRowsPerPage={translate("Rows per page")}
                rowsPerPageOptions={pageSizeList}
                component="div"
                count={total}
                rowsPerPage={pageSize}
                page={current}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Box>
    )
}