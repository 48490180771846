import {
    ApolloClient,
    ApolloLink,
    DefaultContext,
    HttpLink,
    InMemoryCache,
    from,
} from "@apollo/client";
import { Config } from "../config/config";
import { Loading } from "../components/global/Loading";
import { onError } from "@apollo/client/link/error";
import { Message } from "../components/global/Message";
import { Errors } from "../errors/errors";
import { setContext } from "@apollo/client/link/context";
import { GetLocalData, RemoveLocalData } from "../utils/localStroage";
import { createUploadLink } from "apollo-upload-client";
import { FileUploadList } from "./Graphs";
import { LoginSharp } from "@mui/icons-material";

function getLocalIPAddress(): Promise<string> {
    return new Promise((resolve, reject) => {
        const pc = new RTCPeerConnection({ iceServers: [{ urls: "stun:stun.l.google.com:19302" }] });
        pc.createDataChannel(""); // 通过创建数据通道来触发ICE候选的收集
        pc.onicecandidate = (event) => {
            if (event.candidate) {
                const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
                let ipArray = ipRegex.exec(event.candidate.candidate)
                if (ipArray && ipArray.length > 0) {
                    console.log("event: ", event)
                    console.log("ipArray: " + ipArray)
                    const ipAddress = ipArray[1];
                    resolve(ipAddress);
                    pc.close();
                    console.log("ipAddress:", ipAddress)
                }
            }
        };
        pc.createOffer().then((offer) => pc.setLocalDescription(offer));
    });
}

const middlewareLink = new ApolloLink((operation, forward) => {
    // リクエストが開始されたときの処理
    // TODO:Token交換機能を追加
    // Loading.Start();
    console.log("operation=", operation);
    console.log(operation.operationName + JSON.stringify(operation.variables));

    // 次のmiddlewareを呼び出す
    // 今ないです
    return forward(operation).map((response) => {
        // レスポンスを受け取ったときの処理
        // TODO:リスポンス情報を処理
        console.log("response=", response);
        // Loading.Stop();
        return response;
    });
});

const authLink = setContext(async (_, { headers }) => {
    let accessInfo = {
        ip: await getLocalIPAddress(),
        url: window.location.href
    }

    let accessInfoJson = JSON.stringify(accessInfo)
    let baseStr = ""
    try {
        baseStr = btoa(accessInfoJson)
        console.log("baseStr=", baseStr)
        // console.log("accessInfoJson=", accessInfoJson)
    } catch (e) {
        console.log("err=", e)
    }

    // ヘッダーに追加するデータを定義
    const customHeaders = {
        // ここに追加したいヘッダー情報を記述
        Authorization: GetLocalData("session"),
        Address: baseStr
    };

    // ヘッダーにカスタムデータを追加
    const updatedHeaders = {
        ...headers,
        ...customHeaders,
    };

    return {
        headers: updatedHeaders,
    };
});

const httpLink = new HttpLink({
    uri: Config.url,
});

const errorLink = onError(
    ({ networkError, graphQLErrors, response, operation, forward }) => {
        console.log("networError=", networkError);
        console.log("graphqlerror=", graphQLErrors);
        if (graphQLErrors) {
            if (graphQLErrors[0].extensions) {
                Message.Error(Errors.GRAPHQL_VALIDATION_FAILED);
                return
            }
            if (graphQLErrors[0].message) {
                if (graphQLErrors[0].message.includes("session has expired")) {
                    RemoveLocalData("session");
                }
                Message.Error(graphQLErrors[0].message);
                return
            }
        }
        if (networkError) {
            Message.Error(Errors.NET_WORK_ERROR);
            return
        }
        return forward(operation);
    }
);

export const uploadLink = createUploadLink({
    uri: Config.url,
});
const link = ApolloLink.split(
    //ファイルアップロードと普通なリクエスト判断
    (operation) => {
        console.log("checkUploadList=", FileUploadList.includes(operation.operationName))
        console.log("FileUploadList=", FileUploadList)
        console.log("operation.operationName=", operation.operationName)
        return FileUploadList.includes(operation.operationName);
    },
    uploadLink as any,
    httpLink
);

export const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(from([errorLink, middlewareLink, link])),
});
