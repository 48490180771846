import Footer from "./components/Footer";
import Header from "./components/Header";
import Router from "./routes/Router";
import { AppProvider } from "./appContext/AppContext";
import { RequiresLoginCheck } from "./routes/RequiresLoginCheck";
import Background from "./components/global/Background";
import ScrollToTop from "./components/global/ScrollToTop";
import PageWrapper from "./components/global/PageWrapper";
import { ApolloProvider } from "@apollo/client";
import { client } from "./appContext/Client";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function App() {
    return (
        <ApolloProvider client={client}>
            <HelmetProvider>
                <AppProvider>
                    <ScrollToTop />
                    <Background>
                        <Header />
                        <RequiresLoginCheck />
                        <PageWrapper>
                            <Router />
                        </PageWrapper>
                        <Footer />
                    </Background>
                </AppProvider>
            </HelmetProvider>
        </ApolloProvider>
    );
}
