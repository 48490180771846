import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import SearchDetailFileViewer from "../components/SearchDetailFileViewer";
import SearchDetailBasicInfo from "../components/SearchDetailBasicInfo";
import SearchDetailHeader from "../components/SearchDetailHeader";
import { useLazyQuery, useMutation } from "@apollo/client";
import { BookMarkControllerEnum, BookMarkDocument, OfferSearchDocument, OfferStatusInputEnum, PostMessageDocument } from "../gql/graphql";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { useParams } from "react-router-dom";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { OfferResponse } from "../gql/graphql";
import { useAccount, useTranslateField } from "../appContext/AppContext";
import { Message } from "../components/global/Message";
import { ApiPdf, ApiPdfInterface } from "../components/PdfComponent/ApiPdf";
import { CreateFileLink } from "../utils/files/Files";
import PageRecords from "../routes/PageRecords";
import { OfferStatusEnum } from "../enum/OfferEnum";
import { Helmet } from "react-helmet-async";

export default function PagesSearchDetail() {
    const translate = useTranslateField();

    const [offerSearch,] = useLazyQuery(OfferSearchDocument)
    const [record, setRecord] = useState<OfferResponse | undefined>(undefined)
    const account = useAccount()
    const [bookmark,] = useMutation(BookMarkDocument)
    const [postMessageCall,] = useMutation(PostMessageDocument)

    const apiPdfRef = useRef<ApiPdfInterface>(null)

    const { id } = useParams()

    if (!id) {
        return <></>
    }

    const CreatePdf = async () => {
        let data = record
        if (!data) {
            return
        }
        console.log("createPdf start")
        let price = Number(data.price)
        let priceStr = ""
        if (price > 0) {
            priceStr = price.toLocaleString()
            if (data.current) {
                priceStr += " " + data.current
            }
        } else {

            priceStr = "Negotiable"
        }
        apiPdfRef.current?.createPdf({
            id: data.id,
            files: data.files.map(v => CreateFileLink(v.key)),
            title: data.title,
            type: data.type.label,
            maker: data.maker ? data.maker : "-",
            model: data.model ? data.model : "-",
            price: priceStr,
            description: data.description,
            link: PageRecords.SearchDetail.createSearchDetailLink(data.id),
        })
        console.log("createPdf end")
    }

    useAsyncEffect(async () => {
        if (id) {
            let rsp = await offerSearch(
                {
                    variables: {
                        offerSearchId: id,
                    }
                }
            )
            if (rsp.data) {
                setRecord(rsp.data.OfferSearch)
            }
        }
    }, [])

    const OnBookMarkedChange = async (ids: string[], controller: BookMarkControllerEnum) => {
        let response = await bookmark({
            variables: {
                offerIds: ids,
                controller: controller,
            }
        })

        if (response.data && record) {
            if (response.data.BookMark.valueOf()) {
                let newIsBookMark = record.isBookmarked
                if (controller === BookMarkControllerEnum.Add) {
                    newIsBookMark = true
                } else if (controller === BookMarkControllerEnum.Remove) {
                    newIsBookMark = false
                }

                setRecord({
                    ...record,
                    isBookmarked: newIsBookMark,
                })
            }
        }
    }

    const [openContactDialog, setOpenContactDialog] = useState<boolean>(false);
    const handleOpenContactDialog = () => {
        setOpenContactDialog(true);
    };
    const handleCloseContactDialog = () => {
        setOpenContactDialog(false);
    };
    const handleSendMessage = async () => {
        if (message.length > 0) {
            let response = await postMessageCall({
                variables: {
                    data: {
                        offerId: id,
                        text: message
                    }
                }
            })
            if (response.data && response.data.PostMessage.valueOf()) {
                setMessage("")
                Message.Success("Message sent successfully");
                setOpenContactDialog(false);
            } else {
                Message.Error("Message sent failed")
            }
        } else {
            Message.Warning("send message is should not be empty");
        }
    };
    const [message, setMessage] = useState<string>("");
    const handleChangeMessage = (e: ChangeEvent<HTMLInputElement>) => {
        setMessage(e.target.value);
    };

    useEffect(() => {
        document.title = "Liberty | " + (record?.model || "")
        return () => {
            document.title = "Liberty | Business Opportunities"
        }
    }
        , [record])

    return (
        <>
        <Helmet>
                {/* <title>{"Liberty | " + (record?.model || "")}</title> */}
                <meta name="description" content={record?.model + " " + record?.description} />
                <meta name="keywords" content={`エラスティック合同会社,エラスティック,Elastic,リバティ,Liberty,Business Opportunities,Parts,Used,Equipment,中古装置,${record?.maker || "Maker"},${record?.model || "Model"},${record?.keywords}`}  />
        </Helmet>
            {record === undefined ? <></> :
                <>
                    <Box py={4}>
                        <Container>
                            <SearchDetailHeader offerId={record.id} isBookMarked={record.isBookmarked} onBookMarkedChange={OnBookMarkedChange} handleOpenContactDialog={handleOpenContactDialog} onPdfClick={CreatePdf} />
                            <Box
                                className=" relative"
                            >
                                {record.status === OfferStatusInputEnum.Closed && <Container className=" flex justify-center items-center p-0 bg-black w-full h-full absolute top-0 left-0 right-0 bottom-0 z-10 bg-opacity-50">
                                    <Typography className=" opacity-90 bg-white bg-opacity-30 text-red-600 rounded-md border-4 border-solid border-red-600 py-2 px-20" variant="h1">{translate(record.status)}</Typography>
                                </Container>}
                                <Box
                                    sx={{ mt: 2 }}>
                                    {record.files.length > 0 ? (
                                        <Grid
                                            container
                                            spacing={2}
                                            sx={{ position: "relative" }}
                                        >
                                            <Grid item xs={12} md={6}>
                                                <SearchDetailFileViewer files={record.files} maker={record.maker || undefined} model={record.model || undefined} />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <SearchDetailBasicInfo
                                                    category={record.category.label}
                                                    type={record.type.label}
                                                    hashtags={record.keywords ? record.keywords : []}
                                                    title={record.title}
                                                    description={record.description}
                                                    no={record.no}
                                                />
                                            </Grid>
                                        </Grid>
                                    ) : (
                                        <SearchDetailBasicInfo
                                            category={record.category.label}
                                            type={record.type.label}
                                            hashtags={record.keywords ? record.keywords : []}
                                            title={record.title}
                                            description={record.description}
                                            no={record.no}
                                        />
                                    )}
                                </Box>
                                <Grid container spacing={2} sx={{ pt: 2 }}>
                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    {translate("Maker")}
                                                </Typography>
                                                <Typography sx={{ textAlign: "right" }}>
                                                    {record.maker ? record.maker : "-"}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    {translate("Model")}
                                                </Typography>
                                                <Typography sx={{ textAlign: "right" }}>
                                                    {record.model ? record.model : "-"}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    {translate("Year")}
                                                </Typography>
                                                <Typography sx={{ textAlign: "right" }}>
                                                    {record.year ? record.year : "-"}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    {translate("Size")}
                                                </Typography>
                                                <Typography sx={{ textAlign: "right" }}>
                                                    {record.size ? record.size : "-"}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    {translate("Region")}
                                                </Typography>
                                                <Typography sx={{ textAlign: "right" }}>
                                                    {record.country ? record.country.label : "-"}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="subtitle1">
                                                    {translate("Price")}
                                                </Typography>
                                                <Typography sx={{ textAlign: "right" }}>
                                                    {!record.price || !record.current ? translate("Negotiable") : `${record.price} ${record.current}`}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Card sx={{ mt: 2 }}>
                                    <CardContent>
                                        <Typography variant="subtitle1">
                                            {translate("Author of this post")}
                                        </Typography>
                                        <Box
                                            sx={{
                                                pt: 1,
                                                display: "flex",
                                                alignItems: "center",
                                                gap: 2,
                                            }}
                                        >
                                            <Avatar />
                                            <Typography>{record.owner.email ?record.owner.email  : "-"}</Typography>
                                        </Box>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            sx={{ mt: 3 }}
                                            onClick={handleOpenContactDialog}
                                        >
                                            {translate("Contact")}
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Box>
                        </Container>
                    </Box>
                    <Dialog
                        open={openContactDialog}
                        onClose={handleCloseContactDialog}
                        fullWidth
                    >
                        <DialogTitle>{translate("Contact")}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                {translate('Enter your message and press the "SEND" button to send an message to the author of this post.')}
                            </DialogContentText>
                            <TextField
                                label={translate("Message")}
                                multiline
                                rows={8}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                sx={{ mt: 4 }}
                                value={message}
                                onChange={handleChangeMessage}
                                placeholder={translate("Enter your message")}
                            />
                        </DialogContent>
                        <DialogActions sx={{ px: 3, py: 2 }}>
                            <Button onClick={handleCloseContactDialog}>{translate("Cancel")}</Button>
                            <Button
                                variant="contained"
                                onClick={handleSendMessage}
                                disabled={message.length === 0}
                            >
                                {translate("Send")}
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            }
            <div className=' absolute top-[-9999px]'>
                <ApiPdf ref={apiPdfRef}></ApiPdf>
            </div>
        </>
    );
}
