import { Box } from "@mui/material";
import background from "../../media/images/kumiko.png";
import { ReactNode } from "react";

type Props = {
    children: ReactNode;
};

export default function Background(props: Props) {
    return (
        <>
            <Box
                sx={{
                    backgroundImage: `url(${background})`,
                    backgroundColor: "#f7f7f7",
                    position: "relative",
                    zIndex: 0,
                    minWidth: "320px",
                }}
            >
                {props.children}
            </Box>
        </>
    );
}
