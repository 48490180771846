import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { ReactNode, useState } from 'react';
import { Container, Typography } from '@mui/material';
import { useTranslateField } from '../appContext/AppContext';

function not(a: readonly TransferListItem[], b: readonly TransferListItem[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly TransferListItem[], b: readonly TransferListItem[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export interface TransferListItem {
    label: ReactNode
    value: ReactNode
}

export default function TransferList({
    unSelected,
    selected,
    onSave,
    onClose,
}: {
    unSelected?: Array<TransferListItem>,
    selected?: Array<TransferListItem>,
    onSave?: (selected: TransferListItem[]) => void
    onClose?: () => void
}) {
    const [checked, setChecked] = useState<readonly TransferListItem[]>([]);
    const [left, setLeft] = useState<readonly TransferListItem[]>(selected ? selected : []);
    const [right, setRight] = useState<readonly TransferListItem[]>(unSelected ? unSelected : []);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const translate = useTranslateField()

    const handleToggle = (value: TransferListItem) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    const customList = (items: readonly TransferListItem[]) => (
        <Paper sx={{ width: 200, height: 230, overflow: 'auto' }}>
            <List dense component="div" role="list">
                {items.map((value: TransferListItem) => {
                    const labelId = `transfer-list-item-${value}-label`;
                    return (
                        <ListItem
                            key={value.value?.toString()}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.label} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>
                <Typography sx={{ fontWeight: "bolder" }} align='center'>{translate("Set")}</Typography>
                {customList(left)}
            </Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={left.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={right.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item>
                <Typography sx={{ fontWeight: "bolder" }} align='center'>{translate("Unset")}</Typography>
                {customList(right)}
            </Grid>
            <Container sx={{
                display: "flex",
                justifyContent: " flex-end",
                marginTop: "1rem"
            }}>
                <Button sx={{ marginRight: "2rem" }} onClick={() => onClose && onClose()}>Disagree</Button>
                <Button autoFocus
                    onClick={() => {
                        if (left) {
                            onSave && onSave([...left])
                        }
                        onClose && onClose()
                    }}
                >
                    Agree
                </Button>
            </Container>
        </Grid>
    );
}