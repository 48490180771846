import { Box, Divider, Paper, Switch, Typography } from "@mui/material";
import { useAccount, useTranslateField } from "../appContext/AppContext";
import { useEffect, useState } from "react";
import { SendMailEnum, SettingSendMailDocument } from "../gql/graphql";
import { useMutation } from "@apollo/client";

export default function PagesAccountMailSetting() {
    const account = useAccount()
    const [sendMail, setSendMail] = useState(SendMailEnum.Send)
    const [settingSendMail,] = useMutation(SettingSendMailDocument)
    if (!account) {
        return <></>
    }

    useEffect(() => {
        let useRecord = account.GetUser()
        console.log("useRecord=", useRecord)
        if (useRecord) {
            setSendMail(useRecord.sendMail)
        }
    }, [])

    const OnSettingSendMailChange = async () => {
        let sendMailType = SendMailEnum.Send
        if (sendMail === SendMailEnum.Send) {
            sendMailType = SendMailEnum.Nosend
        }
        const useRecond = await settingSendMail({
            variables: {
                sendMailType: sendMailType
            }
        })

        if (useRecond.data) {
            setSendMail(sendMailType)
            account.SetUser(useRecond.data.SettingSendMail)
        }
    }

    const translate = useTranslateField();

    return (
        <>
            <Typography variant="h4">{translate("Mail setting")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph mt={2}>
                {translate("On this page, you can configure settings related to email delivery.")}
            </Typography>
            <Box sx={{ mt: 4 }}>
                <Paper
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 1,
                        p: 2,
                    }}
                >
                    <Typography>{translate("Get email updates from Liberty")}</Typography>
                    <Switch checked={sendMail === SendMailEnum.Send ? true : false} onChange={OnSettingSendMailChange} />
                </Paper>
            </Box>
        </>
    );
}
