
export function PdfImageList({
    data
}: {
    data: string[]
}) {

    const CreateImages = () => {
        if (data.length === 0) {
            return <div>No image</div>
        }
        if (data.length === 1) {
            return <div
                style={{
                    width: "200mm",
                    height: "130mm",
                    display: "inline-block",
                    backgroundImage: `url(${data[0]})`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                }}
            />
        } else if (data.length === 2) {
            return [
                <div
                    style={{
                        width: "95mm",
                        height: "110mm",
                        marginRight: "1mm",
                        display: "inline-block",
                        backgroundImage: `url(${data[0]})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />,
                <div
                    style={{
                        width: "95mm",
                        height: "110mm",
                        display: "inline-block",
                        backgroundImage: `url(${data[1]})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
            ]
        }

        if (data.length >= 4) {
            return data.slice(0, 4).map((v, index) => {
                if (index % 2 === 0) {
                    return <div
                        key={index}
                        style={{
                            width: "98mm",
                            height: "68mm",
                            marginRight: "1mm",
                            display: "inline-block",
                            backgroundImage: `url(${v})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                }
                return <div
                    key={index}
                    style={{
                        width: "98mm",
                        height: "68mm",
                        display: "inline-block",
                        backgroundImage: `url(${v})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
            })
        } else {
            return data.map((v, index) => {
                if (index % 2 === 0) {
                    return <div
                        key={index}
                        style={{
                            width: "98mm",
                            height: "68mm",
                            marginRight: "1mm",
                            display: "inline-block",
                            backgroundImage: `url(${v})`,
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                        }}
                    />
                }
                return <div
                    style={{
                        width: "98mm",
                        height: "68mm",
                        display: "inline-block",
                        backgroundImage: `url(${v})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                    }}
                />
            })
        }

    }
    return (
        <div className=" h-[140mm] flex justify-around flex-wrap items-center">
            {CreateImages()}
        </div>
    )
}