import { ReactNode, useEffect, useState } from "react";
import { CreateRenderNode } from "./RenderNode";
import { Root } from "react-dom/client";
import { Alert } from "@mui/material";
import "./Message.css";
import { MessageEnum } from "../../enum/MessageEnum";

interface MessageInterface {
    msg: ReactNode;
    type: MessageEnum;
    isShowed: boolean;
    id: string;
}

export class Message {
    static _messages: MessageInterface[] = [];
    static _root: Root;
    static Error(message: ReactNode) {
        this._init();
        this._messages.push({
            msg: message,
            type: MessageEnum.ERROR,
            isShowed: false,
            id: new Date().getTime().toString(),
        });
        this._render();
    }

    static Warning(message: ReactNode) {
        this._init();
        this._messages.push({
            msg: message,
            type: MessageEnum.WARNING,
            isShowed: false,
            id: new Date().getTime().toString(),
        });
        this._render();
    }

    static Info(message: ReactNode) {
        this._init();
        this._messages.push({
            msg: message,
            type: MessageEnum.INFO,
            isShowed: false,
            id: new Date().getTime().toString(),
        });
        this._render();
    }

    static Success(message: ReactNode) {
        this._init();
        this._messages.push({
            msg: message,
            type: MessageEnum.SUCCESS,
            isShowed: false,
            id: new Date().getTime().toString(),
        });
        this._render();
    }

    private static _render() {
        return this._root.render(
            // <div className=" max-w-sm break-all absolute right-0 top-4 transition-all ">
            <div className="message-list">
                {this._messages.map((v, index) => {
                    if (v.isShowed) {
                        return (
                            <div
                                id={v.id}
                                style={{
                                    marginTop: "1rem",
                                    zIndex: 999999,
                                    position: "relative",
                                }}
                            >
                                <Alert severity={v.type}>{v.msg}</Alert>
                            </div>
                        );
                    } else {
                        var entry = this._messages.find((m) => {
                            return m.id === v.id;
                        });
                        if (!entry) {
                            return;
                        }
                        entry.isShowed = true;
                        setTimeout(() => {
                            var node = document.getElementById(v.id)
                            node?.classList.add("message-hide")
                            node?.addEventListener("animationend", () => {
                                node?.remove()
                            })
                            this._messages = this._messages.filter(m => {
                                return m.id !== v.id
                            })
                        }, 5000)
                        return (
                            <div
                                id={v.id}
                                style={{
                                    marginTop: "1rem",
                                    zIndex: 999999,
                                    position: "relative",
                                }}
                                className="message-show"
                            >
                                <Alert severity={v.type}>{v.msg}</Alert>
                            </div>
                        );
                    }
                })}
            </div>
        );
    }

    private static _init() {
        this._root = CreateRenderNode("global_message");
    }
}
