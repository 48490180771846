import PageRecords from "../../../routes/PageRecords"

export interface BodyContextInterface {
    id: string
    title: string
    type: string
    maker: string
    model: string
    price: string
    description: string
    link: string
}

export function BodyContext({
    data
}: {
    data: BodyContextInterface
}) {
    return (
        <div
            style={{
                height: "106mm",
                backgroundColor: "#EFEFEF",
                padding: "2mm",
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                alignContent: "start",
                justifyContent: "space-between",
                boxSizing: "border-box",
                gap: "1mm"
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexBasis: "100%",
                    flexGrow: 1,
                }}
            >
                <span
                    style={{
                        marginRight: "2mm",
                        minWidth: "18mm",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "5mm",
                    }}
                >TITLE:</span>
                <span
                    style={{
                        textAlign: "center",
                        margin: 0,
                        backgroundColor: "white",
                        width: "100%",
                        fontWeight: "bold",
                    }}
                >
                    <a href={data.link}>{data.title}</a></span>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexBasis: "49%",
                    flexGrow: 1,
                }}
            >
                <span
                    style={{
                        marginRight: "2mm",
                        minWidth: "18mm",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "5mm",
                    }}
                >TYPE:</span>
                <span
                    style={{
                        textAlign: "center",
                        margin: 0,
                        backgroundColor: "white",
                        width: "100%",
                        fontWeight: "bold",
                    }}
                >{data.type}</span>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexBasis: "49%",
                    flexGrow: 1,
                }}
            >
                <span
                    style={{
                        marginRight: "2mm",
                        minWidth: "18mm",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "5mm",
                    }}
                >MAKER:</span>
                <span
                    style={{
                        textAlign: "center",
                        margin: 0,
                        backgroundColor: "white",
                        width: "100%",
                        fontWeight: "bold",
                    }}
                >{data.maker}</span>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexBasis: "49%",
                    flexGrow: 1,
                }}
            >
                <span
                    style={{
                        marginRight: "2mm",
                        minWidth: "18mm",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "5mm",
                    }}
                >MODEL:</span>
                <span
                    style={{
                        textAlign: "center",
                        margin: 0,
                        backgroundColor: "white",
                        width: "100%",
                        fontWeight: "bold",
                    }}
                >{data.model}</span>
            </div>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexBasis: "49%",
                    flexGrow: 1,
                }}
            >
                <span
                    style={{
                        marginRight: "2mm",
                        minWidth: "18mm",
                        textAlign: "left",
                        fontWeight: "bold",
                        fontSize: "5mm",
                    }}
                >PRICE:</span>
                <span
                    style={{
                        textAlign: "center",
                        margin: 0,
                        backgroundColor: "white",
                        width: "100%",
                        fontWeight: "bold",
                    }}
                >{data.price}</span>
            </div>
            <div
                style={{
                    flex: 1,
                    flexGrow: 1,
                }}
            >
                <p
                    style={{
                        margin: 0,
                        fontWeight: 'bold',
                        fontSize: "5mm",
                    }}
                >DESCRIPTION:</p>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: "3mm",
                    height: "68mm",
                    backgroundColor: "white",
                    wordBreak: "break-all",
                    whiteSpace: "pre-wrap",

                }} >{data.description}</div>
            </div>
        </div>
    )
}