import { GroupEnum, User } from "../gql/graphql"
import { GetLocalData, RemoveLocalData, SetLocalData } from "../utils/localStroage"

export type UserRecordType = User | undefined
const sessionKey = "session"
const userKey = "user"
export class Account {
    private _session: string
    private _user: UserRecordType
    constructor(session: string, user: UserRecordType) {
        this._session = session
        this._user = user
    }

    public SetSession(session: string) {
        this._session = session
        SetLocalData(sessionKey, session)
    }

    public IsLogin(): boolean {
        let session = this.GetSession()
        if (session && session.length > 0) {
            return true
        }
        return false
    }

    public GetSession(): string {
        if (!this._session || this._session.length === 0) {
            let session = GetLocalData(sessionKey)
            if (session) {
                this._session = session
            } else {
                this._session = ""
            }
        }
        return this._session
    }

    public SetUser(user: User) {
        this._user = user
        SetLocalData(userKey, user)
    }

    public GetUser(): UserRecordType {
        if (!this._user) {
            this._user = GetLocalData(userKey)
        }
        return this._user
    }

    public IsAdmin(): boolean {
        if (!this._user ||(this._user.group && !this._user.group.includes(GroupEnum.Admin))) {
            return false
        }
        return true
    }

    public IsStaff(): boolean {
        if (!this._user ||(this._user.group && !this._user.group.includes(GroupEnum.Staff))) {
            return false
        }
        return true
    }

    public IsSystem(): boolean {
        if (!this._user ||(this._user.group && !this._user.group.includes(GroupEnum.System))) {
            return false
        }
        return true
    }

    public IsManager(): boolean {
        let isManager = false
        if (this._user && this._user.group) {
            console.log("this._user.group=", this._user.group)
            for (let i = 0; i < this._user.group.length; i++) {
                let v = this._user.group[i]
                if (v === GroupEnum.Admin || v === GroupEnum.Staff || v === GroupEnum.System) {
                    isManager = true
                    break
                }
            }
        }
        return isManager
    }

    public LogIn(session: string, user: User) {
        this.SetSession(session)
        this.SetUser(user)
    }

    public LogOut() {
        this._session = ""
        this._user = undefined
        RemoveLocalData(sessionKey)
        RemoveLocalData(userKey)
    }
}