import { useEffect, useState } from "react";
import ResponsiveAppBar from "./ResponsiveAppBar";
import PageRecords, { PageRecord } from "../routes/PageRecords";
import { useLocation, useNavigate } from "react-router-dom";

export default function Header() {
    const [defaultStyle, setDefaultStyle] = useState<boolean>(true);
    const [hidden, setHidden] = useState<boolean>(true);
    const { pathname } = useLocation();
    useEffect(() => {
        const pathsWithoutDefaultStyle = [
            PageRecords.Home.path,
            PageRecords.SignUp.path,
            PageRecords.SignIn.path,
        ];
        // for 404 not found page
        const allPath: string[] = [];
        (Object.values(PageRecords) as PageRecord[]).forEach(
            (record: PageRecord) => {
                allPath.push(record.path);
                if (record.children) {
                    Object.values(record.children).forEach(
                        (childRecord: PageRecord) => {
                            allPath.push(record.path + childRecord.path);
                        }
                    );
                }
            }
        );
        const defaultStyleFlag = allPath.includes(pathname)
            ? pathsWithoutDefaultStyle.includes(pathname)
                ? false
                : true
            : false;
        setDefaultStyle(defaultStyleFlag);

        let hiddenFlag = false;
        const pathsWithoutHeader: string[] = [];
        hiddenFlag = pathsWithoutHeader.includes(pathname);
        setHidden(hiddenFlag);
    }, [pathname]);

    return hidden ? <></> : <ResponsiveAppBar defaultStyle={defaultStyle} />;
}
