import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, PaperProps, TextField, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";
import { SendPromotionalEmailInput, SendPromotionalEmailType } from "../../gql/graphql";
import { set } from "zod";

export function SendMailPage({
    title,
    button,
    onSend,
}: {
    title: ReactNode
    button?: ReactNode
    onSend?: (sendType: SendPromotionalEmailType[], email: string[]) => void
}) {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [mailList, setMailList] = useState("")
    const [sendTypeList, setSendTypeList] = useState<Array<SendPromotionalEmailType>>([])

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const OnSend = async () => {
        const emailPattern = /[\w.-]+@[\w.-]+\.[a-zA-Z]{2,4}/g;
        let mails = mailList.match(emailPattern) as Array<string> 
        console.log("mails=", mails)
        console.log("sendTypeList=", sendTypeList)
        onSend && await onSend(sendTypeList, mails?mails:[])
        setMailList("")
        setSendTypeList([])
        handleClose()
    }
    return (
        <Box className=" inline-block">
            {button ? <Typography className="flex" onClick={handleClickOpen}>{button}</Typography> : <Button variant="outlined" className=" bg-red-400 hover:bg-red-700 text-white border-red-400" onClick={handleClickOpen}>
                Send
            </Button>}
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleClose}
                aria-labelledby="send-mail-dialog-title"
            >
                <Box
                    sx={{
                        padding: "1rem",
                    }}
                >
                    <DialogTitle id="send-mail-dialog-title">
                        商品名:{title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            宣伝商品情報をお客様に送信
                        </DialogContentText>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        let newSendTypeList = [...sendTypeList]
                                        if (!sendTypeList.includes(SendPromotionalEmailType.Member)) {
                                            newSendTypeList.push(SendPromotionalEmailType.Member)
                                        }
                                        console.log("newSendTypeList111 = ", newSendTypeList)
                                        setSendTypeList([...newSendTypeList])
                                    } else {
                                        let newSendTypeList = sendTypeList.filter(v => v !== SendPromotionalEmailType.Member)
                                        console.log("newSendTypeList1111 = ", newSendTypeList)
                                        setSendTypeList([...newSendTypeList])
                                    }
                                }}
                                checked={sendTypeList.includes(SendPromotionalEmailType.Member)}
                            />} label="会員" />
                            <FormControlLabel control={
                                <Checkbox
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            let newSendTypeList = [...sendTypeList]
                                            if (!sendTypeList.includes(SendPromotionalEmailType.Entered)) {
                                                newSendTypeList.push(SendPromotionalEmailType.Entered)
                                            }
                                            setSendTypeList([...newSendTypeList])
                                        } else {
                                            let newSendTypeList = sendTypeList.filter(v => v !== SendPromotionalEmailType.Entered)
                                            setSendTypeList([...newSendTypeList])
                                        }
                                    }}
                                    checked={sendTypeList.includes(SendPromotionalEmailType.Entered)}
                                />} label="入力メール" />
                        </FormGroup>
                        <TextField
                            onChange={(e) => setMailList(e.target.value)}
                            helperText={<div><p style={{ marginBottom: 0 }}>非会員の方も、複数のメールアドレスを入力することができます。</p><p style={{ marginTop: 0 }}>例えば「mail1@xmail.com;mail2@xmail.com」といったように、各メールアドレスは「;」で区切ってください。</p></div>} placeholder="" fullWidth id="standard-basic" label="メールアドレス" variant="standard" />
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button onClick={OnSend} autoFocus>
                            Send
                        </Button>
                    </DialogActions>
                </Box>
            </Dialog>
        </Box>
    )
}