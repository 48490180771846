import { Cancel, ChevronLeft, ChevronRight, ImageSearch, RotateLeft, RotateRight, ZoomIn, ZoomOut } from "@mui/icons-material";
import { AppBar, Box, ButtonBase, Container, Grid, IconButton, Modal, SxProps, Toolbar, Tooltip, Typography, css } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import theme from "../theme";
import { CreateFileLink } from "../utils/files/Files";
import { FileRecord } from "../gql/graphql";

export default function SearchDetailFileViewer(props: { files: FileRecord[]; model?: string; maker?: string; sx?: SxProps }) {
    const { files, sx, model, maker } = props;

    // どの画像が現在選択されているかを管理
    const [fileIndex, setFileIndex] = useState<number>(0);
    const handleFileIndex = (delta: number) => (e: any) => {
        const newFileIndex = fileIndex + delta;
        if (newFileIndex < 0) {
            setFileIndex(files.length - 1);
            return;
        }
        if (newFileIndex > files.length - 1) {
            setFileIndex(0);
            return;
        }
        setFileIndex(newFileIndex);
    };

    // Modalの表示・非表示を管理
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(!open);
    };

    // Modal上のビュアーに表示されている画像の状態を管理
    const defaultImageState = {
        scale: 1,
        rotate: 0,
        translate: { x: 0, y: 0 },
    };
    const [imageState, setImageState] = useState(defaultImageState);

    // <--start-- ビュワー上のボタン操作に対応し、ビュワー上の画像の拡大と回転を操作する
    const handleZoomIn = () => {
        const scale = imageState.scale + 0.1;
        setImageState({
            ...imageState,
            scale: scale,
        });
    };
    const handleZoomOut = () => {
        const scale = imageState.scale - 0.1;
        if (scale < 0.1) return;
        setImageState({
            ...imageState,
            scale: scale,
        });
    };
    const handleRotateRight = () => {
        const rotate = imageState.rotate + 1;
        setImageState({
            ...imageState,
            rotate: rotate,
        });
    };
    const handleRotateLeft = () => {
        const rotate = imageState.rotate - 1;
        setImageState({
            ...imageState,
            rotate: rotate,
        });
    };
    // --end--> ビュワー上のボタン操作に対応し、ビュワー上の画像の拡大と回転を操作する

    // <--start-- ドラッグ、ホイール、タッチ、ピンチに対応し、ビュワー上の画像の移動と拡大を操作する
    const imgRef = useRef<HTMLImageElement>(null);
    // 移動用
    let startPoint = { x: 0, y: 0 };
    let movingPoint = { x: 0, y: 0 };
    let difference: { x: number; y: number } = { x: 0, y: 0 };
    // 拡大用
    let startPinchDistance = 0;
    let movingPinchDistance = 0;
    let pinchScale = 1;
    const getDistance = (e: TouchEvent) => {
        const touches = e.changedTouches;
        if (touches.length > 1) {
            const x1 = touches[0].pageX;
            const y1 = touches[0].pageY;
            const x2 = touches[1].pageX;
            const y2 = touches[1].pageY;
            const distance = Math.floor(Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)));
            return distance;
        }
        return 0;
    };
    // transitionFlagが1の時、移動/拡大を適用
    let transitionFlag = 0;
    useEffect(() => {
        if (!open) return;
        const onMouseDown = (e: MouseEvent) => {
            e.preventDefault();
            imgRef.current!.style.transition = "none";
            startPoint = { x: e.x, y: e.y };
            transitionFlag = 1;
        };
        const onMouseMove = (e: MouseEvent) => {
            if (transitionFlag == 1) {
                movingPoint = { x: e.x, y: e.y };
                difference = {
                    x: movingPoint.x - startPoint.x,
                    y: movingPoint.y - startPoint.y,
                };
                imgRef.current!.style.transform = `translate3d(${imageState.translate.x + difference.x}px, ${imageState.translate.y +
                    difference.y}px, 0px) scale3d(${imageState.scale}, ${imageState.scale}, 1) rotate(${90 * imageState.rotate}deg)`;
            }
        };
        const onMouseUp = (e: MouseEvent) => {
            if (transitionFlag === 1) {
                imgRef.current!.style.transition = "transform .25s";
                setImageState({
                    ...imageState,
                    translate: {
                        x: imageState.translate.x + difference.x,
                        y: imageState.translate.y + difference.y,
                    },
                });
                transitionFlag = 0;
            }
        };
        const onWheel = (e: WheelEvent) => {
            let newScale = imageState.scale - e.deltaY / 2000;
            newScale = newScale > 0.1 ? newScale : 0.1;
            setImageState({ ...imageState, scale: newScale });
        };
        const onTouchStart = (e: TouchEvent) => {
            e.preventDefault();
            imgRef.current!.style.transition = "none";
            startPoint = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
            };
            startPinchDistance = getDistance(e);
            transitionFlag = 1;
        };
        const onTouchMove = (e: TouchEvent) => {
            if (transitionFlag == 1) {
                movingPoint = {
                    x: e.touches[0].clientX,
                    y: e.touches[0].clientY,
                };
                difference = {
                    x: movingPoint.x - startPoint.x,
                    y: movingPoint.y - startPoint.y,
                };
                if (e.touches.length > 1) {
                    if (startPinchDistance) movingPinchDistance = getDistance(e);
                    else startPinchDistance = getDistance(e);
                    pinchScale = movingPinchDistance / startPinchDistance ? movingPinchDistance / startPinchDistance : pinchScale;
                    imgRef.current!.style.transform = `translate3d(${imageState.translate.x + difference.x}px, ${imageState.translate.y +
                        difference.y}px, 0px) scale3d(${imageState.scale * pinchScale}, ${imageState.scale * pinchScale}, 1) rotate(${90 *
                        imageState.rotate}deg)`;
                } else {
                    imgRef.current!.style.transform = `translate3d(${imageState.translate.x + difference.x}px, ${imageState.translate.y +
                        difference.y}px, 0px) scale3d(${imageState.scale}, ${imageState.scale}, 1) rotate(${90 * imageState.rotate}deg)`;
                }
            }
        };
        const onTouchEnd = (e: TouchEvent) => {
            if (transitionFlag === 1) {
                imgRef.current!.style.transition = "transform .25s";
                const newTranslate = {
                    x: imageState.translate.x + difference.x,
                    y: imageState.translate.y + difference.y,
                };
                let newScale = imageState.scale * pinchScale;
                newScale = newScale > 0.1 ? newScale : 0.1;
                setImageState({
                    ...imageState,
                    translate: newTranslate,
                    scale: newScale,
                });
                transitionFlag = 0;
            }
        };

        // PC用
        imgRef.current!.addEventListener("mousedown", onMouseDown);
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("wheel", onWheel);
        // 　モバイル用
        imgRef.current!.addEventListener("touchstart", onTouchStart);
        window.addEventListener("touchmove", onTouchMove);
        window.addEventListener("touchend", onTouchEnd);

        imgRef.current!.style.transform = `translate3d(${imageState.translate.x + difference.x}px, ${imageState.translate.y +
            difference.y}px, 0px) scale3d(${imageState.scale}, ${imageState.scale}, 1) rotate(${90 * imageState.rotate}deg)`;

        return () => {
            // PC用
            // imgRef.current!.removeEventListener("mousedown", onMouseDown);
            window.removeEventListener("mousemove", onMouseMove);
            window.removeEventListener("mouseup", onMouseUp);
            window.removeEventListener("wheel", onWheel);
            // モバイル用
            // imgRef.current!.removeEventListener("touchstart", onTouchStart);
            window.removeEventListener("touchmove", onTouchMove);
            window.removeEventListener("touchend", onTouchEnd);
        };
    }, [imageState]);
    // --end--> ドラッグ、ホイール、タッチ、ピンチに対応し、ビュワー上の画像の移動と拡大を操作する

    // Modalの表示/非表示と選択ファイルの変化に反応してスタイルをデフォルトに戻す
    useEffect(() => {
        setImageState(defaultImageState);
    }, [open, fileIndex]);

    return (
        <>
            <Box
                sx={{
                    ...sx,
                    aspectRatio: 1.62,
                    backgroundImage: `url(${CreateFileLink(files[fileIndex].key)})`,
                    backgroundRepeat: "no-repeat",
                    // backgroundSize: "cover",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundColor: "rgba(0,0,0,.25)",
                    position: "sticky",
                    top: "80px",
                }}>
                <img
                    src={CreateFileLink(files[fileIndex].key)}
                    alt={`${maker || ""} ${model || ""} ${fileIndex + 1}/${files.length}`}
                    style={{
                        width: 1, // Set to 1 pixel to make it invisible
                        height: 1, // Set to 1 pixel to make it invisible
                        position: "absolute", // Takes it out of the flow
                        top: "-9999px", // Moves it off-screen
                        left: "-9999px", // Moves it off-screen
                    }}
                />
                <Grid
                    container
                    alignItems="stretch"
                    sx={{
                        height: "100%",
                        color: "#fff",
                        textShadow: "0 0 4px #000",
                    }}>
                    <Grid
                        item
                        xs={1}
                        bgcolor="#00000033"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                        }}>
                        <Tooltip title="Prev image" placement="right" disableInteractive>
                            <ButtonBase onClick={handleFileIndex(-1)} sx={{ width: "100%", height: "100%" }}>
                                <ChevronLeft
                                    fontSize="large"
                                    sx={{
                                        filter: "drop-shadow(0 0 4px #000)",
                                    }}
                                />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                    <Grid
                        item
                        xs
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            position: "relative",
                        }}>
                        <Box
                            sx={{
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                ".MuiSvgIcon-root": {
                                    transition: `opacity ${theme.transitions.easing.sharp} .15s, filter ${theme.transitions.easing.sharp} .15s`,
                                    opacity: 0,
                                },
                                ":hover": {
                                    ".MuiSvgIcon-root": {
                                        filter: "drop-shadow(0 0 4px #000)",
                                        opacity: 1,
                                    },
                                },
                            }}
                            onClick={handleOpen}>
                            <ImageSearch fontSize="large" />
                        </Box>
                        <Typography
                            sx={{
                                position: "absolute",
                                bottom: 10,
                                userSelect: "none",
                                pointerEvents: "none",
                            }}>
                            {`${fileIndex + 1} / ${files.length}`}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        bgcolor="#00000033"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            overflow: "hidden",
                        }}>
                        <Tooltip title="Next image" placement="left" disableInteractive>
                            <ButtonBase onClick={handleFileIndex(1)} sx={{ width: "100%", height: "100%" }}>
                                <ChevronRight
                                    fontSize="large"
                                    sx={{
                                        filter: "drop-shadow(0 0 4px #000)",
                                    }}
                                />
                            </ButtonBase>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Box>
            <Modal open={open}>
                <Box>
                    <AppBar sx={{ bgcolor: "rgba(255, 255, 255, 0.87)" }}>
                        <Container maxWidth="xl">
                            <Toolbar
                                disableGutters
                                sx={{
                                    // header's height is 70px (ResponsiveAppBar.tsx => Toolbar)
                                    height: "70px",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    gap: "0 16px",
                                }}>
                                <Tooltip title="Rotate left">
                                    <IconButton onClick={handleRotateLeft}>
                                        <RotateLeft />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Rotate right">
                                    <IconButton onClick={handleRotateRight}>
                                        <RotateRight />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Zoom out">
                                    <IconButton onClick={handleZoomOut}>
                                        <ZoomOut />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Zoom in">
                                    <IconButton onClick={handleZoomIn}>
                                        <ZoomIn />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Close">
                                    <IconButton onClick={handleOpen}>
                                        <Cancel />
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                        </Container>
                    </AppBar>
                    <Box
                        sx={{
                            position: "absolute",
                            inset: 0,
                            textAlign: "center",
                            overflow: "hidden",
                            "&::before": {
                                display: "inline-block",
                                width: "1px",
                                height: "50%",
                                mr: "-1px",
                                content: '""',
                            },
                        }}>
                        <Box onClick={handleOpen} sx={{ position: "absolute", inset: 0 }} />
                        <img
                            src={CreateFileLink(files[fileIndex].key)}
                            ref={imgRef}
                            css={css`
                                user-select: none;
                                pointer-events: all;
                                max-width: 100%;
                                max-height: 100%;
                                vertical-align: middle;
                                transition: transform 0.25s;
                                transform: ${`translate3d(${imageState.translate.x}px, ${imageState.translate.y}px, 0px) scale3d(${
                                    imageState.scale
                                }, ${imageState.scale}, 1) rotate(${90 * imageState.rotate}deg)`};
                                cursor: grab;
                                &:active {
                                    cursor: grabbing;
                                }
                            `}
                        />
                        <Grid
                            container
                            columns={16}
                            sx={{
                                position: "absolute",
                                inset: 0,
                                pointerEvents: "none",
                            }}>
                            <Grid item xs={2} sm={1.5} md={1}>
                                <Tooltip title="Prev image" placement="right" disableInteractive>
                                    <ButtonBase
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            pointerEvents: "all",
                                        }}
                                        onClick={handleFileIndex(-1)}>
                                        <ChevronLeft
                                            fontSize="large"
                                            sx={{
                                                color: "#fff",
                                                filter: "drop-shadow(0 0 4px #000)",
                                            }}
                                        />
                                    </ButtonBase>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={13} md={14} />
                            <Grid item xs={2} sm={1.5} md={1}>
                                <Tooltip title="Next image" placement="left" disableInteractive>
                                    <ButtonBase
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                            pointerEvents: "all",
                                        }}
                                        onClick={handleFileIndex(1)}>
                                        <ChevronRight
                                            fontSize="large"
                                            sx={{
                                                color: "#fff",
                                                filter: "drop-shadow(0 0 4px #000)",
                                            }}
                                        />
                                    </ButtonBase>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}
