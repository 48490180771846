import ProductHeroLayout from "./ProductHeroLayout";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import backgroundImage from "../media/images/eyecatch.svg";
import { useNavigate } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import { Box } from "@mui/material";
import SearchBar from "./SearchBar";
import { useEffect, useState } from "react";
import { useIsLogin, usePostTypes, useProductTypes, useTranslateField } from "../appContext/AppContext";
import { OfferFilterInput } from "../gql/graphql";
import { SetLocalData } from "../utils/localStroage";

export default function ProductHero() {
    const navigate = useNavigate();
    const onClickNavigate = (path: string) => () => {
        navigate(path);
    };
    const postTypes = usePostTypes()
    const productTypes = useProductTypes()

    const translate = useTranslateField();
    const isLogin = useIsLogin()

    const [filter, setFilter] = useState<OfferFilterInput | undefined>({
        type: postTypes.map(v => v.value),
        onlyExistFile: false,
        productType: productTypes.map(v => v.value),
        keyword: "",
        current: null,
    })

    useEffect(() => {
        setFilter(
            {
                type: postTypes.map(v => v.value),
                onlyExistFile: false,
                productType: productTypes.map(v => v.value),
                keyword: "",
                current: null,
            }
        )
    }, [postTypes, productTypes])

    const OnFilterApply = async (filter: OfferFilterInput) => {
        console.log("productHer filter apply=", filter)
        setFilter(filter)
        SetLocalData("SearchFilter", JSON.stringify(filter))
        navigate(PageRecords.Search.path)
    }

    return (
        <ProductHeroLayout
            sxBackground={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundColor: "#464555",
                backgroundPosition: "center",
            }}
        >
            {/* Increase the network loading priority of the background image. */}
            <img
                style={{ display: "none" }}
                src={backgroundImage}
                alt="increase priority"
            />
            <Box>
                <Typography color="inherit" align="center" variant="h3">
                    {translate("Wanna Sell, Wanna Buy, Can Find")}
                </Typography>
                <Typography align="center" variant="h5" sx={{ mb: 4, mt: 2 }}>
                    {translate("Liberty is a global machine information platform")}
                </Typography>
                <SearchBar sx={{ mt: 2, width: "100%", opacity: 0.9 }} filter={filter} onFilterApply={OnFilterApply} />
                {
                    !isLogin &&
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            gap: 2,
                            flexWrap: "wrap",
                            width: "100%",
                            mt: 2,
                            mb: 2,
                            opacity: 0.9,
                        }}
                    >
                        <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            sx={{ minWidth: 200, flexGrow: 1 }}
                            onClick={onClickNavigate(PageRecords.SignIn.path)}
                        >
                            {translate("Sign in")}
                        </Button>
                        <Button
                            color="secondary"
                            variant="contained"
                            size="large"
                            sx={{ minWidth: 200, flexGrow: 1 }}
                            onClick={onClickNavigate(PageRecords.SignUp.path)}
                        >
                            {translate("Sign up")}
                        </Button>
                    </Box>
                }
                <Box className=" flex justify-center">
                    <video className=" w-[43rem] h-[20rem]" controls poster="video-poster.jpg" autoPlay muted >
                        {/* <source src="http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4" type="video/mp4" /> */}
                        <source src="/video/LIBERTY_FINAL--1080 (source a).mp4" type="video/mp4" />
                        お使いのブラウザはビデオ再生に対応していません
                    </video>
                </Box>
            </Box>

        </ProductHeroLayout>
    );
}
