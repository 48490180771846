import { Box, Container, Paper, Typography } from "@mui/material";
import { useTranslateField } from "../appContext/AppContext";

export default function PagesTerms() {
    const translate = useTranslateField()
    return (
        <>
            <Container sx={{ pt: 4, pb: 8 }}>
                <Paper sx={{ p: { xs: 2, md: 4 } }}>
                    <Typography align="center" variant="h4">{translate(`Website "Liberty" Terms of Service`)}</Typography>
                    <Box sx={{ ml: 2 }}>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 1 (Application)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate("This agreement shall apply to all aspects of the use of this service between the user and the company.")}</Typography>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate('The company may establish additional rules, referred to as "specific provisions," in addition to this agreement, regarding the use of this service. These specific provisions, regardless of their names, shall constitute part of this agreement.')}</Typography>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate('If there is a conflict between the provisions of this agreement and the provisions of the specific provisions mentioned in the preceding article, unless otherwise specified in the specific provisions, the provisions of the specific provisions shall prevail.')}</Typography>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 2 (User Registration)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">1.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('In this service, a prospective user shall apply for user registration by agreeing to this agreement and following the method specified by the company. Upon approval by the company, the user registration will be completed.')}</Typography>
                                    <Typography sx={{ mt: 1 }} variant="body1">{translate('If the company determines that the applicant for user registration has the following reasons, the company may refuse the application for user registration, and shall not be obligated to disclose the reasons for such refusal.')}</Typography>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">{translate('①')}</Typography>
                                        <Typography variant="body1">{translate('If false information is provided during the application for user registration.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">{translate('②')}</Typography>
                                        <Typography variant="body1">{translate('If the application is made by someone who has violated this agreement.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">{translate('③')}</Typography>
                                        <Typography variant="body1">{translate('If the company determines that the user registration is not appropriate for any other reason.')}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 3 (Management of User ID and Password)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">1.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('If the company approves the user registration of the applicant in accordance with the provisions of the preceding article, the user shall set their own user ID.')}</Typography>
                                    <Typography sx={{ mt: 1 }} variant="body1">{translate('If the company determines that the applicant for user registration has the following reasons, the company may refuse the application for user registration, and shall not be obligated to disclose the reasons for such refusal.')}</Typography>
                                    <Typography sx={{ mt: 1 }} variant="body1">{translate('However, if the user ID the user intends to set is already in use or otherwise unavailable, they must set a different user ID.')}</Typography>
                                    <Typography sx={{ mt: 1 }} variant="body1">{translate('')}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">2.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('The user shall be responsible for managing their user ID and the password they have set for this service appropriately.')}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">3.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('The user is prohibited from transferring, lending, or sharing their user ID and password with any third party under any circumstances.')}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">4.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('If the combination of the user ID and password matches the registered information and a login is made, the company will consider it as the usage by the user who registered that user ID.')}</Typography>
                                    <Typography variant="body1">{translate('')}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">5.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('The company shall not be liable for any damages arising from the unauthorized use of user IDs and passwords by third parties, except in cases of intentional or gross negligence on the part of the company.')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 4 (Usage Fees and Payment Methods)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">1.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('Users who wish to use our paid services shall pay the usage fees as specified by the company and displayed on this website for the paid portion of the respective service. The payment shall be made through the method specified by the company.')}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">2.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('If the user delays the payment of usage fees, the user shall pay a late payment charge at an annual rate of 14.6%.')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 5 (Prohibited Activities)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography sx={{ mt: 2 }} variant="body1">{translate('Users are prohibited from engaging in the following activities when using this service.')}</Typography>
                            <Box sx={{ pl: 2 }}>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">①</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate('Actions that violate laws or public order and morals.')}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">②</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate('Actions related to criminal activities.')}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">③</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate('Actions that infringe upon the content of this service, including but not limited to copyrights, trademarks, and other intellectual property rights contained in this service.')}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">④</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate('Actions that disrupt or interfere with the functionality of the servers or networks of the company, other users, or any third party.')}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑤</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Actions that may disrupt the operation of the company's services.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑥</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Actions of unauthorized access or attempted unauthorized access to the website itself where this service is provided, or to third parties through this service.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑦</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Actions of collecting or accumulating personal information or any other data related to other users.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑧</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Actions of using this service for illicit purposes.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑨</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Actions that cause harm, damage, or discomfort to other users of this service or any other third parties.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑩</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Impersonating other users.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑪</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Engaging in advertising, promotion, solicitation, or business activities on this service through methods not authorized by the company.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑫</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Engaging in activities that directly or indirectly provide benefits to anti-social forces in relation to the company's services.`)}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                    <Typography sx={{ pr: 1 }} variant="body1">⑬</Typography>
                                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                                        <Typography variant="body1">{translate(`Other actions that the company deems inappropriate.`)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 6 (Suspension of Service Provision, etc.)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">1.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('If the company determines that any of the following reasons exist, it may suspend or interrupt the provision of all or part of this service without prior notice to the user.')}</Typography>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">①</Typography>
                                        <Typography variant="body1">{translate('When it is necessary to perform maintenance, inspection, or updates on the computer system related to this service.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">②</Typography>
                                        <Typography variant="body1">{translate('If the provision of this service becomes difficult due to force majeure events such as earthquakes, lightning, fires, power outages, or natural disasters.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">③</Typography>
                                        <Typography variant="body1">{translate('If the company determines that the user registration is not appropriate for any other reason.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">④</Typography>
                                        <Typography variant="body1">{translate('If the company determines that the provision of this service is difficult for any other reason.')}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">2.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate('The company shall not be held responsible for any disadvantages or damages incurred by the user or third parties as a result of the suspension or interruption of this service.')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 7 (Usage Restrictions and Account Cancellation)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">1.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`If the user falls under any of the following conditions, the company may, without prior notice, restrict the user's access to all or part of this service, or cancel the user's registration:`)}</Typography>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">①</Typography>
                                        <Typography variant="body1">{translate('If the user violates any provision of this agreement.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">②</Typography>
                                        <Typography variant="body1">{translate('If it is discovered that there are false facts in the registered information.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">③</Typography>
                                        <Typography variant="body1">{translate('If there is a failure to fulfill payment obligations, such as fees.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">④</Typography>
                                        <Typography variant="body1">{translate('If there is no response within two business days to communications from the company.')}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">⑤</Typography>
                                        <Typography variant="body1">{translate(`If there is no usage of this service for a certain period of time since the last usage.`)}</Typography>
                                    </Box>
                                    <Box sx={{ mt: 1, pl: 2, display: "flex" }}>
                                        <Typography sx={{ pr: 1 }} variant="body1">⑥</Typography>
                                        <Typography variant="body1">{translate(`If the company determines that the use of this service is not appropriate for any other reason.`)}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">2.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`The Company shall not be liable for any damage caused to the User due to the actions taken by the Company under this section.`)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 8 (Withdrawal)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate(`Users shall be able to withdraw from this service according to the withdrawal procedure specified by our company.`)}</Typography>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate(`Article 9 (Disclaimer and Limitation of Liability)`)}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">1.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`We do not explicitly or implicitly guarantee the absence of any defects (including but not limited to defects in safety, reliability, accuracy, completeness, effectiveness, suitability for a specific purpose, security, errors, bugs, or infringement of rights) in this service, either factually or legally.`)}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">2.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`We do not assume any liability for any damages incurred by users as a result of using this service. However, if the agreement between us and the user, including these terms and conditions, qualifies as a consumer contract under the Consumer Contract Act, this disclaimer provision does not apply.`)}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">3.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`Even in cases where the preceding provision applies, we shall not be held liable for any damages incurred by users due to our negligence (excluding gross negligence), including damages arising from special circumstances (including cases where we or the user could foresee or have foreseen the occurrence of damages). Furthermore, the liability for damages incurred by users due to our negligence (excluding gross negligence) in the event of non-performance of obligations or unlawful acts shall be limited to the amount of the usage fee received from the user in the month in which the damages occurred.`)}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">4.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`We do not assume any responsibility for transactions, communications, or disputes that may arise between users and other users or third parties in relation to this service.`)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate("Article 10 (Changes to Service Contents, etc.)")}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate(`We reserve the right to modify the content of this service or suspend the provision of this service without prior notice to users. We do not assume any responsibility for any damages incurred by users as a result of such modifications or suspensions.`)}</Typography>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate(`Article 11 (Changes to Terms of Use)`)}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate(`We reserve the right to modify this Agreement at any time in specific circumstances without prior notice to users.`)}</Typography>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate(`In addition, if you start using this service after changing this agreement, the user will be deemed to have agreed to the changed agreement.`)}</Typography>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate(`Article 12 (Handling of personal information)`)}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">1.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`The Company shall properly handle personal information acquired through the use of this service in accordance with the Company's "Personal Information Protection Regulations".`)}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", mt: 2, flexFlow: "row" }}>
                                <Typography sx={{ pr: 1 }} variant="body1">2.</Typography>
                                <Box sx={{ display: "flex", flexFlow: "column" }}>
                                    <Typography variant="body1">{translate(`We do not assume any liability for any damages incurred by users as a result of using this service. However, if the agreement between us and the user, including these terms and conditions, qualifies as a consumer contract under the Consumer Contract Act, this disclaimer provision does not apply.`)}</Typography>
                                    <Typography variant="body1">{translate(`Users agree in advance that, out of the information provided by the user to the Company when registering for the Liberty website, the information described in Contact Information will be published at the exhibition hall and on the Member list page on the Liberty website.`)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate(`Article 14 (Prohibition of Transfer of Rights and Obligations)`)}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate(`Users may not transfer or pledge their status under the terms of use or their rights or obligations under these Terms to a third party without the prior written consent of the Company.`)}</Typography>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate(`Article 15 (Governing Law)`)}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate(`The law of Japan shall be the governing law for the interpretation of these Terms.`)}</Typography>
                        </Box>
                        <Typography variant="h5" sx={{ mt: 4 }}>{translate(`Article 16 (Jurisdiction)`)}</Typography>
                        <Box sx={{ pl: 2 }}>
                            <Typography variant="body1" sx={{ mt: 2 }}>{translate(`In the event of a dispute between the Company and the User regarding the Service, the court having jurisdiction over the location of the Company's head office shall be the exclusive jurisdiction.`)}</Typography>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
}
