import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import noImageThumbnail from "../media/images/noimage.jpg";
import {
    Box,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    SxProps,
    Tooltip,
} from "@mui/material";
import { DoubleArrow, Pending } from "@mui/icons-material";
import { useState } from "react";
import { OfferResponse, OfferStatusInputEnum } from "../gql/graphql";
import { useLazyQuery } from "@apollo/client";
import { CreateFileLink } from "../utils/files/Files";
import { OfferStatusEnum } from "../enum/OfferEnum";
import { useNavigate } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import { useTranslateField } from "../appContext/AppContext";

export default function OfferCard({
    showThumbnail,
    showMenu,
    openDetailPageInAnotherTab,
    data,
    sx,
}: {
    showThumbnail?: boolean;
    showMenu?: boolean;
    openDetailPageInAnotherTab?: boolean;
    data?: OfferResponse;
    sx?: SxProps;
}) {
    if (!data) {
        return <></>;
    }

    console.log("data id=", data.id);
    console.log("data=", data);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();
    const handleMenuOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const onClickNavigate = (path: string) => () => {
        navigate(path);
    };

    const translate = useTranslateField();

    return (
        <Card sx={{ ...sx }}>
            {showThumbnail ? (
                <CardMedia
                    component="img"
                    alt="img"
                    height="140"
                    image={
                        data.files.length > 0
                            ? CreateFileLink(data.files[0].key)
                            : noImageThumbnail
                    }
                />
            ) : (
                <></>
            )}
            <CardContent>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Typography
                        sx={{
                            color: "secondary.main",
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        {translate(data.type.label.toUpperCase())}
                        <DoubleArrow sx={{ color: "secondary.main" }} />
                        {data.status ===
                            OfferStatusInputEnum.Closed && (
                            <>
                                <Typography
                                    component="span"
                                    sx={{ color: "error.main", ml: 0.5 }}
                                >
                                    {translate("CLOSED")}
                                </Typography>
                            </>
                        )}
                    </Typography>
                    {showMenu ? (
                        <Tooltip
                            title={translate("Menu")}
                            placement="left"
                            disableInteractive
                        >
                            <IconButton onClick={handleMenuOpen}>
                                <Pending />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleMenuClose}>{translate("Edit")}</MenuItem>
                        <MenuItem onClick={handleMenuClose}>{translate("Delete")}</MenuItem>
                    </Menu>
                </Box>
                <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        textDecoration:
                            data.status === OfferStatusInputEnum.Closed
                                ? "line-through"
                                : "",
                    }}
                >
                    {data.title}
                </Typography>
                <Divider />
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            columnGap: 2,
                        }}
                    >
                        <Typography sx={{ minWidth: "80px" }}>{translate("Maker")}</Typography>
                        <Typography
                            sx={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {data.maker ? data.maker : "-"}
                        </Typography>
                    </Box>
                    <Divider />
                </>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            columnGap: 2,
                        }}
                    >
                        <Typography sx={{ minWidth: "80px" }}>{translate("Model")}</Typography>
                        <Typography
                            sx={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {data.model ? data.model : "-"}
                        </Typography>
                    </Box>
                    <Divider />
                </>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            columnGap: 2,
                        }}
                    >
                        <Typography sx={{ minWidth: "80px" }}>{translate("Year")}</Typography>
                        <Typography
                            sx={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {data.year ? data.year : "-"}
                        </Typography>
                    </Box>
                    <Divider />
                </>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            columnGap: 2,
                        }}
                    >
                        <Typography sx={{ minWidth: "80px" }}>{translate("Size")}</Typography>
                        <Typography
                            sx={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {data.size ? data.size : "-"}
                        </Typography>
                    </Box>
                    <Divider />
                </>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            columnGap: 2,
                        }}
                    >
                        <Typography sx={{ minWidth: "80px" }}>
                            {translate("Country")}
                        </Typography>
                        <Typography
                            sx={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {data.country ? data.country.label : "-"}
                        </Typography>
                    </Box>
                    <Divider />
                </>
                <>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            columnGap: 2,
                        }}
                    >
                        <Typography sx={{ minWidth: "80px" }}>{translate("Price")}</Typography>
                        <Typography
                            sx={{
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {data.price && data.current
                                ? `${data.price} ${data.current}`
                                : translate("Negotiable")}
                        </Typography>
                    </Box>
                    <Divider />
                </>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
                {openDetailPageInAnotherTab ? (
                    <Button
                        component="a"
                        href={PageRecords.SearchDetail.createSearchDetailLink(data.id)}
                        target="_blank"
                        variant="outlined"
                        size="small"
                    >
                        {translate("Detail")}
                    </Button>
                ) : (
                    <Button
                        variant="outlined"
                        size="small"
                        onClick={onClickNavigate(PageRecords.SearchDetail.createSearchDetailLink(data.id))}
                    >
                        {translate("Detail")}
                    </Button>
                )}
            </CardActions>
        </Card>
    );
}
