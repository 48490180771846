import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import { SyntheticEvent, useEffect, useState } from "react";
import PageRecords from "../routes/PageRecords";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import { useNavigate } from "react-router-dom";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
    Bookmark,
    EditNote,
    Forum,
    Home,
    Login,
    Logout,
    Mail,
    Person,
} from "@mui/icons-material";
import {
    useAccessCount,
    useAccount,
    useLanguage,
    useTranslateField,
} from "../appContext/AppContext";
import { useMutation } from "@apollo/client";
import { LogOutDocument } from "../gql/graphql";
import { Message } from "./global/Message";
import PagesCategory from "../pages/PagesCategory";
import { Autocomplete, List, ListItem, TextField } from "@mui/material";

type Props = {
    defaultStyle: boolean;
};

const languages: LanguageType[] = [
    {
        label: "English",
        value: "en",
    },
    {
        label: "Japanese",
        value: "ja",
    },
    {
        label: "Chinese",
        value: "ch",
    },
    {
        label: "Korean",
        value: "ko",
    },
];
interface LanguageType {
    label: string;
    value: string;
}

function ResponsiveAppBar(props: Props) {
    const navigate = useNavigate();
    const translate = useTranslateField();
    const account = useAccount();
    const [logOut] = useMutation(LogOutDocument);
    const [isShowCategory, setIsShowCategory] = useState(false);

    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [language, setLaunguage] = useState<LanguageType>(languages[0]);
    const [globeLanguage, setGlobeLanguage] = useLanguage();
    const accessCount = useAccessCount();

    useEffect(() => {
        let entry = languages.find((v) => v.label === globeLanguage);
        if (entry) {
            setLaunguage(entry);
        } else {
            setLaunguage(languages[0]);
        }
    }, [globeLanguage]);

    const handleLanguage = (e: SyntheticEvent, newLanguage: LanguageType) => {
        let entry = languages.find((v) => v.value === newLanguage.value);
        if (entry) {
            setLaunguage(newLanguage);
            setGlobeLanguage(entry.label);
        }
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleNavMenuNavigate = (path: string) => () => {
        navigate(path);
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleUserMenuNavigate = (path: string) => () => {
        setAnchorElUser(null);
        navigate(path);
    };

    const OnLogOut = async () => {
        try {
            if (account) {
                await logOut({
                    variables: {
                        session: account.GetSession(),
                    },
                });
                account.LogOut();
                Message.Success("logout is successful");
            }
        } catch (error) {}
    };

    const pages = [
        { text: translate("Home"), path: PageRecords.Home.path },
        { text: translate("Search"), path: PageRecords.Search.path },
        { text: translate("Category"), path: "" },
        { text: translate("Post"), path: PageRecords.Post.path },
        { text: translate("Contact"), path: PageRecords.Contact.path },
    ];
    const settings = [
        // {
        //     text: translate("Account home"),
        //     path: PageRecords.Account.path,
        //     icon: <Home />,
        // },
        {
            text: translate("Profile"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.Profile.path,
            icon: <Person />,
        },
        {
            text: translate("Message"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.Message.path,
            icon: <Forum />,
        },
        {
            text: translate("Edit post"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.EditPost.path,
            icon: <EditNote />,
        },
        {
            text: translate("Bookmark"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.Bookmark.path,
            icon: <Bookmark />,
        },
        {
            text: translate("Mail setting"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.MailSetting.path,
            icon: <Mail />,
        },
        {
            text: translate("Analysis"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.Analysis.path,
            icon: <SignalCellularAltIcon />,
            mustManager: true,
        },
        {
            text: translate("Mail manager"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.MailManager.path,
            icon: <ContactMailIcon />,
            mustManager: true,
        },
        {
            text: translate("Account manager"),
            path:
                PageRecords.Account.path +
                PageRecords.Account.children.AccountManager.path,
            icon: <SupervisorAccountIcon />,
            mustManager: true,
        },
        {
            text: translate("Logout"),
            path: PageRecords.Home.path,
            icon: <Logout />,
            callback: OnLogOut,
        },
    ];

    return (
        <AppBar
            sx={{
                ...{ position: "sticky", zIndex: 9999, minWidth: "320px" },
                ...(props.defaultStyle
                    ? {
                          backgroundColor: "secondary.main",
                          position: "sticky",
                      }
                    : {
                          //   mixBlendMode: "difference",
                          //   backgroundColor: "transparent",
                          backgroundColor: "secondary.main",
                          //   position: "fixed",
                      }),
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters sx={{ height: 70 }}>
                    <SettingsIcon
                        sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
                    />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        onClick={() => {
                            navigate(PageRecords.Home.path);
                        }}
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                            cursor: "pointer",
                        }}
                    >
                        {translate("Liberty")}
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={`${page.text}-menu`}
                                    onClick={handleNavMenuNavigate(page.path)}
                                >
                                    <Typography
                                        component="a"
                                        textAlign="center"
                                    >
                                        {page.text}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <SettingsIcon
                        sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}
                    />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        onClick={() => navigate(PageRecords.Home.path)}
                        sx={{
                            mr: 2,
                            display: { xs: "flex", md: "none" },
                            flexGrow: 1,
                            fontFamily: "monospace",
                            fontWeight: 700,
                            letterSpacing: ".3rem",
                            color: "inherit",
                            textDecoration: "none",
                            cursor: "pointer",
                            m: 0,
                        }}
                    >
                        {translate("Liberty")}
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.text}
                                component="a"
                                onClick={() => {
                                    if (page.path.length > 0) {
                                        setIsShowCategory(false);
                                        navigate(page.path);
                                    } else {
                                        setIsShowCategory(!isShowCategory);
                                    }
                                }}
                                sx={{
                                    my: 2,
                                    color: "#fff",
                                    display: "block",
                                    textAlign: "center",
                                }}
                            >
                                {page.text}
                            </Button>
                        ))}
                    </Box>
                    {accessCount && (
                        <Box>
                            {accessCount} {translate("visitors")}
                        </Box>
                    )}
                    <Box sx={{ width: "8rem" }}>
                        <List disablePadding>
                            <ListItem>
                                <Autocomplete
                                    fullWidth
                                    options={languages.map((language) => {
                                        return {
                                            label: translate(language.label),
                                            value: language.value,
                                        } as LanguageType;
                                    })}
                                    value={{
                                        label: translate(language.label),
                                        value: language.value,
                                    }}
                                    disableClearable
                                    getOptionLabel={(option: LanguageType) =>
                                        option.label
                                    }
                                    onChange={handleLanguage}
                                    isOptionEqualToValue={(option, value) =>
                                        option.value === value.value
                                    }
                                    sx={{
                                        "& .MuiAutocomplete-endAdornment": {},
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            sx={{
                                                "& .MuiInputBase-root": {
                                                    pr: "1rem !important",
                                                },
                                                "& .MuiInput-root": {
                                                    "& .MuiInput-input": {
                                                        p: "0rem",
                                                        textAlign: "center",
                                                    },
                                                    color: "#fff",
                                                    ":hover": {
                                                        ":not(.Mui-disabled, .Mui-error)": {
                                                            ":before": {
                                                                borderColor:
                                                                    "#fff",
                                                            },
                                                        },
                                                    },
                                                    ":after": {
                                                        borderColor: "#fff",
                                                    },
                                                    ":before": {
                                                        borderColor: "#fff",
                                                        border: "none",
                                                    },
                                                },
                                                "& .MuiSvgIcon-root": {
                                                    alignItems: "center",
                                                    color: "#fff",
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </ListItem>
                        </List>
                    </Box>
                    {account && account.GetSession().length > 0 ? (
                        <Box sx={{ flexGrow: 0, display: "flex" }}>
                            <Box>
                                <Tooltip title={translate("Account")}>
                                    <IconButton
                                        onClick={handleOpenUserMenu}
                                        sx={{ p: 0 }}
                                    >
                                        <Avatar alt="Remy Sharp" />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: "45px" }}
                                    id="menu-appbar"
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {settings.map((setting) => {
                                        if (setting.mustManager) {
                                            if (!account.IsManager()) {
                                                return;
                                            }
                                        }
                                        return (
                                            <MenuItem
                                                key={setting.text}
                                                onClick={async () => {
                                                    setting.callback &&
                                                        (await setting.callback());
                                                    console.log(
                                                        "handleUserMenuNavigate start"
                                                    );
                                                    setAnchorElUser(null);
                                                    navigate(setting.path);
                                                }}
                                            >
                                                <Typography
                                                    textAlign="center"
                                                    component="a"
                                                >
                                                    {setting.text}
                                                </Typography>
                                            </MenuItem>
                                        );
                                    })}
                                </Menu>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            <Box
                                sx={{
                                    gap: 1,
                                    display: { xs: "none", md: "flex" },
                                }}
                            >
                                <Button
                                    onClick={() =>
                                        navigate(PageRecords.SignIn.path)
                                    }
                                    sx={{
                                        color: "#fff",
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    {translate("Sign In")}
                                </Button>
                                <Button
                                    onClick={() =>
                                        navigate(PageRecords.SignUp.path)
                                    }
                                    sx={{
                                        color: "#fff",
                                        display: "block",
                                        textAlign: "center",
                                    }}
                                >
                                    {translate("Sign Up")}
                                </Button>
                            </Box>
                            <IconButton
                                size="large"
                                color="inherit"
                                sx={{
                                    display: { xs: "flex", md: "none" },
                                }}
                            >
                                <Login />
                            </IconButton>
                        </>
                    )}
                </Toolbar>
            </Container>
            {isShowCategory ? (
                <PagesCategory
                    onClose={() => {
                        setIsShowCategory(false);
                    }}
                />
            ) : (
                <></>
            )}
        </AppBar>
    );
}
export default ResponsiveAppBar;
