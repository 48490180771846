import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { BaseDataLib, BaseDataObj } from "./BaseDataLib";
import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    useLazyQuery,
    useQuery,
} from "@apollo/client";
import { Graphs, GraphsType } from "./Graphs";
import { client } from "./Client";
import { BaseInfoDocument, User } from "../gql/graphql";
import { useNavigation } from "react-router-dom";
import { Account } from "./Account";
import {
    useAsyncEffect,
    useStateWithLocalStorage,
} from "../utils/ReactHelpers";
import { Language } from "@mui/icons-material";
import { Config, PRODUCT_ENVIROMENT } from "./../config/config";
import { GetLocalData, SetLocalData } from "../utils/localStroage";

interface AppContextInterface {
    baseDataLib: BaseDataLib;
    graphqls: GraphsType;
    account: Account;
    activeLanguage: string;
    setActiveLanguage(language: string): void;
}

let accountObj = new Account("", undefined);
let baseDataObj = new BaseDataObj();

// Contextを作成
export const AppContext = createContext<AppContextInterface | undefined>(
    undefined
);

const activeLanguageKey = "activeLanguage";

// Providerを作成
export const AppProvider = (prop: { children?: any }) => {
    const [activeLanguage, setActiveLanguage] = useState("");
    const [account] = useState<Account>(accountObj);
    const [callBaseInfo] = useLazyQuery(BaseInfoDocument);
    const [baseDataLib] = useState(new BaseDataLib(baseDataObj, callBaseInfo));

    if (Config.environment === PRODUCT_ENVIROMENT) {
        console.log = function() {};
    }

    account.GetSession();
    account.GetUser();
    useAsyncEffect(async () => {
        await baseDataLib.Init();
        let languageKeyStr = GetLocalData(activeLanguageKey);
        if (languageKeyStr) {
            setActiveLanguage(languageKeyStr);
        } else {
            setActiveLanguage("English");
        }
    }, []);

    return (
        <AppContext.Provider
            value={{
                baseDataLib: baseDataLib,
                account: account,
                graphqls: Graphs,
                activeLanguage: activeLanguage,
                setActiveLanguage: setActiveLanguage,
            }}
        >
            {prop.children}
        </AppContext.Provider>
    );
};

export function useTranslateField() {
    const context = useContext(AppContext);
    return function(key: string): string {
        if (context) {
            const langList = context.baseDataLib.GetLanguageData();
            if (langList) {
                if (langList[key]) {
                    return langList[key][context.activeLanguage]
                        ? langList[key][context.activeLanguage]
                        : key;
                }
            }
        }
        return key;
    };
}

export function useLangList() {
    const context = useContext(AppContext);
    if (context) {
        return context.baseDataLib.GetLanguage();
    }
    return [];
}

export function useCurrencies() {
    const context = useContext(AppContext);
    if (context) {
        return context.baseDataLib.GetCurrencies();
    }
    return [];
}

export function useCountries() {
    const context = useContext(AppContext);
    if (context) {
        return context.baseDataLib.GetCountries();
    }
    return [];
}

export function usePostTypes() {
    const context = useContext(AppContext);
    if (context) {
        return context.baseDataLib.GetPostTypes();
    }
    return [];
}

export function useProductTypes() {
    const context = useContext(AppContext);
    if (context) {
        return context.baseDataLib.GetProductTypes();
    }
    return [];
}

export function useCategories() {
    const context = useContext(AppContext);
    if (context) {
        return context.baseDataLib.GetCategories();
    }
    return [];
}

export function useConcactSubjects() {
    const context = useContext(AppContext);
    if (context) {
        return context.baseDataLib.GetContactSubjects();
    }
    return [];
}

export function useIsLogin(): boolean {
    const context = useContext(AppContext);
    if (context) {
        return context.account.IsLogin();
    }
    return false;
}

type LanguageState = [string, (language: string) => void];

export function useLanguage(): LanguageState {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error("context is undefined");
    }
    return [
        context.activeLanguage,
        (language: string) => {
            console.log("language=", language);
            SetLocalData(activeLanguageKey, language);
            console.log("language2222");
            context.setActiveLanguage(language);
        },
    ];
}

export function useAccount() {
    const context = useContext(AppContext);
    if (context) {
        return context.account;
    }
    return undefined;
}

export function useAccessCount() {
    const context = useContext(AppContext);
    if (context) {
        let account = context.baseDataLib.GetAccessCount();
        if (!account || account.length === 0) {
            return undefined;
        }
        return account;
    }
    return undefined;
}

// Consumerを作成
export const AppConsumer = AppContext.Consumer;
