import {
    styled,
    useTheme,
    Theme,
    CSSObject,
    css,
    SxProps,
} from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import {
    Bookmark,
    EditNote,
    Forum,
    Home,
    Logout,
    Mail,
    Person,
    PersonOff,
} from "@mui/icons-material";
import { ChangeEvent, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from "@mui/material";
import Footer from "../components/Footer";
import { useAccount, useTranslateField } from "../appContext/AppContext";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
    [theme.breakpoints.up("xs")]: {
        width: "100vw",
    },
    [theme.breakpoints.up("md")]: {
        width: drawerWidth,
    },
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export default function PagesAccountLayout() {
    // メニュー開閉の管理
    const [open, setOpen] = useState(false);
    const handleDrawer = () => {
        setOpen(!open);
    };
    const navigate = useNavigate();

    const account = useAccount()
    if (!account){
        navigate(PageRecords.SignIn.path)
    }

    // <--start-- アカウント削除ダイアログ用の変数・関数
    const [openDeleteAccountDialog, setOpenDeleteAccountDialog] = useState(
        false
    );
    const [password, setPassword] = useState<string>("");
    const onClickDeleteAccountButton = () => {
        setOpenDeleteAccountDialog(true);
    };
    const onClickCloseDialog = () => {
        setOpenDeleteAccountDialog(false);
        setPassword("");
    };
    const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
        setPassword(e.target.value);
    };
    const onClickApproveDeletion = () => { };
    // --end--> アカウント削除ダイアログ用の変数・関数

    const onClickSignOutButton = () => {
        navigate(PageRecords.Home.path);
    };

    const { pathname } = useLocation();
    const currentPageIconColor = (path: string): SxProps => {
        return pathname === path
            ? { color: "secondary.main" }
            : { color: "text.secondary" };
    };

    const translate = useTranslateField();

    return (
        <>
            <Drawer
                variant="permanent"
                open={open}
                sx={{
                    "& .MuiDrawer-paperAnchorLeft": {
                        // header's height is 70px (ResponsiveAppBar.tsx => Toolbar)
                        pt: "70px",
                    },
                }}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawer}>
                        {open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {[
                        // {
                        //     content: translate("Home"),
                        //     icon: (
                        //         <Home
                        //             sx={currentPageIconColor(
                        //                 PageRecords.Account.path
                        //             )}
                        //         />
                        //     ),
                        //     to: PageRecords.Account.children.Home.path,
                        // },
                        {
                            content: translate("Profile"),
                            icon: (
                                <Person
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children.Profile
                                            .path
                                    )}
                                />
                            ),
                            to: PageRecords.Account.children.Profile.path,
                        },
                        {
                            content: translate("Message"),
                            icon: (
                                <Forum
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children.Message
                                            .path
                                    )}
                                />
                            ),
                            to: PageRecords.Account.children.Message.path,
                        },
                        {
                            content: translate("Edit post"),
                            icon: (
                                <EditNote
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children
                                            .EditPost.path
                                    )}
                                />
                            ),
                            to: PageRecords.Account.children.EditPost.path,
                        },
                        {
                            content: translate("Bookmark"),
                            icon: (
                                <Bookmark
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children
                                            .Bookmark.path
                                    )}
                                />
                            ),
                            to: PageRecords.Account.children.Bookmark.path,
                        },
                        {
                            content: translate("Mail setting"),
                            icon: (
                                <Mail
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children
                                            .MailSetting.path
                                    )}
                                />
                            ),
                            to: PageRecords.Account.children.MailSetting.path,
                        },
                        {
                            content: translate("Analysis"),
                            icon: (
                                <SignalCellularAltIcon
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children
                                            .Analysis.path
                                    )}
                                />
                            ),
                            mustManagerPage: true,
                            to: PageRecords.Account.children.Analysis.path,
                        },
                        {
                            content: translate("Mail manager"),
                            icon: (
                                <ContactMailIcon
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children
                                            .MailManager.path
                                    )}
                                />
                            ),
                            mustManagerPage: true,
                            to: PageRecords.Account.children.MailManager.path,
                        },
                        {
                            content: translate("Account manager"),
                            icon: (
                                <SupervisorAccountIcon
                                    sx={currentPageIconColor(
                                        PageRecords.Account.path +
                                        PageRecords.Account.children
                                            .AccountManager.path
                                    )}
                                />
                            ),
                            mustManagerPage: true,
                            to: PageRecords.Account.children.AccountManager.path,
                        },
                    ].map((item) => {
                        if (item.mustManagerPage) {
                            if (!account?.IsManager()){
                                return 
                            }
                        }
                        return (
                            <ListItem
                                key={item.content}
                                disablePadding
                                sx={{ display: "block" }}
                            >
                                <Link
                                    to={PageRecords.Account.path + item.to}
                                    css={css`
                                    color: #000;
                                    text-decoration: none;
                                `}
                                >
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open
                                                ? "initial"
                                                : "center",
                                            px: 2.5,
                                        }}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : "auto",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {item.icon}
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.content}
                                            sx={{ opacity: open ? 1 : 0 }}
                                        />
                                    </ListItemButton>
                                </Link>
                            </ListItem>
                        )
                    })}
                </List>
                <Divider />
                <List>
                    {[
                        {
                            content: translate("Delete Account"),
                            icon: <PersonOff />,
                            onClick: onClickDeleteAccountButton,
                        },
                        {
                            content: translate("Sign out"),
                            icon: <Logout />,
                            onClick: onClickSignOutButton,
                        },
                    ].map((item) => (
                        <ListItem
                            key={item.content}
                            disablePadding
                            sx={{ display: "block" }}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                                onClick={item.onClick}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={item.content}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    pl: {
                        xs: "57px",
                        sm: "65px",
                    },
                }}
            >
                <Container
                    // header's height is 70px (ResponsiveAppBar.tsx => Toolbar)
                    sx={{ py: 4, minHeight: "calc(100vh - 70px)" }}
                >
                    <Outlet />
                </Container>
                <Footer forceDisplay />
            </Box>
            <Dialog open={openDeleteAccountDialog} onClose={onClickCloseDialog}>
                <DialogTitle>{translate("Delete Account ?")}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {translate("To delete your account, please enter your confirmation password below. By clicking the 'Delete' button, you confirm that you understand the consequences of this action and wish to proceed with the account deletion. If you have any concerns or questions, please contact our support team for assistance. We're sorry to see you go and thank you for being a part of our community.")}
                    </DialogContentText>
                    <TextField
                        variant="standard"
                        label={translate("Password")}
                        value={password}
                        onChange={onChangePassword}
                        fullWidth
                        sx={{ mt: 2 }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={onClickCloseDialog}>
                        {translate("Cancel")}
                    </Button>
                    <Button
                        color="error"
                        onClick={onClickApproveDeletion}
                        disabled={password.length === 0}
                    >
                        {translate("Delete")}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
