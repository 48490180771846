const PUBLIC_URL = process.env.PUBLIC_URL;
const staticContentUrl = `${PUBLIC_URL}/`;

type Sponsor = {
  homepageUrl: string;
  logoUrl: string;
};
const BASE_URL = `${staticContentUrl}images/sponsorLogos/`;

const Sponsors: Sponsor[] = [
  {
    homepageUrl: "http://www.newinte.com/",
    logoUrl: `${BASE_URL}logo_NIT.jpg`,
  },
  {
    homepageUrl:
      "https://jpn.pioneer/ja/corp/group/tohokupioneer/exhibition/oled/",
    logoUrl: `${BASE_URL}logo_pioneer.png`,
  },
  // {
  //   homepageUrl: "http://elastic-hjc.com/",
  //   logoUrl: `${BASE_URL}elastic_square.png`,
  // },
  {
    homepageUrl: "http://www.ch.com.cn",
    logoUrl: `${BASE_URL}logo_cec.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_sansyou.jpg`,
  },
  {
    homepageUrl: "http://www.bjtstd.com.cn/",
    logoUrl: `${BASE_URL}logo_bjtstd.jpg`,
  },
  {
    homepageUrl: "http://www.sinotrans.com",
    logoUrl: `${BASE_URL}logo_sinotrans.png`,
  },
  {
    homepageUrl: "http://www.yes-lcd.com",
    logoUrl: `${BASE_URL}logo_yes.jpg`,
  },
  {
    homepageUrl: "http://dowell-reuse.com",
    logoUrl: `${BASE_URL}logo_dowell.jpg`,
  },
  {
    homepageUrl: "http://www.kdragon.co.jp/",
    logoUrl: `${BASE_URL}logo_kdragon.jpg`,
  },
  {
    homepageUrl: "http://rgbstudio.hu/",
    logoUrl: `${BASE_URL}logo_rgbstudio.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_bootbit.jpg`,
  },
  {
    homepageUrl: "http://www.austinelec.com/",
    logoUrl: `${BASE_URL}logo_austin.png`,
  },
  {
    homepageUrl: "http://truvice.jp",
    logoUrl: `${BASE_URL}logo_truvice.png`,
  },
  {
    homepageUrl: "http://en.rs-machining.com",
    logoUrl: `${BASE_URL}logo_advancel.jpg`,
  },
  {
    homepageUrl: "https://shanghai-sotec.en.alibaba.com/",
    logoUrl: `${BASE_URL}logo_sotec.png`,
  },
  {
    homepageUrl: "http://www.szhft.com",
    logoUrl: `${BASE_URL}logo_szhft.jpg`,
  },
  {
    homepageUrl: "http://www.ckdseiki.com/",
    logoUrl: `${BASE_URL}logo_sckdpme.jpg`,
  },
  {
    homepageUrl: "https://workingvisa-kanagawa.com/",
    logoUrl: `${BASE_URL}logo_working_visa_kanagawa.jpg`,
  },
  {
    homepageUrl: "http://www.holokook.com/",
    logoUrl: `${BASE_URL}logo_holokook.png`,
  },
  {
    homepageUrl: "http://www.hscnk.com.cn/",
    logoUrl: `${BASE_URL}logo_csx.png`,
  },
  {
    homepageUrl: "http://tekno.co.jp//",
    logoUrl: `${BASE_URL}logo_ntc.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_ast.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_hennda.png`,
  },
  {
    homepageUrl: "http://www.jlyhm.com/",
    logoUrl: `${BASE_URL}logo_jlyhm.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_guanju_machinery.png`,
  },
  {
    homepageUrl: "http://www.qrtkr.com",
    logoUrl: `${BASE_URL}logo_qrt.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_huaqi.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_fullnano.png`,
  },
  {
    homepageUrl: "http://www.bjmars.cn/",
    logoUrl: `${BASE_URL}logo_mars.png`,
  },
  {
    homepageUrl: "http://www.ohlab.jp",
    logoUrl: `${BASE_URL}logo_ohlab.png`,
  },
  {
    homepageUrl: "http://www.tk-top.com",
    logoUrl: `${BASE_URL}logo_thinking_top.jpg`,
  },
  {
    homepageUrl: "http://www.yeolight.com",
    logoUrl: `${BASE_URL}logo_yeolight.png`,
  },
  {
    homepageUrl: "http://www.expower.co.kr/",
    logoUrl: `${BASE_URL}logo_expower.png`,
  },
  {
    homepageUrl: "http://www.astkk.com/",
    logoUrl: `${BASE_URL}logo_astkk.jpg`,
  },
  {
    homepageUrl: "http://www.szhk.com.cn/",
    logoUrl: `${BASE_URL}logo_hkc.png`,
  },
  {
    homepageUrl: "http://www.compoundsemiconductorchina.net/",
    logoUrl: `${BASE_URL}logo_cs.jpg`,
  },
  {
    homepageUrl: "https://www.ssm-eng.co.jp",
    logoUrl: `${BASE_URL}logo_ssm.png`,
  },
  {
    homepageUrl: "https://www.chtf.com/",
    logoUrl: `${BASE_URL}logo_hua_jia.jpg`,
  },
  {
    homepageUrl: "http://www.ezidonegroup.com/",
    logoUrl: `${BASE_URL}logo_ez_done.png`,
  },
  {
    homepageUrl: "http://www.chinafpd.net/sdia/",
    logoUrl: `${BASE_URL}logo_sdia.png`,
  },
  {
    homepageUrl: "https://www.meidensha.co.jp/npi/index.html",
    logoUrl: `${BASE_URL}logo_meiden.png`,
  },
  {
    homepageUrl: "http://www.co-tech.co.kr",
    logoUrl: `${BASE_URL}logo_cotech.png`,
  },
  {
    homepageUrl: "",
    logoUrl: `${BASE_URL}logo_share.png`,
  },
  {
    homepageUrl: "https://mta-tokyo.jp/",
    logoUrl: `${BASE_URL}logo_mta.png`,
  },
  {
    homepageUrl: "https://www.hardolass.com/",
    logoUrl: `${BASE_URL}logo_hardlass.png`,
  },
  {
    homepageUrl: "https://www.jsw.co.jp/ja/index.html",
    logoUrl: `${BASE_URL}logo_jsw.png`,
  },
  {
    homepageUrl: "http://ertv321.cn.b2b168.com/",
    logoUrl: `${BASE_URL}logo_shenzenCityZhiainiTechnologyCoLtd.png`,
  },
  {
    homepageUrl: "http://www.uban.net.cn",
    logoUrl: `${BASE_URL}logo_uban.png`,
  },
  {
    homepageUrl: "http://www.xmymjx.com/",
    logoUrl: `${BASE_URL}logo_yiming.png`,
  },
  {
    homepageUrl: "http://hum3d.com/",
    logoUrl: `${BASE_URL}logo_hum3d.jpg`,
  },
  {
    homepageUrl: "https://www.aliexpress.com/store/912401908",
    logoUrl: `${BASE_URL}logo_onlyone.png`,
  },
  {
    homepageUrl: "http://www.jilongcn.com",
    logoUrl: `${BASE_URL}logo_jilong.jpg`,
  },
  {
    homepageUrl: "https://www.suntique.co.kr",
    logoUrl: `${BASE_URL}logo_suntique.jpg`,
  },
  {
    homepageUrl: "https://smartstore.naver.com/rainworx",
    logoUrl: `${BASE_URL}logo_smartstore.jpg`,
  },
  {
    homepageUrl: "https://tks-llc.jp/",
    logoUrl: `${BASE_URL}logo_tks.jpg`,
  },
  {
    homepageUrl: "https://www.sungreatinverter.com/products.asp",
    logoUrl: `${BASE_URL}senda_drive.png`,
  },
  {
    homepageUrl: "https://www.sungreatinverter.com/products.asp",
    logoUrl: `${BASE_URL}DOAGE.png`,
  },
];

export default Sponsors;
