const localStorageBaseKey = "a8L7x3DXhnrKDmW8";
export function SetLocalData(key: string, value: any) {
    console.log("setLocalData", key, value);
    window.localStorage.setItem(
        localStorageBaseKey + key,
        JSON.stringify(value)
    );
}

export function GetLocalData(key: string): any {
    let value = window.localStorage.getItem(localStorageBaseKey + key);
    return value ? JSON.parse(value) : undefined;
}

export function RemoveLocalData(key: string) {
    window.localStorage.removeItem(localStorageBaseKey + key);
}
