import { Box, Divider, Typography } from "@mui/material";
import React, { useRef, useEffect, useState } from "react";

export function PdfHeader({ title }: { title: string }) {
    const elementRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (elementRef.current) {
            const element = elementRef.current;
            console.log(element);

            if (element.scrollWidth > element.clientWidth) {
                let truncatedText = title;
                while (element.scrollWidth > element.clientWidth && truncatedText.length > 0) {
                    console.log("element.scrollWidth=", element.scrollWidth, "element.clientWidth=", element.clientWidth);
                    truncatedText = truncatedText.slice(0, -1);
                    element.textContent = truncatedText;
                }
                if (truncatedText.length < title.length) {
                    element.textContent = truncatedText.slice(0, -3) + "...";
                }
            }
        }
    }, [title]);

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                boxSizing: "border-box",
                width: "100%",
                height: "19mm",
            }}>
            <div
                ref={elementRef}
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    padding: "0 1em",
                    boxSizing: "border-box",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    fontSize: "6mm",
                    fontWeight: "bold",
                }}>
                {title}
            </div>
            <div
                style={{
                    width: "100%",
                    borderBottom: "1mm",
                    borderStyle: "solid",
                    borderColor: "#FF0000",
                }}
            />
            <div
                style={{
                    width: "100%",
                    borderBottom: "1mm",
                    borderStyle: "solid",
                    borderColor: "#008000",
                    marginTop: "1mm",
                }}
            />
        </div>
    );
}
