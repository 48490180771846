import { Box, Breadcrumbs, Button, ButtonBase, Card, Container, Grid, Link, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import PageRecords from "../routes/PageRecords";
import { useLocation, useNavigate } from "react-router-dom";
import theme from "../theme";
import { usePostTypes, useProductTypes, useTranslateField } from "../appContext/AppContext";
import { CategoryListDocument, CategoryRecord, OfferFilterInput } from "../gql/graphql";
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { SetLocalData } from "../utils/localStroage";
import { Helmet } from "react-helmet-async";

export default function PagesCategory({ onClose }: { onClose?: () => void }) {
    const [categories, setCategories] = useState<Array<CategoryRecord>>([]);
    const [categoryListCall] = useLazyQuery(CategoryListDocument);
    const [activeCategoryRecords, setActiveCategoryRecords] = useState<Array<CategoryRecord>>([]);
    const navigate = useNavigate();
    const translate = useTranslateField();
    const productTypes = useProductTypes();
    const postTypes = usePostTypes();
    const location = useLocation();
    useAsyncEffect(async () => {
        let parentId = undefined;
        if (activeCategoryRecords.length > 0) {
            parentId = activeCategoryRecords[activeCategoryRecords.length - 1].value;
        }

        let response = await categoryListCall({
            variables: {
                parentId: parentId,
            },
        });

        if (response.data) {
            if (response.data.CategoryList.length > 0) {
                setCategories(response.data.CategoryList);
            } else {
                let filter: OfferFilterInput = {
                    category: activeCategoryRecords[activeCategoryRecords.length - 1].value,
                    type: postTypes.map((v) => v.value),
                    onlyExistFile: false,
                    productType: productTypes.map((v) => v.value),
                    keyword: "",
                    current: null,
                };
                onClose && onClose();
                SetLocalData("SearchFilter", JSON.stringify(filter));
                if (location.pathname === PageRecords.Search.path) {
                    window.location.reload();
                } else {
                    navigate(PageRecords.Search.path);
                }
            }
        }
        console.log("response categories=", response);
    }, [activeCategoryRecords]);

    return (
        <Container
            className=" m-auto top-20 left-0 right-0 bottom-20 fixed flex flex-col rounded text-black border-2 bg-white border-black border-solid"
            sx={{ pt: 4, pb: 8 }}>
            <Helmet>
                <title>{"Liberty | " + translate("Category")}</title>
            </Helmet>
            <Box>
                <Grid sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    <Typography variant="h4">{translate("Category")}</Typography>
                    <Button variant="contained" onClick={() => onClose && onClose()}>
                        {translate("Close")}
                    </Button>
                </Grid>
                <Typography mt={1}>
                    {translate(
                        "Explore our comprehensive list of product categories. Simply click on a button with the category name to jump to a search page specifically tailored to that category. "
                    )}
                </Typography>
                <Breadcrumbs sx={{ mt: 1 }} separator="›" aria-label="activeCategory">
                    {activeCategoryRecords.map((v) => (
                        <Link
                            key={"activeCategoryRecords-" + v.value}
                            onClick={() => {
                                let tmpArr: Array<CategoryRecord> = [];
                                console.log("activeCategoryRecords = ", activeCategoryRecords);
                                for (let i = 0; i < activeCategoryRecords.length; i++) {
                                    tmpArr.push(activeCategoryRecords[i]);
                                    console.log("active=", v);
                                    console.log("i=", i);
                                    if (activeCategoryRecords[i].value === v.value) {
                                        break;
                                    }
                                }
                                console.log("tmpArr=", tmpArr);
                                setActiveCategoryRecords([...tmpArr]);
                            }}
                            underline="hover"
                            color="inherit"
                            href="#">
                            {v.label}
                        </Link>
                    ))}
                </Breadcrumbs>
            </Box>
            <Box mt={4}>
                <Grid container rowSpacing={3} columnSpacing={2}>
                    {activeCategoryRecords.length === 0 && (
                        <Grid item xs={6} sm={4} md={3} flexWrap="wrap">
                            <Card>
                                <ButtonBase
                                    sx={{
                                        p: 2,
                                        width: "100%",
                                    }}
                                    onClick={async () => {
                                        setActiveCategoryRecords([]);
                                        let filter: OfferFilterInput = {
                                            type: postTypes.map((v) => v.value),
                                            onlyExistFile: false,
                                            productType: productTypes.map((v) => v.value),
                                            keyword: "",
                                            current: null,
                                        };
                                        onClose && onClose();
                                        SetLocalData("SearchFilter", JSON.stringify(filter));
                                        if (location.pathname === PageRecords.Search.path) {
                                            window.location.reload();
                                        } else {
                                            navigate(PageRecords.Search.path);
                                        }
                                    }}>
                                    <Typography
                                        variant="body2"
                                        className=" font-bold"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            wordBreak: "break-all",
                                            color: theme.palette.primary.light,
                                        }}>
                                        {translate("All")}
                                        <ChevronRight />
                                    </Typography>
                                </ButtonBase>
                            </Card>
                        </Grid>
                    )}
                    {categories.slice(0, 10).map((category, i) => {
                        return (
                            <>
                                <Grid key={"categories-" + category.value} item xs={6} sm={4} md={3} flexWrap="wrap">
                                    <Card>
                                        <ButtonBase
                                            sx={{
                                                p: 2,
                                                width: "100%",
                                            }}
                                            onClick={async () => {
                                                let newCategory = [...activeCategoryRecords];
                                                newCategory.push(category);
                                                setActiveCategoryRecords(newCategory);
                                            }}>
                                            <Typography
                                                variant="body2"
                                                className=" font-bold"
                                                sx={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    wordBreak: "break-all",
                                                    color: theme.palette.primary.light,
                                                }}>
                                                {category.label}
                                                <ChevronRight />
                                            </Typography>
                                        </ButtonBase>
                                    </Card>
                                </Grid>
                            </>
                        );
                    })}
                </Grid>
            </Box>
        </Container>
    );
}
