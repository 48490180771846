import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { useTranslateField } from "../appContext/AppContext";

export default function PagesPrivacy() {
    let translate = useTranslateField()
    return (
        <>
            <Container sx={{ pt: 4, pb: 8 }}>
                <Paper sx={{ p: { xs: 2, md: 4 } }}>
                    <Typography align="center" variant="h4">{translate(`Personal information protection regulations`)}</Typography>
                    <Box sx={{ display: "flex", flexFlow: "column" }}>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 1 Purpose of these regulations`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`These regulations are basic regulations for the appropriate use and protection of personal information handled by Elastic LLC (hereinafter referred to as "our company") based on our personal and corporate information protection policy.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 2 (Definition)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Box>
                                    <Typography variant="body1">{translate(`Terms used in these regulations are defined in the following relevant items.`)}</Typography>
                                </Box>
                                <Grid container sx={{ mt: 2 }} >
                                    <Grid xs={4} md={3}>
                                        <Box sx={{ display: "flex" }}>
                                            <Typography variant="subtitle1" sx={{ mr: .5 }}>(1)</Typography>
                                            <Typography variant="subtitle1">{translate(`Personal information`)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={8} md={9}>
                                        <Typography variant="body1">{translate(`Information about living individuals or existing corporations, including names, dates of birth or establishment dates, or other descriptions, numbers, symbols or other codes assigned to individuals or corporations, images or sounds Information that can identify the individual or corporation (including information that cannot be identified by the information alone, but can be easily compared with other information to identify the individual).`)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }} >
                                    <Grid xs={4} md={3}>
                                        <Box sx={{ display: "flex" }}>
                                            <Typography variant="subtitle1" sx={{ mr: .5 }}>(2)</Typography>
                                            <Typography variant="subtitle1">{translate(`Person`)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={8} md={9}>
                                        <Typography variant="body1">{translate(`Refers to a specific individual or corporation identified by personal information.`)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }}>
                                    <Grid xs={4} md={3}>
                                        <Box sx={{ display: "flex" }}>
                                            <Typography variant="subtitle1" sx={{ mr: .5 }}>(3)</Typography>
                                            <Typography variant="subtitle1">{translate(`General Personal Information Protection Manager`)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={8} md={9}>
                                        <Typography variant="body1">{translate(`Refers to a person appointed by the representative director who has overall responsibility and authority for operations related to the implementation and operation of these regulations.`)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }} >
                                    <Grid xs={4} md={3}>
                                        <Box sx={{ display: "flex" }}>
                                            <Typography variant="subtitle1" sx={{ mr: .5 }}>(4)</Typography>
                                            <Typography variant="subtitle1">{translate(`Utilization`)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={8} md={9}>
                                        <Typography variant="body1">{translate(`Refers to the processing of personal information by the Company within the Company.`)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }} >
                                    <Grid xs={4} md={3}>
                                        <Box sx={{ display: "flex" }}>
                                            <Typography variant="subtitle1" sx={{ mr: .5 }}>(5)</Typography>
                                            <Typography variant="subtitle1">{translate(`Provide`)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={8} md={9}>
                                        <Typography variant="body1">{translate(`Refers to the transfer of personal information held by the Company to a third party outside the Company and making it available.`)}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container sx={{ mt: 2 }} >
                                    <Grid xs={4} md={3}>
                                        <Box sx={{ display: "flex" }}>
                                            <Typography variant="subtitle1" sx={{ mr: .5 }}>(6)</Typography>
                                            <Typography variant="subtitle1">{translate(`Entrust`)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid xs={8} md={9}>
                                        <Typography variant="body1">{translate(`Refers to entrusting personal information held by the Company to a third party outside the Company for purposes such as entrusting shipping operations, etc.`)}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 3 (Target Personal Information, etc.)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`These regulations apply to all personal information handled by our company, regardless of whether it is processed by a computer system or recorded in writing.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 4 (Restrictions on Scope of Acquisition)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`Acquisition of Personal Information, etc. shall be carried out within the scope of the business stipulated in the Articles of Incorporation, clearly defining the purpose of use, and to the extent necessary to achieve that purpose.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 5 (Restrictions on Acquisition Methods)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`Acquisition of personal information shall be done by lawful and fair means.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 6 (Principles of Transfer and Transmission of Personal Information)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`Transfer and transmission of personal information shall be carried out by a person authorized to do so in a necessary and appropriate manner to prevent risks such as leakage and loss to the outside, and to the extent necessary for the execution of business.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 7 (Usage Principles)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2, "& > :not(:first-child)": { ml: 2 } }}>
                                <Typography variant="body1">{translate(`Personal information shall be used within the scope of the following purposes of use.`)}</Typography>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">①</Typography>
                                    <Typography variant="body1">{translate(`The purpose of hearing opinions, exchanging information, providing and reporting, etc. regarding the operation of our business, and reflecting it in future business operations.`)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">②</Typography>
                                    <Typography variant="body1">{translate(`The purpose of carrying out transactions based on contractual relationships such as purchase of goods, outsourcing of work, financial transactions, etc., in the course of the Company's normal operations.`)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">③</Typography>
                                    <Typography variant="body1">{translate(`The purpose of our company to conduct advertising activities for the user.`)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">④</Typography>
                                    <Typography variant="body1">{translate(`Communication from our company to users.`)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">⑤</Typography>
                                    <Typography variant="body1">{translate(`Collection of quality information and feedback to development, etc.`)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">⑥</Typography>
                                    <Typography variant="body1">{translate(`Purposes related to ① to ⑤ above`)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 8 (Principle of provision)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2, "& > :not(:first-child)": { ml: 2 } }}>
                                <Typography variant="body1">{translate(`As a general rule, personal information will not be provided. However, this shall not apply in the case of any of the following items.`)}</Typography>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">(1)</Typography>
                                    <Typography variant="body1">{translate(`When required by law or when legally requested disclosure by a court or other government agency with legitimate authority`)}</Typography>
                                </Box>
                                <Box sx={{ display: "flex", mt: 2 }}>
                                    <Typography sx={{ mr: 1 }} variant="body1">(2)</Typography>
                                    <Typography variant="body1">{translate(`When it is unavoidable for business and the consent of the person is obtained`)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 9 (Use and provision outside the scope of purpose of use)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`If neither of the preceding Articles (1) and (2) apply and the personal information is used or provided beyond the scope of the purpose of use, the person will be notified in writing or by an alternative method, and the person will be notified in advance. shall be done with the consent of`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 10 (Principles for Management of Personal Information, etc.)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`Personal information shall be managed accurately and up-to-date within the scope necessary for the purpose of use.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 11 (Security Control Measures for Personal Information, etc.)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`Sufficient attention shall be paid to risks related to personal information such as unauthorized access to personal information, loss, destruction, falsification and leakage of personal information.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 12 (Responsibilities of Workers Concerning Confidentiality of Personal Information)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`Persons engaged in business related to the acquisition, use, provision, and deposit of Personal Information, etc. at the Company shall comply with the provisions of laws and regulations, these Regulations, etc. It shall conduct its business with care.`)}</Typography>
                            </Box>
                        </Box>
                        <Typography sx={{ mt: 4 }} variant="h5">{translate(`Article 13 (Disposal of personal information)`)}</Typography>
                        <Box>
                            <Box sx={{ mt: 2, ml: 2 }}>
                                <Typography variant="body1">{translate(`Erasure and disposal of personal information shall be carried out by a person authorized to do so in a necessary and appropriate manner to prevent risks such as leakage and loss to the outside, and to the extent necessary for the execution of business. do.`)}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Container>
        </>
    );
}
