import { ArrowBack, Bookmark, Mail } from "@mui/icons-material";
import { Box, Button, Card, SxProps, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { BookMarkControllerEnum } from "../gql/graphql";
import { useTranslateField } from "../appContext/AppContext";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export default function SearchDetailHeader(props: {
    sx?: SxProps;
    offerId: string;
    isBookMarked?: boolean;
    onBookMarkedChange?: (
        ids: string[],
        controller: BookMarkControllerEnum
    ) => void;
    handleOpenContactDialog: () => void;
    onPdfClick?: () => void
}) {
    const navigate = useNavigate();
    const translate = useTranslateField();
    return (
        <>
            <Card
                sx={{
                    ...props.sx,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 1,
                }}
            >
                <Button onClick={() => navigate(-1)}>
                    <ArrowBack
                        sx={{
                            mr: 1,
                            display: {
                                xs: "none",
                                sm: "block",
                            },
                        }}
                    />
                    <Typography variant="body2">{translate("Back")}</Typography>
                </Button>
                <Box sx={{ display: "flex", gap: 1 }}>
                    <Button
                        variant="outlined"
                        onClick={props.handleOpenContactDialog}
                    >
                        <Mail
                            sx={{
                                mr: 1,
                                display: {
                                    xs: "none",
                                    sm: "block",
                                },
                            }}
                        />
                        <Typography variant="body2">{translate("Contact")}</Typography>
                    </Button>
                    {!props.isBookMarked ? (
                        <Button
                            variant="outlined"
                            onClick={() =>
                                props.onBookMarkedChange &&
                                props.onBookMarkedChange(
                                    [props.offerId],
                                    BookMarkControllerEnum.Add
                                )
                            }
                        >
                            <Bookmark
                                sx={{
                                    mr: 1,
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                }}
                            />
                            <Typography variant="body2">{translate("Bookmark")}</Typography>
                        </Button>
                    ) : (
                        <Button
                            variant="outlined"
                            onClick={() =>
                                props.onBookMarkedChange &&
                                props.onBookMarkedChange(
                                    [props.offerId],
                                    BookMarkControllerEnum.Remove
                                )
                            }
                        >
                            <Bookmark
                                className=" text-red-600"
                                sx={{
                                    mr: 1,
                                    display: {
                                        xs: "none",
                                        sm: "block",
                                    },
                                }}
                            />
                            <Typography
                                className="text-red-600"
                                variant="body2"
                            >
                                {translate("Bookmarked")}
                            </Typography>
                        </Button>
                    )}
                    <Button
                        variant="outlined"
                        onClick={() =>
                            props.onPdfClick && props.onPdfClick()
                        }
                    >
                        <PictureAsPdfIcon
                            sx={{
                                mr: 1,
                                display: {
                                    xs: "none",
                                    sm: "block",
                                },
                            }}
                        />
                        <Typography variant="body2">{translate("PDF")}</Typography>
                    </Button>
                </Box>
            </Card>
        </>
    );
}
