import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
    Avatar,
    Box,
    Button,
    Container,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
} from "@mui/material";
import Copyright from "../components/Copyright";
import { useState } from "react";
import { Message } from "../components/global/Message";
import { useNavigate } from "react-router-dom";
import PageRecords from "../routes/PageRecords";
import { useAsyncEffect, useQueryParameter } from "../utils/ReactHelpers";
import { useMutation } from "@apollo/client";
import { ResetPasswordDocument } from "../gql/graphql";
import { useTranslateField } from "../appContext/AppContext";

export default function PagesResetPassword() {
    const navigate = useNavigate();
    const translate = useTranslateField();
    const [resetPasswordCall] = useMutation(ResetPasswordDocument)
    const resetKey = useQueryParameter("resetKey")

    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState<boolean>(
        false
    );
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleClickShowPasswordConfirm = () =>
        setShowPasswordConfirm(!showPasswordConfirm);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get("password");
        const passwordConfirm = data.get("confirm");
        if (password === "" || passwordConfirm === "") {
            Message.Error("No password entered");
            return;
        }
        if (password !== passwordConfirm) {
            Message.Error("The passwords entered do not match");
            return;
        }

        if (resetKey && password && passwordConfirm) {
            let response = await resetPasswordCall({
                variables: {
                    key: resetKey,
                    password: password.toString(),
                    confirmPassword: passwordConfirm.toString()
                }
            })
            if (response.data && response.data.ResetPassword.valueOf()) {
                Message.Success("Password set successfully");
                navigate(PageRecords.SignIn.path);
            }
        } else {
            Message.Error("key is not found")
            return
        }
    };

    return (
        <>
            <Container component="main" maxWidth="xs">
                <Box
                    sx={{
                        // header's height is 70px (ResponsiveAppBar.tsx => Toolbar)
                        minHeight: "calc(100vh - 70px)",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
                        <LockOutlined />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {translate("Reset password")}
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label={translate("Password")}
                            autoFocus
                            type={showPassword ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="confirm"
                            label={translate("Confirm password")}
                            type={showPasswordConfirm ? "text" : "password"}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={
                                                handleClickShowPasswordConfirm
                                            }
                                            edge="end"
                                        >
                                            {showPasswordConfirm ? (
                                                <VisibilityOff />
                                            ) : (
                                                <Visibility />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {translate("Reset")}
                        </Button>
                    </Box>
                    <Copyright sx={{ mt: 4 }} />
                </Box>
            </Container>
        </>
    );
}
