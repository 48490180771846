import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Switch,
    SxProps,
    TextField,
} from "@mui/material";
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import { SyntheticEvent, useEffect, useState } from "react";
import { useCategories, useCountries, useCurrencies, usePostTypes, useProductTypes, useTranslateField } from "../appContext/AppContext";
import theme from "../theme";
import { CategoryRecord, OfferFilterInput } from "../gql/graphql";
import { PostTypeRecord } from "../gql/graphql";
import { useStateWithLocalStorage } from "../utils/ReactHelpers";
import { areInterfacesEqual } from "../utils/tools";
import { SetLocalData } from "../utils/localStroage";

type Props = {
    onFilterApply?: (filter: OfferFilterInput) => void
    placeholder?: string;
    defaultText?: string
    filter?: OfferFilterInput
    sx?: SxProps;
};

export default function SearchBar(props: Props) {
    const currencies = useCurrencies();
    const postTypes = usePostTypes()
    const productTypes = useProductTypes();
    const categories = useCategories()
    const [open, setOpen] = useState(false);
    const defaultOfferFilterInput: OfferFilterInput = {
        type: postTypes.map(v => v.value),
        onlyExistFile: false,
        productType: productTypes.map(v => v.value),
        keyword: "",
        current: null,
    }
    console.log("props filter=", props.filter)

    const [filterParam, setFilterParam] = useState<OfferFilterInput>({
        type: postTypes.map(v => v.value),
        onlyExistFile: false,
        productType: productTypes.map(v => v.value),
        keyword: "",
        current: null,
    });

    console.log("props filterParam=", filterParam)

    useEffect(() => {
        if (props.filter) {
            console.log("props.filter=", props.filter)
            setFilterParam(props.filter)
        } else {
            console.log("filter is undefine")
            setFilterParam({
                type: postTypes.map(v => v.value),
                onlyExistFile: false,
                productType: productTypes.map(v => v.value),
                keyword: "",
                current: null,
            })
        }
    }, [postTypes, productTypes, props.filter])

    console.log("filterParam start=", filterParam)

    const defaultParams = JSON.stringify(defaultOfferFilterInput)

    // ダイアログの開閉を管理
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const noFilter = () => {
        if (defaultParams === JSON.stringify(filterParam)) {
            return true
        }
        console.log("defaultParams=", defaultParams)
        console.log("JSON.stringify(filterParam)=", JSON.stringify(filterParam))

        return false
        // return areInterfacesEqual(defaultParams, filterParam)
    }

    const translate = useTranslateField()

    const GetCategory = () => {
        return categories.find(v => v.value === filterParam.category)
    }

    return (
        <Paper
            component="form"
            sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                ...props.sx,
            }}
        >
            <IconButton sx={{ p: "10px" }} onClick={handleOpen}>
                {noFilter() ? (
                    <FilterAltOutlined />
                ) : (
                    <FilterAlt sx={{ color: theme.palette.primary.main }} />
                )}
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll="paper"
                sx={{ "& .MuiDialog-paper": { m: 2 } }}
            >
                <DialogTitle
                    sx={{
                        borderBottom: `solid 2px ${theme.palette.primary.main}`,
                    }}
                >
                    {translate("Filter")}
                </DialogTitle>
                <DialogContent sx={{ bgcolor: "#f7f7f7" }}>
                    <DialogContentText sx={{ pt: 2 }}>
                        <Paper sx={{ p: 2 }}>
                            {translate("Post type")}
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    pt: 2,
                                }}
                            >
                                {
                                    postTypes.map(postType => {
                                        return (
                                            <Grid key={postType.label} item xs={12} sm={6}>
                                                <FormControlLabel
                                                    sx={{
                                                        border: `solid 1px ${theme.palette.divider
                                                            }`,
                                                        borderRadius: 1,
                                                        width: "100%",
                                                        mx: 0,
                                                    }}
                                                    label={translate(postType.label)}
                                                    control={
                                                        <Checkbox
                                                            checked={filterParam.type.includes(postType.value)}
                                                            onChange={() => {
                                                                let newTypes = filterParam.type
                                                                if (filterParam.type.includes(postType.value)) {
                                                                    newTypes = filterParam.type.filter(v => v !== postType.value)
                                                                } else {
                                                                    newTypes.push(postType.value)
                                                                }
                                                                setFilterParam(
                                                                    {
                                                                        ...filterParam,
                                                                        type: newTypes,
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Paper>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            {translate("Product type")}
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    pt: 2,
                                }}
                            >
                                {
                                    productTypes.map((productType) => {
                                        return (
                                            <Grid key={productType.label} item xs={12} sm={6}>
                                                <FormControlLabel
                                                    sx={{
                                                        border: `solid 1px ${theme.palette.divider
                                                            }`,
                                                        borderRadius: 1,
                                                        width: "100%",
                                                        mx: 0,
                                                    }}
                                                    label={translate(productType.label)}
                                                    control={
                                                        <Checkbox
                                                            checked={filterParam.productType.includes(productType.value)}
                                                            onChange={() => {
                                                                let newProductType = filterParam.productType
                                                                if (filterParam.productType.includes(productType.value)) {
                                                                    newProductType = filterParam.productType.filter(v => v !== productType.value)
                                                                } else {
                                                                    newProductType.push(productType.value)
                                                                }
                                                                console.log("newProductType=", newProductType)
                                                                setFilterParam(
                                                                    {
                                                                        ...filterParam,
                                                                        productType: newProductType,
                                                                    }
                                                                )
                                                            }}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Paper>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <FormControl fullWidth>
                                {translate("Category")}
                                <Autocomplete
                                    options={categories}
                                    getOptionLabel={(option: CategoryRecord) =>
                                        translate(option.label)
                                    }
                                    value={GetCategory()}
                                    onChange={(e, v) => {
                                        setFilterParam(
                                            {
                                                ...filterParam,
                                                category: v ? v.value : undefined
                                            }
                                        )
                                    }}
                                    autoHighlight
                                    isOptionEqualToValue={(option, value) => {
                                        if (value) {
                                            return option.value === value.value
                                        }

                                        return false
                                    }
                                    }
                                    renderInput={(_params) => (
                                        <TextField
                                            {..._params}
                                            required
                                            size="small"
                                            sx={{ mt: 2 }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Paper>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <FormControl fullWidth>
                                {translate("Price range")}
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size="small"
                                            label={translate("From")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            value={filterParam.fromPrice}
                                            onChange={(v) => {
                                                if (v.target.value.length == 0) {
                                                    setFilterParam({
                                                        ...filterParam,
                                                        fromPrice: undefined
                                                    })
                                                } else {
                                                    setFilterParam({
                                                        ...filterParam,
                                                        fromPrice: Number(v.target.value),
                                                    })
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size="small"
                                            label={translate("To")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            fullWidth
                                            value={filterParam.toPrice}
                                            onChange={(v) => {
                                                if (v.target.value.length == 0) {
                                                    setFilterParam({
                                                        ...filterParam,
                                                        toPrice: undefined
                                                    })
                                                } else {
                                                    setFilterParam({
                                                        ...filterParam,
                                                        toPrice: Number(v.target.value),
                                                    })
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            freeSolo
                                            options={currencies}
                                            value={filterParam.current ? filterParam.current : ""}
                                            autoHighlight
                                            onChange={(e, v) => {
                                                setFilterParam({
                                                    ...filterParam,
                                                    current: v,
                                                })
                                            }}
                                            renderInput={(_params) => (
                                                <TextField
                                                    {..._params}
                                                    label={translate("Currency")}
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <FormControl fullWidth>
                                {translate("Date range")}
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{
                                        mt: 2,
                                    }}
                                >
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size="small"
                                            label={translate("From")}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="date"
                                            value={filterParam.fromDate}
                                            onChange={(v) => {
                                                setFilterParam({
                                                    ...filterParam,
                                                    fromDate: v.target.value,
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            size="small"
                                            label={translate("To")}
                                            fullWidth
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            type="date"
                                            value={filterParam.toDate}
                                            onChange={(v) => {
                                                setFilterParam({
                                                    ...filterParam,
                                                    toDate: v.target.value,
                                                })
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Paper>
                        <Paper sx={{ p: 2, mt: 2 }}>
                            <FormControl fullWidth>
                                {translate("Show only posts with images")}
                                <Switch
                                    checked={filterParam.onlyExistFile}
                                    onChange={(e, v) => {
                                        setFilterParam({
                                            ...filterParam,
                                            onlyExistFile: v,
                                        })
                                    }}
                                    sx={{ ml: "auto", mt: 2 }}
                                />
                            </FormControl>
                        </Paper>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        px: 3,
                        py: 2,
                        borderTop: `solid 2px ${theme.palette.primary.main}`,
                    }}
                >
                    <Button onClick={() => {
                        handleClose()
                    }}>{translate("Cancel")}</Button>
                    <Button onClick={() => {
                        setFilterParam({
                            type: postTypes.map(v => v.value),
                            onlyExistFile: false,
                            productType: productTypes.map(v => v.value),
                            keyword: "",
                        })
                    }}>{translate("Reset")}</Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            props.onFilterApply && props.onFilterApply(filterParam)
                            handleClose()
                        }}
                    >
                        {translate("Apply")}
                    </Button>
                </DialogActions>
            </Dialog>
            <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder={translate("Enter keyword or #hashtag")}
                value={filterParam.keyword}
                onChange={(e) => {
                    setFilterParam({
                        ...filterParam,
                        keyword: e.target.value
                    })

                }}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        props.onFilterApply && props.onFilterApply(filterParam)
                        e.preventDefault()
                    }
                }}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={() => props.onFilterApply && props.onFilterApply(filterParam)}>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
