
/** Note that JS's File class inherits Blob */
export function BlobToDataUrlAsync(from:Blob): Promise<string>{
    return new Promise((accept,reject)=>{
        try{
            const fileReader = new FileReader();
            fileReader.onload = (e)=>{
                if(
                    e.target?.result===undefined ||
                    e.target?.result===null
                ){
                    reject(e.target?.result);
                }else{
                    // We know that result is a string, not an ArrayBuffer, because we call readAsDataUrl, rather than readAsArrayBuffer
                    const result:string = e.target.result as string;
                    accept(result);
                }
            };
            fileReader.readAsDataURL(from);
        }catch(e){
            reject(e);
        }
    });
}
