import { Divider, Typography } from "@mui/material";
import SearchBar from "../components/SearchBar";
import SearchResultTable from "../components/SearchResultTable";
import { useState } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { BookMarkControllerEnum, BookMarkDocument, BookMarkListDocument, LimitInput, OfferFilterInput, OfferResponse } from "../gql/graphql";
import { useAsyncEffect } from "../utils/ReactHelpers";
import { useTranslateField } from "../appContext/AppContext";

export default function PagesAccountBookMark() {
    const [bookMarkListCall] = useLazyQuery(BookMarkListDocument, { fetchPolicy: "network-only" });
    const [offers, setOffers] = useState<Array<OfferResponse>>([]);
    const [current, setCurrent] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [total, setTotal] = useState(0);
    const pageSizeList = [10, 30, 50];
    const [bookMark,] = useMutation(BookMarkDocument)
    const [filter, setFilter] = useState<OfferFilterInput | undefined>(undefined)

    useAsyncEffect(async () => {
        let limitInput: LimitInput = {
            current: current,
            pageSize: pageSize,
        };

        let { data } = await bookMarkListCall({
            variables: { limit: limitInput, filter: filter },
            fetchPolicy: "network-only",
        });
        if (data) {
            setOffers(data.BookMarkList.offers);
            setTotal(data.BookMarkList.total);
        }
        console.log("bookmark data=", data)
    }, [current, pageSize, offers, filter]);

    const OnBookMarkChange = async (ids: string[], controller: BookMarkControllerEnum) => {
        if (controller === BookMarkControllerEnum.Add) {
            return
        }

        let response = await bookMark({
            variables: {
                offerIds: ids,
                controller: BookMarkControllerEnum.Remove,
            }
        })
        if (response.data) {
            if (response.data.BookMark.valueOf()) {
                let tmpOffers = offers.filter(v => {
                    if (ids.includes(v.id)) {
                        return false
                    }
                    return true
                })
                setOffers(tmpOffers)
            }
        }
    }

    const OnFilterApply = async (filter: OfferFilterInput) => {
        setFilter(filter)
    }

    const translate = useTranslateField();

    return (
        <>
            <Typography variant="h4">{translate("Bookmark")}</Typography>
            <Divider sx={{ height: "1px", backgroundColor: "#000" }} />
            <Typography paragraph mt={2}>
                {translate("You can view bookmarked posts on this page.")}
            </Typography>
            <SearchBar sx={{ mt: 4, width: "100%" }} onFilterApply={OnFilterApply} />
            <SearchResultTable
                data={offers}
                pageSize={pageSize}
                total={total}
                current={current}
                setPageSize={setPageSize}
                setCurrent={setCurrent}
                pageSizeList={pageSizeList}
                sx={{ mt: 4, width: "100%" }}
                onBookMarkChange={OnBookMarkChange}
            />
        </>
    );
}
