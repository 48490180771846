import { LocalizationProvider } from '@mui/x-date-pickers'
import { Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from '@mui/x-date-pickers'
import { ReactNode, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslateField } from '../../appContext/AppContext';
import { MailManagerTypeEnum } from '../../gql/graphql';

export interface MailManagerSearchFilterInterface {
    email: string,
    offerTitle: string,
    date: Date | undefined,
    type: string,
}

export function MailManagerSearchComponent({
    onSend,
    sort
}: {
    onSend: (filter: MailManagerSearchFilterInterface) => void,
    sort?: ReactNode
}) {
    const [inputOnFocusTop, setInputOnFocusTop] = useState(-0.5)
    const inputRef = useRef<HTMLInputElement>(null)
    const [selectDate, setSelectData] = useState<Date | undefined>(undefined)
    const [email, setEmail] = useState("")
    const [offerTitle, setOfferTitle] = useState("")
    const [mailManagerType, setMailManagerType] = useState("")
    const translate = useTranslateField()
    useEffect(() => {
        if (inputRef.current) {
            if (!inputRef.current.onfocus) {
                console.log("focus", inputRef.current.onfocus)
                inputRef.current.onfocus = () => {
                    setInputOnFocusTop(0)
                }

            }

            if (!inputRef.current.onblur) {
                inputRef.current.onblur = () => {
                    if (inputRef.current) {
                        if (inputRef.current.value === "MM/DD/YYYY") {
                            setInputOnFocusTop(-0.5)
                        }
                    }
                }
            }
        }
    })

    useEffect(() => {
        if (!selectDate) {
            setInputOnFocusTop(-.5)
        } else {
            setInputOnFocusTop(0)
        }
    }, [selectDate])

    const OnTypeChange = (e: SelectChangeEvent) => {
        setMailManagerType(e.target.value)
    }
    return (
        <Box className=" mb-2 flex items-center" >
            <TextField size="small" onChange={(e) => setEmail(e.target.value)} className=' mr-1 w-[12rem]' id="outlined-basic" label={translate("emails")} variant="outlined" />
            <TextField size="small" onChange={(e) => setOfferTitle(e.target.value)} className=' mr-1 w-[12rem]' id="outlined-basic" label={translate("offer")} variant="outlined" />
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DatePicker sx={
                    {
                        width: "12rem",
                        "& label": {
                            top: inputOnFocusTop + "rem",
                        },
                        "& input": {
                            padding: ".5rem",
                        },
                        marginRight: ".25rem"
                    }
                }
                    label={translate("date")}
                    inputRef={inputRef}
                    onOpen={() => setInputOnFocusTop(0)}
                    value={selectDate ? selectDate : null}
                    onClose={() => {
                        if (inputRef.current) {
                            if (inputRef.current.value === "MM/DD/YYYY") {
                                setInputOnFocusTop(-0.5)
                            } else {
                                setInputOnFocusTop(-.5)
                            }
                        }
                    }}

                    onChange={(e) => {
                        if (e) {
                            let date = new Date(e)
                            console.log(date)
                            setSelectData(date)
                        } else {
                            setSelectData(undefined)
                        }
                    }}
                />
            </LocalizationProvider>
            <FormControl className=' w-[12rem] mr-1' size='small'>
                <InputLabel id="mail-manager-type">{translate("type")}</InputLabel>
                <Select
                    labelId="mail-manager-type"
                    id="mail-manager-type-select"
                    value={mailManagerType}
                    label={translate("type")}
                    onChange={OnTypeChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        Object.values(MailManagerTypeEnum).map(v => {
                            return <MenuItem key={v} value={v}>{v}</MenuItem>
                        })
                    }
                </Select>
            </FormControl>
            <Button
                className="mr-1"
                variant="contained"
                endIcon={<SearchIcon />}
                onClick={() => onSend && onSend({
                    email: email,
                    offerTitle: offerTitle,
                    date: selectDate,
                    type: mailManagerType,
                })}
            >
                Search
            </Button>
            {sort}
        </Box>
    )
}